import { React } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import Box from '@mui/material/Box';
import {
  useTheme,
  useMediaQuery,
  Button,
  Divider,
  Typography,
} from '@mui/material';
import { ReactComponent as ChatCircleIcon } from '../../assets/images/chatCircle.svg';
import { ReactComponent as CopyIcon } from '../../assets/images/wadizCopy.svg';
import { ReactComponent as AndroidIcon } from '../../assets/images/android.svg';
import { ReactComponent as IosIcon } from '../../assets/images/ios.svg';
import FooterMenuArea from './FooterMenu';

const Container = styled(Box)(({ theme }) => ({
  whiteSpace: 'nowrap',
  [theme.breakpoints.up('desktop')]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

const Menu2Container = styled(Box)(({ theme }) => ({
  padding: '24px 16px',
  [theme.breakpoints.up('desktop')]: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    padding: '32px 0px',
    height: '238px',
    width: '1280px',
  },
}));

const PhoneEmailBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('desktop')]: {
    padding: '0px 0px 0px 0px',
    width: '351px',
    height: '174px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginLeft: '24px',
    marginRight: '24px',
  },
}));

const AskButton = styled(Button)(({ theme }) => ({
  height: '40px',
  backgroundColor: '#212529',
  borderRadius: '30px',
  paddingRight: '16px',
  paddingLeft: '18px',
  '&:hover': { backgroundColor: '#212529' },
  [theme.breakpoints.up('desktop')]: {
    alignItems: 'center',
    padding: '10px 19px',
    width: '187px',
    height: '48px',
  },
}));

const PhoneNumberContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItem: 'center',
  marginTop: '24px',
  marginLeft: '13px',
  [theme.breakpoints.up('desktop')]: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    padding: '0px',
    marginTop: '0px',
    width: '284px',
    height: '48px',
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: '12px',
  color: '#495057',
  [theme.breakpoints.up('desktop')]: {},
}));

const NumberTitle = styled(Typography)(({ theme }) => ({
  cursor: 'pointer',
  fontWeight: 700,
  fontSize: '16px',
  color: '#212529',
  lineHeight: '26px',
  marginTop: '3px',
  textAlign: 'center',
  [theme.breakpoints.up('desktop')]: {},
}));

const SubTitle = styled(Typography)(({ theme }) => ({
  fontSize: '12px',
  color: '#90949C',
  [theme.breakpoints.up('desktop')]: {},
}));

const ConsultingTitleTitle = styled(Typography)(({ theme }) => ({
  color: '#90949C',
  fontSize: '12px',
  fontWeight: 400,
  lineHeight: '14px',
  marginTop: '8px',
  letterSpacing: '-0.3px',
  [theme.breakpoints.up('desktop')]: {},
}));

const EmailTitle = styled(Typography)(({ theme }) => ({
  cursor: 'pointer',
  fontSize: '12px',
  lineHeight: '14px',
  fontWeight: 400,
  color: '#495057',
  marginTop: '8px',
  [theme.breakpoints.up('desktop')]: {},
}));

const AppLinkContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-evenly',
  marginTop: '42px',
  [theme.breakpoints.up('desktop')]: {
    marginTop: '0px',
    marginLeft: '0px',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '0px',
    width: '240px',
    height: '128px',
  },
}));

const AppLinkTitle = styled(Typography)(({ theme }) => ({
  marginLeft: '8px',
  color: '#212529',
  fontSize: '12px',
  fontWeight: 400,
  [theme.breakpoints.up('desktop')]: {},
}));

const InfoBox = styled(Box)(({ theme }) => ({
  marginTop: '24px',
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.up('desktop')]: {
    marginTop: '0px',
    width: '380px',
    height: '78px',
  },
}));
const NameCompanyBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints.up('desktop')]: {
    display: 'inline-flex',
  },
}));
const CompanyRegistBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints.up('desktop')]: {
    display: 'inline-flex',
  },
}));

const Footer = ({ sideOn }) => {
  const sideOnColor = {
    background: sideOn ? '#F5F7FA' : '#fff',
  };
  const theme = useTheme();
  const isDeskTop = useMediaQuery(theme.breakpoints.up('desktop'));
  return (
    <Container>
      {!sideOn && <FooterMenuArea />}
      <Menu2Container sx={sideOnColor}>
        <AskButton
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            window.ChannelIO('showMessenger');
          }}
        >
          <ChatCircleIcon sx={{ marginRight: '8px' }} />
          <Typography
            sx={{
              color: 'white',
              fontSize: '14px',
              fontWeight: 500,
              marginLeft: '8px',
            }}
          >
            와디즈에 문의하기
          </Typography>
        </AskButton>
        <PhoneEmailBox>
          <PhoneNumberContainer>
            <Box sx={{ textAlign: 'center' }}>
              <Title>펀딩/스토어 고객센터</Title>
              <NumberTitle
                onClick={() => {
                  window.open('tel:1661-9056', '_blank');
                }}
              >
                1661-9056
              </NumberTitle>
            </Box>
          </PhoneNumberContainer>
          <ConsultingTitleTitle>
            상담 가능 시간 : 평일 오전 9시 ~ 오후 6시 (주말, 공휴일 제외)
          </ConsultingTitleTitle>
          <Typography
            sx={{
              color: '#495057',
              fontSize: '12px',
              fontWeight: 600,
              marginTop: '24px',
            }}
          >
            이메일
          </Typography>
          <EmailTitle
            onClick={() => {
              window.open('mailto:info@wadiz.kr', '_blank');
            }}
          >
            펀딩 : info@wadiz.kr
          </EmailTitle>
          <EmailTitle
            onClick={() => {
              window.open('mailto:help.finance@wadiz.kr', '_blank');
            }}
          >
            파이낸스 : help.finance@wadiz.kr
          </EmailTitle>
        </PhoneEmailBox>
        <InfoBox>
          {isDeskTop && (
            <NameCompanyBox>
              <SubTitle>와디즈 ㈜</SubTitle>
              <Divider
                orientation="vertical"
                variant="middle"
                sx={{
                  margin: '0px',
                  marginLeft: '10px',
                  marginRight: '10px',
                  height: '10px',
                }}
              />
              <SubTitle>대표이사 신혜성</SubTitle>
              <Divider
                orientation="vertical"
                variant="middle"
                sx={{
                  margin: '0px',
                  marginLeft: '10px',
                  marginRight: '10px',
                  height: '10px',
                }}
              />
              <SubTitle>사업자등록번호 258-87-01370</SubTitle>
              <Divider
                orientation="vertical"
                sx={{
                  margin: '0px',
                  marginLeft: '10px',
                  marginRight: '10px',
                  height: '10px',
                }}
              />
            </NameCompanyBox>
          )}
          {!isDeskTop && (
            <>
              <NameCompanyBox>
                <SubTitle>와디즈 ㈜</SubTitle>
                <Divider
                  orientation="vertical"
                  variant="middle"
                  sx={{
                    margin: '0px',
                    marginLeft: '10px',
                    marginRight: '10px',
                    height: '10px',
                  }}
                />
                <SubTitle>대표이사 신혜성</SubTitle>
                <Divider
                  orientation="vertical"
                  variant="middle"
                  sx={{
                    margin: '0px',
                    marginLeft: '10px',
                    marginRight: '10px',
                    height: '10px',
                  }}
                />
              </NameCompanyBox>
              <CompanyRegistBox>
                <SubTitle>사업자등록번호 258-87-01370</SubTitle>
                <Divider
                  orientation="vertical"
                  sx={{
                    margin: '0px',
                    marginLeft: '10px',
                    marginRight: '10px',
                    height: '10px',
                  }}
                />
              </CompanyRegistBox>
            </>
          )}
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <SubTitle>통신판매업신고번호 2021-성남분당C-1153</SubTitle>
            <Divider
              orientation="vertical"
              sx={{
                margin: '0px',
                marginLeft: '10px',
                marginRight: '10px',
                height: '10px',
              }}
            />
          </Box>
          <SubTitle>경기 성남시 분당구 판교로 242 PDC A동 402호</SubTitle>
          <Box sx={{ marginTop: '12px' }}>
            <CopyIcon />
          </Box>
        </InfoBox>
        <AppLinkContainer>
          <Box sx={{ width: '120px', textAlign: 'center' }}>
            <Button
              onClick={() => {
                window.open(
                  'https://play.google.com/store/apps/details?id=com.markmount.wadiz&pli=1',
                  '_blank',
                );
              }}
            >
              <AndroidIcon />
              <AppLinkTitle>Android앱</AppLinkTitle>
            </Button>
          </Box>
          <Box sx={{ width: '120px', textAlign: 'center' }}>
            <Button
              onClick={() => {
                window.open(
                  'https://apps.apple.com/kr/app/wadijeu/id1107828621',
                  '_blank',
                );
              }}
              variant="text"
            >
              <IosIcon />
              <AppLinkTitle>IOS앱</AppLinkTitle>
            </Button>
          </Box>
        </AppLinkContainer>
      </Menu2Container>
    </Container>
  );
};

Footer.propTypes = {
  sideOn: PropTypes.bool,
};

Footer.defaultProps = {
  sideOn: false,
};
export default Footer;
