import React, { useContext, useCallback } from 'react';
import moment from 'moment';
import qs from 'qs';
import { styled, Box, useTheme, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { menuState } from '../../atoms';
import { homeContext, getfindBoard } from '../../lib/utils';
import { ReactComponent as RightArrowIcon } from '../../assets/images/ic-rightArrow.svg';
import { ReactComponent as NewIcon } from '../../assets/images/ic-new.svg';

const Notice = () => {
  const { board } = useContext(homeContext);
  const navigate = useNavigate();
  const menuList = useRecoilValue(menuState);
  const theme = useTheme();
  const isDeskTop = useMediaQuery(theme.breakpoints.up('desktop'));

  // console.log('board.notice :', board?.notice);

  // 공지사항 게시판으로 이동 by '더보기'
  const onToNoticeBoard = () => {
    const currentMenu = getfindBoard(menuList, board.notice.board_idx);
    navigate(`/menu/${currentMenu.menu_idx}`);
  };

  // 공지사항 상세내용으로 이동
  const onToNoticeBoardDetail = useCallback(
    (listIdx, listRownum) => {
      const data = {
        rownum: listRownum,
        search_type: null,
        search_text: null,
        category1_idx: null,
        category2_idx: null,
        board_id: board.notice.board_idx,
      };
      const query = qs.stringify(data);
      navigate(`/board/${board.notice.board_type}/${listIdx}?${query}`);
    },
    [board],
  );

  return (
    <Container
      sx={{
        // border: '1px solid red',
        // mr: isDeskTop ? '60px' : '0',
        px: isDeskTop ? 0 : '16px',
        mb: isDeskTop ? 0 : '40px',
        width: isDeskTop ? '610px' : '100%',
        mx: isDeskTop ? '5px' : '0',
      }}
    >
      <TitleBox sx={{ justifyContent: 'space-between', pb: '10px' }}>
        <Title
          sx={{
            fontSize: isDeskTop ? '24px' : '18px',
            mb: '5px',
          }}
        >
          공지사항
        </Title>
        <FlexBox onClick={onToNoticeBoard}>
          <MoreViewText>더보기</MoreViewText>
          <RightArrowIcon />
        </FlexBox>
      </TitleBox>
      {board.notice.lists.length < 1 ? (
        <EmptyBox>등록된 게시물이 없습니다.</EmptyBox>
      ) : (
        board.notice.lists.map(list => {
          return (
            <ContentBox key={list.idx} sx={{ py: '14px' }}>
              <FlexBox sx={{ alignItems: 'flex-start' }}>
                {list.important_yn === 'Y' ? (
                  <Importance>중요</Importance>
                ) : null}{' '}
                <Subject
                  sx={{
                    fontSize: isDeskTop ? '16px' : '14px',
                  }}
                  onClick={() => {
                    onToNoticeBoardDetail(list.idx, list.rownum);
                  }}
                >
                  {list.title}
                </Subject>
                {moment(list.reg_date).format('YYYY-MM-DD') ===
                moment().format('YYYY-MM-DD') ? (
                  <Box sx={{ mt: '3px', padding: 0 }}>
                    <NewIcon width={16} height={16} />
                  </Box>
                ) : null}
              </FlexBox>
              <Date
                sx={{
                  fontSize: isDeskTop ? '16px' : '14px',
                }}
              >
                {moment(list.reg_date).format('YYYY.MM.DD')}
              </Date>
            </ContentBox>
          );
        })
      )}
    </Container>
  );
};

const Container = styled(Box)({
  // border: '1px solid dodgerblue',
  position: 'relative',
  minWidth: '320px',
});
const FlexBox = styled(Box)({
  // border: '1px solid powderblue',
  padding: 0,
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
});
const TitleBox = styled(Box)({
  // border: '1px solid powderblue',
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderBottom: '1px solid #f2f2f2',
});
const Title = styled(Box)({
  fontSize: '24px',
  fontWeight: 700,
  color: '#212529',
  lineHeight: '34px',
});
const MoreViewText = styled(Box)({
  // border: '1px solid red',
  marginRight: '8px',
  color: '#000',
  fontSize: '14px',
  fontWeight: 400,
  display: 'flex',
  alignItems: 'center',
});
const EmptyBox = styled(Box)({
  // border: '1px solid powderblue',
  width: '100%',
  height: '175px',
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderBottom: '1px solid #f2f2f2',
  fontWeight: 400,
  fontSize: '15px',
});
const ContentBox = styled(Box)({
  // border: '1px solid powderblue',
  position: 'relative',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderBottom: '1px solid #f2f2f2',
});
const Importance = styled(Box)({
  minWidth: '27px',
  width: '27px',
  height: '18px',
  margin: '3px 0',
  padding: '0 0 1px 0',
  fontSize: '10px',
  fontWeight: 700,
  color: '#fff',
  backgroundColor: '#00C4C4',
  border: 0,
  borderRadius: '3px',
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginRight: '12px',
});
const Subject = styled(Box)({
  // border: '1px solid powderblue',
  fontWeight: 400,
  fontSize: '16px',
  color: '#212529',
  // lineHeight: '26px',
  cursor: 'pointer',
  marginRight: '10px',
  // display: 'flex',
  // justifyContent: 'center',
  // alignItems: 'center',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  wordBreak: 'break-word',
  display: '-webkit-box',
  WebkitLineClamp: 2, // 이 숫자를 바꾸면 1줄, 2줄, 3줄 다 된다.
  WebkitBoxOrient: 'vertical',
  WebkitBoxAlign: 'start',
});
const Date = styled(Box)({
  fontWeight: 400,
  fontSize: '14px',
  color: '#90949C',
  lineHeight: '20px',
  marginLeft: '20px',
});

export default Notice;

// const Box = styled(motion.div)({
//   width: '100px',
//   height: '100px',
//   backgroundColor: 'red',
// }); <Box whileHover={{ scale: 1.2 }} />
