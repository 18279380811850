/* eslint-disable import/extensions */
import 'froala-editor/js/froala_editor.pkgd.min.js';

// Require Editor CSS files.
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/css/froala_style.min.css';

import './options/icon';
import './options/language';

// Import all Froala Editor plugins;
import 'froala-editor/js/plugins.pkgd.min.js';
import './plugins/quote';

import React from 'react';
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';

const Viewer = props => {
  return <FroalaEditorView {...props} />;
};

export default Viewer;
