import React from 'react';
import { Box, Typography, Tabs, Tab } from '@mui/material';
import { styled } from '@mui/system';
import { useParams, Link, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { menuState, currentMenuId } from '../../atoms';
import { getfindMenu, findCategory } from '../../lib/utils';
import ScreenLoading from '../common/ScreenLoading';
import TabPanel from '../common/TabPanel';
import InnerHtml from './InnerHtml';
import BasicBoard from '../../pages/BasicBoard';
import CalendarBoard from '../../pages/CalendarBoard';
import EventBoard from '../../pages/EventBoard';
import CardBoard from '../../pages/CardBoard';
import PageBoard from './PageBoard';

const MyContainer = styled(Box)(({ theme }) => ({
  // mobile
  width: '100%',
  minWidth: '360px',
  [theme.breakpoints.up('desktop')]: {
    paddingTop: '80px',
    minWidth: '1097px',
  },
}));

const MenuRoot = () => {
  const [tab, setTab] = React.useState(0);
  const [subTab, setSubTab] = React.useState(0);
  const [tabList, setTabList] = React.useState([]);
  const location = useLocation();
  const [content, setContent] = React.useState(null);
  const setMenuId = useSetRecoilState(currentMenuId);
  let { menuId } = useParams();
  menuId = parseInt(menuId, 10);
  const menuList = useRecoilValue(menuState);
  const currentMenu = getfindMenu(menuList, menuId);
  const currentCategory = findCategory(menuList, menuId);
  const query = queryString.parse(location.search);
  const isMobile = useMediaQuery(useTheme().breakpoints.down('desktop'));
  const findTab = (menu, pContent) => {
    return menu.findIndex(element => element.menu_idx === pContent.menu_idx);
  };

  const findSubTab = pContent => {
    for (let i = 0; i < currentCategory.menu.length; i += 1) {
      if (currentCategory.menu[i].menu) {
        const subTabIndex = findTab(currentCategory.menu[i].menu, pContent);
        if (subTabIndex > -1) {
          setTab(i);
          setSubTab(subTabIndex);
        }
      }
    }
  };

  const findContentMenu = inputMenu => {
    if (inputMenu.menu_type === 'category' && inputMenu.menu) {
      return findContentMenu(inputMenu.menu[0]);
    }
    return inputMenu;
  };

  const getUrl = url => {
    return url.match(/^http[s]?:\/\//) ? url : `http://${url}`;
  };

  React.useLayoutEffect(() => {
    setMenuId(menuId);
    localStorage.setItem('menuId', menuId);
    if (currentCategory !== null) {
      setTabList(currentCategory.menu);
      const localContent = findContentMenu(currentMenu);
      setContent(localContent);
      if (localContent.menu_depth === 2) {
        setSubTab(0);
        setTab(findTab(currentCategory.menu, localContent));
      } else if (localContent.menu_depth === 3) {
        findSubTab(localContent);
      }
      // console.log(localContent);
      // console.log('content type : ', localContent.menu_type);
      switch (localContent.menu_type) {
        case 'board':
          // console.log(
          //   localContent.menu_type,
          //   localContent.board_type,
          //   localContent.board_idx,
          //   localContent.page_row,
          // );
          // eslint-disable-next-line no-console
          // console.log(localContent);
          // eslint-disable-next-line no-console
          // console.log('query : ', query);
          break;
        case 'page':
          // console.log(localContent.menu_type, localContent.page_file_url);
          break;
        case 'link':
          if (
            localContent.external_popup_yn === 'Y' &&
            localContent.external_url
          ) {
            window.open(getUrl(localContent.external_url), menuId);
          } else if (localContent.external_popup_yn === 'N') {
            window.location.href = getUrl(localContent.external_url);
          }
          break;
        default:
      }

      // console.log('111', currentCategory);
      // console.log('222', currentMenu);
      // console.log('333', findContentMenu(currentMenu));
    }
  }, [currentCategory, menuId, query]);

  if (menuList === null) {
    return <ScreenLoading />;
  }

  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };

  const handleChangeSubTab = (event, newValue) => {
    setSubTab(newValue);
  };

  const Title = styled(Typography)(({ theme }) => ({
    fontWeight: 700,
    fontSize: '52px',
    color: '#212529',
    [theme.breakpoints.up('desktop')]: {},
  }));

  const SelectedTab = styled(Typography)(({ theme }) => ({
    fontWeight: 700,
    fontSize: '16px',
    color: '#00b2b2',
    [theme.breakpoints.up('desktop')]: {
      borderBottom: '2px solid #00b2b2',
      paddingBottom: '10px',
    },
  }));

  const UnSelectedTab = styled(Typography)(({ theme }) => ({
    fontWeight: 400,
    fontSize: '16px',
    color: '#212529',
    [theme.breakpoints.up('desktop')]: {
      paddingBottom: '10px',
    },
  }));

  const SelectedInnerTab = styled(Typography)(({ theme }) => ({
    fontWeight: 500,
    fontSize: '14px',
    color: '#ffffff',
    [theme.breakpoints.down('desktop')]: {
      fontSize: '12px',
      lineHeight: '20px',
      backgroundColor: '#495057',
      border: '1px solid #495057',
      borderRadius: '40px',
      height: '36px',
      minWidth: '106px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      paddingLeft: '10px',
      paddingRight: '10px',
    },
  }));

  const UnSelectedInnerTab = styled(Typography)(({ theme }) => ({
    fontWeight: 500,
    fontSize: '14px',
    color: '#495057',
    [theme.breakpoints.down('desktop')]: {
      fontSize: '12px',
      lineHeight: '20px',
      backgroundColor: '#fff',
      border: '1px solid #495057',
      borderRadius: '40px',
      height: '36px',
      minWidth: '106px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      paddingLeft: '10px',
      paddingRight: '10px',
    },
  }));

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  return (
    <Box sx={{ width: '100%' }} display="flex" justifyContent="center">
      {isMobile ? (
        <MyContainer
          display="flex"
          flexDirection="column"
          alignItems="center"
          sx={{ pb: '60px' }}
        >
          {content?.menu_type === 'page' &&
          (content.menu_depth === 1 || content.menu_depth === 2) ? (
            <Box sx={{ mt: '30px', width: '100%' }}>
              <InnerHtml url={content?.page_file_url} />
            </Box>
          ) : null}
          {content?.menu_type === 'board' &&
          content?.board_type === 'basic' &&
          (content.menu_depth === 1 || content.menu_depth === 2) ? (
            <BasicBoard
              key={content.board_idx}
              board_idx={content.board_idx}
              page_size={content.page_row}
              page={query.page ?? 1}
              searchText={query.search ?? ''}
            />
          ) : null}
          {content?.menu_type === 'board' &&
          content?.board_type === 'event' &&
          (content.menu_depth === 1 || content.menu_depth === 2) ? (
            <EventBoard
              key={content.board_idx}
              board_idx={content.board_idx}
              page_size={content.page_row}
              page={query.page ?? 1}
              searchText={query.search ?? ''}
            />
          ) : null}
          {content?.menu_type === 'board' &&
          content?.board_type === 'card' &&
          (content.menu_depth === 1 || content.menu_depth === 2) ? (
            <CardBoard
              key={content.board_idx}
              board_idx={content.board_idx}
              page_size={content.page_row}
              page={query.page ?? 1}
              searchText={query.search ?? ''}
            />
          ) : null}
          {content?.menu_type === 'board' &&
          content?.board_type === 'calendar' &&
          content.menu_depth === 2 ? (
            <CalendarBoard
              key={content.board_idx}
              board_idx={content.board_idx}
              page_size={content.page_row}
              page={query.page ?? 1}
            />
          ) : null}
          <Box
            display="flex"
            justifyContent="center"
            sx={{ width: '100%', mt: '30px' }}
          >
            <Tabs
              value={subTab}
              onChange={handleChangeSubTab}
              aria-label="basic tabs"
              variant="scrollable"
              scrollButtons={false}
              TabIndicatorProps={{
                sx: { bgcolor: '#fff', height: '0px' },
              }}
            >
              {!currentCategory.menu || !currentCategory.menu[tab]?.menu
                ? null
                : currentCategory.menu[tab].menu.map((subItem, jndex) => {
                    return (
                      <Tab
                        key={subItem.menu_idx}
                        label={
                          subTab === jndex ? (
                            <SelectedInnerTab>
                              {subItem.menu_title}
                            </SelectedInnerTab>
                          ) : (
                            <UnSelectedInnerTab>
                              {subItem.menu_title}
                            </UnSelectedInnerTab>
                          )
                        }
                        sx={{
                          ml: '2px',
                          mr: '2px',
                          p: '0px',
                        }}
                        style={{
                          minWidth: '106px',
                        }}
                        {...a11yProps(jndex)}
                        component={Link}
                        to={`/menu/${subItem.menu_idx}`}
                      />
                    );
                  })}
            </Tabs>
          </Box>
          {currentCategory.menu && currentCategory.menu[tab]?.menu
            ? currentCategory.menu[tab].menu.map((subItem, jndex) => {
                return (
                  <TabPanel
                    key={subItem.menu_idx}
                    value={subTab}
                    index={jndex}
                    style={{ marginTop: '18px', width: '100%' }}
                  >
                    {content?.menu_type === 'page' &&
                    content.menu_depth === 3 ? (
                      // <InnerHtml url={content?.page_file_url} />
                      <PageBoard
                        // key={`page-board-${content.menu_idx}-${isMobile}`}
                        url={content?.page_file_url}
                        menuIdx={content.menu_idx}
                        // isMobile={isMobile}
                      />
                    ) : null}
                    {content?.menu_type === 'board' &&
                    content?.board_type === 'basic' &&
                    content.menu_depth === 3 ? (
                      <BasicBoard
                        key={content.board_idx}
                        board_idx={content.board_idx}
                        page_size={content.page_row}
                        page={query.page ?? 1}
                        searchText={query.search ?? ''}
                      />
                    ) : null}
                    {content?.menu_type === 'board' &&
                    content?.board_type === 'event' &&
                    content.menu_depth === 3 ? (
                      <EventBoard
                        key={content.board_idx}
                        board_idx={content.board_idx}
                        page_size={content.page_row}
                        page={query.page ?? 1}
                        searchText={query.search ?? ''}
                      />
                    ) : null}
                    {content?.menu_type === 'board' &&
                    content?.board_type === 'card' &&
                    content.menu_depth === 3 ? (
                      <CardBoard
                        key={content.board_idx}
                        board_idx={content.board_idx}
                        page_size={content.page_row}
                        page={query.page ?? 1}
                        searchText={query.search ?? ''}
                      />
                    ) : null}
                  </TabPanel>
                );
              })
            : null}
        </MyContainer>
      ) : (
        <MyContainer
          display="flex"
          flexDirection="column"
          alignItems="center"
          sx={{
            maxWidth: content?.menu_type === 'page' ? '100%' : '1300px',
            width: content?.menu_type === 'page' ? '100%' : '1300px',
          }}
        >
          <Title sx={{ mb: '16px' }}>{currentCategory.menu_title}</Title>
          {currentCategory.menu_type !== 'category' ? (
            <div>
              {content?.menu_type === 'page' && content.menu_depth === 1 ? (
                <InnerHtml url={content?.page_file_url} />
              ) : null}
              {content?.menu_type === 'board' &&
              content?.board_type === 'basic' &&
              content.menu_depth === 1 ? (
                <BasicBoard
                  key={content.board_idx}
                  board_idx={content.board_idx}
                  page_size={content.page_row}
                  page={query.page ?? 1}
                  searchText={query.search ?? ''}
                />
              ) : null}
              {content?.menu_type === 'board' &&
              content?.board_type === 'event' &&
              content.menu_depth === 1 ? (
                <EventBoard
                  key={content.board_idx}
                  board_idx={content.board_idx}
                  page_size={content.page_row}
                  page={query.page ?? 1}
                  searchText={query.search ?? ''}
                />
              ) : null}
              {content?.menu_type === 'board' &&
              content?.board_type === 'card' &&
              content.menu_depth === 1 ? (
                <CardBoard
                  key={content.board_idx}
                  board_idx={content.board_idx}
                  page_size={content.page_row}
                  page={query.page ?? 1}
                  searchText={query.search ?? ''}
                />
              ) : null}
            </div>
          ) : (
            <Tabs
              value={tab}
              onChange={handleChangeTab}
              aria-label="basic tabs"
              TabIndicatorProps={{
                sx: { bgcolor: '#00b2b2', height: '0px' },
              }}
            >
              {tabList?.map((item, index) => {
                return (
                  <Tab
                    key={item.menu_idx}
                    label={
                      tab === index ? (
                        <SelectedTab>{item.menu_title}</SelectedTab>
                      ) : (
                        <UnSelectedTab>{item.menu_title}</UnSelectedTab>
                      )
                    }
                    sx={{ p: '0px', ml: '20px', mr: '20px' }}
                    style={{ minWidth: '30px' }}
                    {...a11yProps(index)}
                    component={Link}
                    to={`/menu/${item.menu_idx}`}
                  />
                );
              })}
            </Tabs>
          )}
          {currentCategory.menu_type !== 'category'
            ? null
            : tabList?.map((item, index) => {
                return (
                  <TabPanel
                    key={item.menu_idx}
                    value={tab}
                    index={index}
                    style={{ marginTop: '68px', width: '100%' }}
                  >
                    {content?.menu_type === 'page' &&
                    content.menu_depth === 2 ? (
                      <InnerHtml url={content?.page_file_url} />
                    ) : null}
                    {content?.menu_type === 'board' &&
                    content?.board_type === 'basic' &&
                    content.menu_depth === 2 ? (
                      <BasicBoard
                        key={content.board_idx}
                        board_idx={content.board_idx}
                        page_size={content.page_row}
                        page={query.page ?? 1}
                        searchText={query.search ?? ''}
                      />
                    ) : null}
                    {content?.menu_type === 'board' &&
                    content?.board_type === 'event' &&
                    content.menu_depth === 2 ? (
                      <EventBoard
                        key={content.board_idx}
                        board_idx={content.board_idx}
                        page_size={content.page_row}
                        page={query.page ?? 1}
                        searchText={query.search ?? ''}
                      />
                    ) : null}
                    {content?.menu_type === 'board' &&
                    content?.board_type === 'card' &&
                    content.menu_depth === 2 ? (
                      <CardBoard
                        key={content.board_idx}
                        board_idx={content.board_idx}
                        page_size={content.page_row}
                        page={query.page ?? 1}
                        searchText={query.search ?? ''}
                      />
                    ) : null}
                    {content?.menu_type === 'board' &&
                    content?.board_type === 'calendar' &&
                    content.menu_depth === 2 ? (
                      <CalendarBoard
                        key={content.board_idx}
                        board_idx={content.board_idx}
                        page_size={content.page_row}
                        page={query.page ?? 1}
                      />
                    ) : null}
                    <Tabs
                      value={subTab}
                      onChange={handleChangeSubTab}
                      aria-label="basic tabs"
                      centered
                      TabIndicatorProps={{
                        sx: {
                          bgcolor: '#fff',
                          height: '0px',
                        },
                      }}
                    >
                      {!item.menu
                        ? null
                        : item.menu.map((subItem, jndex) => {
                            return (
                              <Tab
                                key={subItem.menu_idx}
                                label={
                                  subTab === jndex ? (
                                    <SelectedInnerTab>
                                      {subItem.menu_title}
                                    </SelectedInnerTab>
                                  ) : (
                                    <UnSelectedInnerTab>
                                      {subItem.menu_title}
                                    </UnSelectedInnerTab>
                                  )
                                }
                                sx={{
                                  pl: '24px',
                                  pr: '24px',
                                  ml: '5px',
                                  mr: '5px',
                                  bgcolor:
                                    subTab === jndex ? '#495057' : '#fff',
                                  border: '1px solid #495057',
                                  borderRadius: '40px',
                                }}
                                style={{ minWidth: '140px' }}
                                {...a11yProps(index)}
                                component={Link}
                                to={`/menu/${subItem.menu_idx}`}
                              />
                            );
                          })}
                    </Tabs>
                    {item.menu
                      ? item.menu.map((subItem, jndex) => {
                          return (
                            <TabPanel
                              key={subItem.menu_idx}
                              value={subTab}
                              index={jndex}
                              style={{ marginTop: '68px', width: '100%' }}
                            >
                              {content?.menu_type === 'page' &&
                              content.menu_depth === 3 ? (
                                // <InnerHtml url={content?.page_file_url} />
                                <PageBoard
                                  // key={`page-board-${content.menu_idx}-${isMobile}`}
                                  url={content?.page_file_url}
                                  menuIdx={content.menu_idx}
                                  // isMobile={isMobile}
                                />
                              ) : null}
                              {content?.menu_type === 'board' &&
                              content?.board_type === 'basic' &&
                              content.menu_depth === 3 ? (
                                <BasicBoard
                                  key={content.board_idx}
                                  board_idx={content.board_idx}
                                  page_size={content.page_row}
                                  page={query.page ?? 1}
                                  searchText={query.search ?? ''}
                                />
                              ) : null}
                              {content?.menu_type === 'board' &&
                              content?.board_type === 'event' &&
                              content.menu_depth === 3 ? (
                                <EventBoard
                                  key={content.board_idx}
                                  board_idx={content.board_idx}
                                  page_size={content.page_row}
                                  page={query.page ?? 1}
                                  searchText={query.search ?? ''}
                                />
                              ) : null}
                              {content?.menu_type === 'board' &&
                              content?.board_type === 'card' &&
                              content.menu_depth === 3 ? (
                                <Box
                                  key={content.board_idx}
                                  sx={{ mb: '48px' }}
                                >
                                  <CardBoard
                                    board_idx={content.board_idx}
                                    page_size={content.page_row}
                                    page={query.page ?? 1}
                                    searchText={query.search ?? ''}
                                  />
                                </Box>
                              ) : null}
                            </TabPanel>
                          );
                        })
                      : null}
                  </TabPanel>
                );
              })}
        </MyContainer>
      )}
    </Box>
  );
};

export default MenuRoot;
