import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  SvgIcon,
  IconButton,
  useTheme,
  useMediaQuery,
  Drawer,
  Dialog,
} from '@mui/material';
import moment from 'moment';
import { getCalendarColor, getStringMomentDayOfWeek } from './calendar_util';
import { ReactComponent as IconClose } from '../../assets/images/calendar/ic_close_24.svg';

const MoreIcon = ({ textColor }) => {
  return (
    <SvgIcon viewBox="0 0 17 16" style={{ width: 16, height: 16 }}>
      <path
        d="M8.42969 8.75C8.8439 8.75 9.17969 8.41421 9.17969 8C9.17969 7.58579 8.8439 7.25 8.42969 7.25C8.01547 7.25 7.67969 7.58579 7.67969 8C7.67969 8.41421 8.01547 8.75 8.42969 8.75Z"
        fill={textColor}
      />
      <path
        d="M8.42969 4.75C8.8439 4.75 9.17969 4.41421 9.17969 4C9.17969 3.58579 8.8439 3.25 8.42969 3.25C8.01547 3.25 7.67969 3.58579 7.67969 4C7.67969 4.41421 8.01547 4.75 8.42969 4.75Z"
        fill={textColor}
      />
      <path
        d="M8.42969 12.75C8.8439 12.75 9.17969 12.4142 9.17969 12C9.17969 11.5858 8.8439 11.25 8.42969 11.25C8.01547 11.25 7.67969 11.5858 7.67969 12C7.67969 12.4142 8.01547 12.75 8.42969 12.75Z"
        fill={textColor}
      />
    </SvgIcon>
  );
};

MoreIcon.propTypes = {
  textColor: PropTypes.string,
};

MoreIcon.defaultProps = {
  textColor: 'white',
};

const CalendarDateDialog = ({
  open,
  onClose,
  schedules,
  yyyymmdd,
  onClickSchedule,
}) => {
  const theme = useTheme();
  const isDeskTop = useMediaQuery(theme.breakpoints.up('desktop'));
  const mmdd = yyyymmdd % 10000;
  const dd = mmdd % 100;
  const mm = (mmdd - dd) / 100;
  const dayOfWeek = moment(`${yyyymmdd}`, 'YYYYMMDD').day();
  const strDay = getStringMomentDayOfWeek(dayOfWeek);

  if (isDeskTop) {
    return (
      <Dialog open={open} onClose={onClose}>
        <Box sx={{ minWidth: '240px', padding: '24px 24px 20px 24px' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box
              sx={{
                fontWeight: 700,
                fontSize: '18px',
                lineHeight: '28px',
                color: '#212529',
              }}
            >{`${mm}월 ${dd}일 ${strDay}`}</Box>
            <IconButton sx={{ padding: 0 }} onClick={() => onClose()}>
              <IconClose />
            </IconButton>
          </Box>
          <Box sx={{ marginTop: '12px' }}>
            {schedules.map(schedule => {
              const colorInfo = getCalendarColor(schedule.calendar_type);
              return (
                <Box
                  key={`schedule-item-${schedule.idx}`}
                  sx={{ padding: '4px 0' }}
                >
                  <Box
                    sx={{
                      backgroundColor: colorInfo.bgColor,
                      color: colorInfo.textColor,
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      borderRadius: '12px',
                    }}
                  >
                    <Box
                      sx={{
                        marginLeft: '16px',
                        fontWeight: 500,
                        fontSize: '13px',
                        lineHeight: '14px',

                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {schedule.title}
                    </Box>
                    <IconButton
                      sx={{ padding: '3px 4px' }}
                      onClick={() => {
                        if (onClickSchedule) {
                          onClickSchedule(schedule.idx);
                        }
                      }}
                    >
                      <MoreIcon textColor={colorInfo.textColor} />
                    </IconButton>
                  </Box>
                </Box>
              );
            })}
          </Box>
        </Box>
      </Dialog>
    );
  }

  return (
    <Drawer
      anchor="bottom"
      open={open}
      onClose={onClose}
      PaperProps={{
        style: { borderTopLeftRadius: '10px', borderTopRightRadius: '10px' },
      }}
    >
      <Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            margin: '24px 24px 0 24px',
          }}
        >
          <Box>{`${mm}월 ${dd}일 ${strDay}`}</Box>
          <IconButton sx={{ padding: 0 }} onClick={() => onClose()}>
            <IconClose />
          </IconButton>
        </Box>
        <Box
          sx={{
            marginTop: '20px',
            fontWeight: 500,
            fontSize: '13px',
            lineHeight: '14px',
          }}
        >
          {schedules.map(schedule => {
            const colorInfo = getCalendarColor(schedule.calendar_type);
            return (
              <Box
                key={`schedule-item-${schedule.idx}`}
                sx={{ padding: '8px 32px' }}
              >
                <Box
                  sx={{
                    height: '30px',
                    backgroundColor: colorInfo.bgColor,
                    color: colorInfo.textColor,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    borderRadius: '15px',
                  }}
                >
                  <Box
                    sx={{
                      marginLeft: '16px',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {schedule.title}
                  </Box>
                  <IconButton
                    sx={{ padding: '7px 8px' }}
                    onClick={() => {
                      if (onClickSchedule) {
                        onClickSchedule(schedule.idx);
                      }
                    }}
                  >
                    <MoreIcon textColor={colorInfo.textColor} />
                  </IconButton>
                </Box>
              </Box>
            );
          })}
        </Box>
        <Box sx={{ height: '16px' }} />
      </Box>
    </Drawer>
  );
};

CalendarDateDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  schedules: PropTypes.arrayOf(
    PropTypes.shape({
      calendar_type: PropTypes.string,
      idx: PropTypes.number,
      title: PropTypes.string,
      start_date: PropTypes.string,
      end_date: PropTypes.string,
      startDateNumber: PropTypes.number,
      endDateNumber: PropTypes.number,
    }),
  ),
  yyyymmdd: PropTypes.number.isRequired,
  onClickSchedule: PropTypes.func,
};

CalendarDateDialog.defaultProps = {
  open: false,
  schedules: [],
  onClickSchedule: null,
};

export default CalendarDateDialog;
