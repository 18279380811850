import { React } from 'react';
import { IconButton, Box, Toolbar, Button, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ReactComponent as LogoIcon } from '../../assets/images/logo_desktop.svg';
import { ReactComponent as SearchIcon } from '../../assets/images/m-search.svg';
import PCToolbarNav, {
  ProjectOpenText,
  ProjectOpenLink,
  PencilIcon,
} from './PCToolbarNav';
// import NewsLetter from '../popups/NewsLetter'; // 뉴스 레터

// 아래로 나오는 메뉴 width만큼 할당
const ToolbarBox = styled(Toolbar)(() => ({
  padding: 0,
  margin: 'auto',
  height: '80px',
  maxWidth: '1280px',
}));

const MenuBox = styled(Box)(() => ({
  padding: 0,
  marginLeft: '60px',
  display: 'flex',
}));
const OneMenu = styled(Button)(() => ({
  padding: 0,
  transition: 'none',
  borderBottom: 'solid 3px #fff',
  width: '150px',
  textAlign: 'start',
  '&:hover': {
    background: '#fff',
  },
}));
const MenuTitle = styled(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: '700',
  fontSize: '18px',
  lineHeight: '28px',
  textAlign: 'start',
  letterSpacing: '-0.3px',
  display: 'inline',
  color: '#495057',
  '&:hover': {
    paddingBottom: '6px',
    borderBottom: '2px solid #00C4C4',
    color: '#00b2b2',
  },
}));
const OneMenuTitle = ({ close, m, isChecked, setOpen, nonDisplay }) => {
  const style = isChecked ? { color: '#00b2b2' } : { color: '#495057' };
  const nonStyle = nonDisplay ? { display: 'none' } : { display: 'block' };
  const navigate = useNavigate();
  const handleClick = menuIdx => {
    navigate(`/menu/${menuIdx}`);
  };

  return (
    <OneMenu
      sx={nonStyle}
      onMouseEnter={() => {
        setOpen(true);
      }}
      onClick={() => {
        window.dataLayer.push({
          event: 'ga.custom.tag',
          GACategory: 'GNB',
          GALabel: m.menu_title,
          GAAction: '클릭',
        });
        if ('menu' in m) handleClick(m.menu[0].menu_idx);
        else handleClick(m.menu_idx);
        close();
      }}
    >
      <MenuTitle sx={style}>{m.menu_title}</MenuTitle>
    </OneMenu>
  );
};
OneMenuTitle.propTypes = {
  m: PropTypes.object.isRequired,
  isChecked: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  nonDisplay: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
};
const PCTopHeader = styled(Box)(() => ({
  position: 'relative',
  background: '#fff',
  borderBottom: '1px solid #E6EAED',
  zIndex: 101,
}));

const PCToolbar = ({
  searchOpen,
  setSearchOpen,
  menu,
  menuList,
  desktopOpen,
  setDesktopOpen,
}) => {
  const navigate = useNavigate();
  const handleClick = () => {
    window.dataLayer.push({
      event: 'ga.custom.tag',
      GACategory: 'GNB',
      GALabel: '홈',
      GAAction: '클릭',
    });
    navigate('/');
  };

  const handleMakeProject = () => {
    window.dataLayer.push({
      event: 'ga.custom.tag',
      GACategory: 'GNB',
      GALabel: '프로젝트 만들기',
      GAAction: '클릭',
    });
    const url =
      'https://www.wadiz.kr/web/wsub/openfunding/reward?utm_source=makercenter&utm_medium=button&utm_campaign=create_project';
    window.open(url, '_blank');
  };

  const close = () => {
    setDesktopOpen(false);
  };

  return (
    <PCTopHeader>
      <ToolbarBox id="back-to-top-anchor">
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Button
            sx={{ padding: '0px' }}
            onClick={() => {
              close();
              handleClick();
            }}
          >
            <LogoIcon />
          </Button>
          <MenuBox>
            {menuList.map((m, i) => {
              let isChecked = false;
              if ('menu' in m) {
                for (let k = 0; k < m.menu.length; k += 1) {
                  if (m.menu[k].menu_title === menu.menu_title)
                    isChecked = true;
                  else if ('menu' in m.menu[k]) {
                    for (let l = 0; l < m.menu[k].menu.length; l += 1) {
                      if (m.menu[k].menu[l].menu_title === menu.menu_title)
                        isChecked = true;
                    }
                  }
                }
              }
              // 메뉴 너무많이 받아와서 일단 자름
              if (i >= 5)
                return (
                  <OneMenuTitle
                    key={m.menu_title}
                    setOpen={setDesktopOpen}
                    m={m}
                    isChecked={isChecked}
                    nonDisplay
                    close={close}
                  />
                );
              return (
                <OneMenuTitle
                  key={m.menu_title}
                  setOpen={setDesktopOpen}
                  m={m}
                  isChecked={isChecked}
                  nonDisplay={false}
                  close={close}
                />
              );
            })}
          </MenuBox>
        </Box>
        <IconButton
          sx={{ marginRight: '34px' }}
          onClick={() => {
            setSearchOpen(current => !current);
            if (desktopOpen) {
              setDesktopOpen(false);
            }
          }}
        >
          {searchOpen === false && <SearchIcon width={24} height={24} />}
          {searchOpen === true && <CloseIcon sx={{ color: 'gray' }} />}
        </IconButton>
        <ProjectOpenLink onClick={handleMakeProject}>
          <PencilIcon />
          <ProjectOpenText>프로젝트 만들기</ProjectOpenText>
        </ProjectOpenLink>
      </ToolbarBox>
      <PCToolbarNav
        selectedm={menu}
        menu={menuList}
        close={close}
        open={desktopOpen}
      />
    </PCTopHeader>
  );
};
PCToolbar.propTypes = {
  searchOpen: PropTypes.bool.isRequired,
  setSearchOpen: PropTypes.func.isRequired,
  menu: PropTypes.object,
  menuList: PropTypes.array,
  desktopOpen: PropTypes.bool.isRequired,
  setDesktopOpen: PropTypes.func.isRequired,
};

PCToolbar.defaultProps = {
  menu: {},
  menuList: [],
};

export default PCToolbar;
