import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import InnerHtml from './InnerHtml';

const MyBottomMenu = ({ menuIdx }) => {
  const isMobile = useMediaQuery(useTheme().breakpoints.down('desktop'));

  const handleClickGoButton = () => {
    if (menuIdx === 37) {
      // 스토어
      window.open('https://makercenter.wadiz.kr/board/card/425', '_blank');
    } else {
      window.dataLayer.push({
        event: 'ga.custom.tag',
        GACategory: '하단고정바',
        GALabel: '프로젝트 간편 등록하기',
        GAAction: '클릭',
      });
      window.open(
        'https://www.wadiz.kr/web/wsub/openfunding/reward?utm_source=makercenter&utm_medium=button&utm_campaign=create_project',
        '_blank',
      );
    }
  };
  if (menuIdx === 36 && isMobile) {
    return (
      <Box
        sx={{
          position: 'fixed',
          backgroundColor: 'white',
          bottom: 0,
          width: '100%',
          padding: '16px',
          borderTopLeftRadius: '16px',
          borderTopRightRadius: '16px',
          boxShadow:
            '0px -1px 5px rgba(10, 22, 70, 0.06), 0px -1px 1px rgba(10, 22, 70, 0.1)',
        }}
      >
        <Box
          sx={{
            padding: '14px 0',
            textAlign: 'center',
            borderRadius: '3px',
            backgroundColor: '#557CF2',
            cursor: 'pointer',
            fontWeight: 700,
            fontSize: '14px',
            lineHeight: '20px',
            color: 'white',
          }}
          onClick={handleClickGoButton}
          data-promo-make-project
        >
          프로젝트 간편 등록하기
        </Box>
      </Box>
    );
  }
  if (menuIdx === 36 && !isMobile) {
    return (
      <Box
        sx={{
          position: 'fixed',
          backgroundColor: '#212529',
          bottom: 0,
          left: 0,
          width: '100vw',
          boxShadow:
            '0px 0px 5px rgba(10, 22, 70, 0.06), 0px -4px 6px rgba(10, 22, 70, 0.1)',
        }}
      >
        <Box
          sx={{
            maxWidth: '1280px',
            margin: '0 auto',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '16px',
          }}
        >
          <Box
            sx={{
              fontWeight: 700,
              fontSize: '16px',
              lineHeight: '28px',
              color: 'white',
            }}
          >
            😄 지금 등록하고 펀딩 가이드를 무료로 받으세요.
          </Box>
          <Box
            sx={{
              fontWeight: 500,
              fontSize: '16px',
              lineHeight: '28px',
              color: 'white',
              padding: '10px 28px',
              backgroundColor: '#557CF2',
              borderRadius: '3px',
              cursor: 'pointer',
            }}
            onClick={handleClickGoButton}
            data-promo-make-project
          >
            프로젝트 간편 등록하기
          </Box>
        </Box>
      </Box>
    );
  }
  if (menuIdx === 37 && isMobile) {
    return (
      <Box
        sx={{
          position: 'fixed',
          backgroundColor: 'white',
          bottom: 0,
          width: '100%',
          padding: '16px',
          borderTopLeftRadius: '16px',
          borderTopRightRadius: '16px',
          boxShadow:
            '0px -1px 5px rgba(10, 22, 70, 0.06), 0px -1px 1px rgba(10, 22, 70, 0.1)',
        }}
      >
        <Box
          sx={{
            padding: '14px 0',
            textAlign: 'center',
            borderRadius: '3px',
            backgroundColor: '#557CF2',
            cursor: 'pointer',
            fontWeight: 700,
            fontSize: '14px',
            lineHeight: '20px',
            color: 'white',
          }}
          onClick={handleClickGoButton}
        >
          와디즈 스토어 입점 가이드
        </Box>
      </Box>
    );
  }
  if (menuIdx === 37 && !isMobile) {
    return (
      <Box
        sx={{
          position: 'fixed',
          backgroundColor: '#212529',
          bottom: 0,
          left: 0,
          width: '100vw',
          boxShadow:
            '0px 0px 5px rgba(10, 22, 70, 0.06), 0px -4px 6px rgba(10, 22, 70, 0.1)',
        }}
      >
        <Box
          sx={{
            maxWidth: '1280px',
            margin: '0 auto',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '16px',
          }}
        >
          <Box
            sx={{
              fontWeight: 700,
              fontSize: '16px',
              lineHeight: '28px',
              color: 'white',
            }}
          >
            📦 펀딩에 성공한 프로젝트라면 지금 바로 와디즈 스토어 입점 가이드를
            확인해 보세요.
          </Box>
          <Box
            sx={{
              fontWeight: 500,
              fontSize: '16px',
              lineHeight: '28px',
              color: 'white',
              padding: '10px 28px',
              backgroundColor: '#557CF2',
              borderRadius: '3px',
              cursor: 'pointer',
            }}
            onClick={handleClickGoButton}
          >
            와디즈 스토어 입점 가이드
          </Box>
        </Box>
      </Box>
    );
  }
  return null;
};
MyBottomMenu.propTypes = {
  menuIdx: PropTypes.number,
};

MyBottomMenu.defaultProps = {
  menuIdx: null,
};

const PageBoard = ({ url, menuIdx }) => {
  const observerRef = useRef();
  const [bottomMenu, setBottomMenu] = useState(null);
  const isMobile = useMediaQuery(useTheme().breakpoints.down('desktop'));
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (menuIdx === 36 || menuIdx === 37) {
      const callback = entries => {
        entries.some(entry => {
          if (entry.isIntersecting) {
            setBottomMenu(null);
            return true;
          }
          setBottomMenu(menuIdx);
          return false;
        });
      };
      const observer = new IntersectionObserver(callback);
      observer.observe(observerRef.current);
      return () => {
        observer.disconnect();
      };
    }
  });

  let sxObserver = { height: '1px' };
  if (menuIdx === 36) {
    sxObserver = {
      position: 'relative',
      height: isMobile ? '218px' : '360px',
      width: '1px',
      top: isMobile ? '-218px' : '-360px',
    };
  }
  if (menuIdx === 37) {
    sxObserver = {
      position: 'relative',
      height: isMobile ? '218px' : '208px',
      width: '1px',
      top: isMobile ? '-218px' : '-208px',
    };
  }
  return (
    <Box>
      <InnerHtml url={url} />
      <Box sx={{ height: '1px' }}>
        <Box sx={sxObserver} ref={observerRef} />
      </Box>
      {(bottomMenu === 36 || bottomMenu === 37) && (
        <MyBottomMenu menuIdx={menuIdx} />
      )}
    </Box>
  );
};

PageBoard.propTypes = {
  url: PropTypes.string,
  menuIdx: PropTypes.number,
};

PageBoard.defaultProps = {
  url: '',
  menuIdx: null,
};

export default PageBoard;
