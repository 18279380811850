import React, { useEffect, useState } from 'react';
import { styled } from '@mui/system';
import {
  Fade,
  Fab,
  useMediaQuery,
  useTheme,
  Box,
  Drawer,
  useScrollTrigger,
  Collapse,
} from '@mui/material';
import { useRecoilValue } from 'recoil';
import { useParams, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { getfindMenu } from '../../lib/utils';
import { detailState, menuState } from '../../atoms';
import { ReactComponent as UpIcon } from '../../assets/images/m-upBtn.svg';
import Sidebar from './Sidebar';
import SearchDrawer from '../search/SearchDrawer';
import Banner from '../header/Banner';
import MobileToolbar from '../header/MobileToolbar';
import PCToolbar from '../header/PCToolbar';

function ScrollTop(props) {
  // eslint-disable-next-line react/prop-types
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = event => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      '#back-to-top-anchor',
    );

    if (anchor) {
      anchor.scrollIntoView({
        block: 'center',
      });
    }
  };

  return (
    <Fade in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: 'fixed', bottom: 16, right: 16, zIndex: 10 }}
      >
        {children}
      </Box>
    </Fade>
  );
}

ScrollTop.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
};

const Container = styled(Box)(() => ({
  whiteSpace: 'nowrap',
}));
const BackgroundBox = styled(Box)(() => ({
  position: 'absolute',
  background: 'rgba(0,0,0,0.5)',
  width: '100%',
  minHeight: '100vh',
  zIndex: 100,
}));
const SearchDrawerBox = styled(Box)(() => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  zIndex: 10,
}));

const Header = props => {
  const menuList = useRecoilValue(menuState);
  let { menuId } = useParams();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [desktopOpen, setDesktopOpen] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  menuId = parseInt(menuId, 10);
  const isDeskTop = useMediaQuery(useTheme().breakpoints.up('desktop'));
  const detailData = useRecoilValue(detailState);

  let menu;
  if (menuId) {
    menu = getfindMenu(menuList, menuId);
  }

  // 모바일 && 검색 searchDrawer open && url 변화시 검색창 닫기
  useEffect(() => {
    if (searchOpen) {
      setSearchOpen(false);
    }
  }, [location.pathname]);

  return (
    <Container sx={{ position: 'relative' }}>
      <Banner />
      <Box position="relative">
        {isDeskTop ? (
          <PCToolbar
            searchOpen={searchOpen}
            setSearchOpen={setSearchOpen}
            menu={menu}
            menuList={menuList}
            desktopOpen={desktopOpen}
            setDesktopOpen={setDesktopOpen}
          />
        ) : (
          <MobileToolbar
            searchOpen={searchOpen}
            setSearchOpen={setSearchOpen}
            title={menu?.menu_title ? menu.menu_title : detailData?.board_title}
            setOpen={setOpen}
          />
        )}
        <SearchDrawerBox>
          <Collapse
            in={searchOpen}
            sx={{
              width: '100%',
              background: '#fff',
            }}
          >
            <Box sx={{ pt: isDeskTop ? '130px' : '90px', pb: '100px' }}>
              <SearchDrawer
                searchOpen={searchOpen}
                setSearchOpen={setSearchOpen}
              />
            </Box>
          </Collapse>
        </SearchDrawerBox>
      </Box>
      <Drawer
        anchor="left"
        open={open}
        onClose={() => setOpen(false)}
        PaperProps={{ sx: { width: '100%' } }}
      >
        <Box sx={{ width: '100%', height: '100%' }}>
          <Sidebar close={() => setOpen(false)} />
        </Box>
      </Drawer>
      <ScrollTop {...props}>
        <Fab size="small" aria-label="scroll back to top">
          <UpIcon />
        </Fab>
      </ScrollTop>
      {isDeskTop && (
        <BackgroundBox
          onMouseEnter={() => {
            setDesktopOpen(false);
          }}
          sx={{
            height: document.body.offsetHeight,
            display: desktopOpen ? 'block' : 'none',
          }}
        />
      )}
    </Container>
  );
};

export default Header;
