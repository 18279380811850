import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Box, styled } from '@mui/material';
import qs from 'qs';
import dayjs from 'dayjs';
import moment from 'moment';
import BasicTable from '../components/common/BasicTable';
import { boardApi } from '../api/board';
import Pagenation from '../components/common/Pagenation';
import TableFilter from '../components/common/TableFilter';
import Selector from '../components/common/Selector';
import CategorySelector from '../components/common/CategorySelector';
import { showError } from '../lib/utils';
import { ReactComponent as NewIcon } from '../assets/images/ic-new.svg';
import WideRollingBanner from '../components/home/KVBanner/WideRollingBanner';

const searchList = [
  { value: 1, name: '제목+내용', param: 'title_contents' },
  { value: 2, name: '제목', param: 'title' },
  { value: 3, name: '내용', param: 'contents' },
  { value: 4, name: '해시태그', param: 'hashtag' },
];

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  [theme.breakpoints.down('desktop')]: {
    minWidth: '360px',
  },
}));

const EmptyBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '400px',
  color: '#495057',
  fontWeight: '400',
  fontSize: '15px',
  lineHeight: '24px',
  [theme.breakpoints.up('desktop')]: {
    fontSize: '18px',
    lineHeight: '28px',
  },
}));

const BasicBoard = ({ board_idx, page, page_size, searchText }) => {
  const [dataList, setDataList] = useState({
    list: [],
    total_count: 0,
    page_row: 0,
    category1: '',
    category2: '',
    banner: [],
  });
  const [category1List, setCategory1List] = useState([]);
  const [category1, setCategory1] = useState('');
  const [categoryChanged, setCategoryChanged] = useState(false);
  const [category2List, setCategory2List] = useState([]);
  const [category2, setCategory2] = useState('');
  const [searchType, setSearchType] = useState(searchList[0]);
  const [numbering, setNumbering] = useState(true);
  const [currentPage, setCurrentPage] = useState(Number(page));
  const [loading, setLoading] = useState(true);
  const [currentBannerIndex, setCurrentBannerIndex] = useState(0);
  const navigate = useNavigate();
  const { menuId } = useParams();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('desktop'));
  const params = {
    board_type: 'basic',
    board_idx,
    page,
    page_size,
    platform: 'PC',
    search_type: 'all',
    search_text: searchText,
    category1_idx: '',
    category2_idx: '',
  };
  const handleClickItem = (idx, rownum) => {
    const data = {
      rownum,
      search_type: searchType.param,
      search_text: searchText,
      category1_idx: category1,
      category2_idx: category2,
      page: params.page,
    };
    const query = qs.stringify(data);
    // console.log(query);
    navigate(`/board/basic/${idx}?${query}`);
  };
  const handleChangeSearchType = value => {
    setSearchType(searchList.find(element => element.value === value));
  };
  const TableHeader = [];
  if (!isMobile) {
    TableHeader.push({
      accessor: 'rownum',
      Header: '',
      Cell: ({ row }) => {
        const rowData = row.original;
        return (
          <Box
            sx={{
              fontWeight: '400',
              fontSize: '15px',
              lineHeight: '24px',
              color: '#212529',
              display: 'flex',
              height: '100%',
              alignItems: 'center',
            }}
          >
            {rowData.rownum}
          </Box>
        );
      },
    });
    TableHeader.push({
      accessor: 'title',
      Header: '',
      Cell: ({ row }) => {
        const rowData = row.original;
        return (
          <Box
            display="flex"
            alignItems="center"
            sx={{ cursor: 'pointer', width: '100%' }}
            onClick={() => handleClickItem(rowData.idx, rowData.rownum)}
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              flexGrow={1}
            >
              <Box display="flex">
                <Box
                  sx={{
                    fontWeight: '400',
                    fontSize: isMobile ? '14px' : '15px',
                    lineHeight: isMobile ? '20px' : '24px',
                    color: '#90949c',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {numbering && rowData.reg_date
                    ? dayjs(new Date(rowData.reg_date)).format('YYYY.MM.DD')
                    : ''}
                </Box>
                <Box
                  sx={{
                    fontWeight: '400',
                    fontSize: isMobile ? '14px' : '15px',
                    lineHeight: isMobile ? '20px' : '24px',
                    color: '#495057',
                    display: 'flex',
                    alignItems: 'center',
                    ml: numbering ? '20px' : '0px',
                  }}
                >
                  {rowData.category1_title ?? ''}
                  {rowData.category2_title
                    ? ` > ${rowData.category2_title}`
                    : ''}
                </Box>
              </Box>
              <Box display="flex" alignItems="baseline" sx={{ mt: '8px' }}>
                {rowData.important_yn === 'Y' ? (
                  <Box
                    sx={{
                      width: '27px',
                      minWidth: '27px',
                      height: '18px',
                      bgcolor: '#00c4c4',
                      borderRadius: '3px',
                      fontWeight: '700',
                      fontSize: '10px',
                      lineHeight: '14px',
                      color: '#fff',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      mr: '12px',
                    }}
                  >
                    중요
                  </Box>
                ) : null}
                <Box
                  sx={{
                    fontWeight: isMobile ? '500' : '400',
                    fontSize: isMobile ? '16px' : '20px',
                    lineHeight: '28px',
                    color: '#212529',
                    display: 'flex',
                    alignItems: 'center',
                    wordBreak: 'break-all',
                  }}
                >
                  {rowData.title}
                </Box>
                {moment(rowData.reg_date).format('YYYY-MM-DD') ===
                moment().format('YYYY-MM-DD') ? (
                  <Box>
                    <NewIcon
                      style={{ marginLeft: isMobile ? '8px' : '12px' }}
                    />
                  </Box>
                ) : null}
              </Box>
              <Box
                sx={{
                  fontWeight: '400',
                  fontSize: '14px',
                  lineHeight: '20px',
                  color: '#adb5bd',
                  display: 'flex',
                  alignItems: 'center',
                  mt: '8px',
                }}
              >
                {rowData.summary}
              </Box>
            </Box>
            {rowData.image_url && rowData.image_use_yn === 'Y' ? (
              <Box
                component="img"
                alt="image"
                sx={{
                  height: '120px',
                  width: '200px',
                  borderRadius: '0px',
                  ml: '20px',
                }}
                src={rowData.image_url}
              />
            ) : null}
          </Box>
        );
      },
    });
  } else {
    TableHeader.push({
      accessor: 'title',
      Header: '',
      Cell: ({ row }) => {
        const rowData = row.original;
        return (
          <Box
            display="flex"
            alignItems="center"
            sx={{ cursor: 'pointer', width: '100%' }}
            onClick={() => handleClickItem(rowData.idx, rowData.rownum)}
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              flexGrow={1}
            >
              <Box display="flex">
                <Box
                  sx={{
                    fontWeight: '400',
                    fontSize: isMobile ? '14px' : '15px',
                    lineHeight: isMobile ? '20px' : '24px',
                    color: '#90949c',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {numbering && rowData.reg_date
                    ? dayjs(new Date(rowData.reg_date)).format('YYYY.MM.DD')
                    : ''}
                </Box>
                <Box
                  sx={{
                    fontWeight: '400',
                    fontSize: isMobile ? '14px' : '15px',
                    lineHeight: isMobile ? '20px' : '24px',
                    color: '#495057',
                    display: 'flex',
                    alignItems: 'center',
                    ml: numbering ? '20px' : '0px',
                  }}
                >
                  {rowData.category1_title ?? ''}
                  {rowData.category2_title
                    ? ` > ${rowData.category2_title}`
                    : ''}
                </Box>
              </Box>
              <Box display="flex">
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-start"
                  flexGrow={1}
                >
                  <Box display="flex" alignItems="baseline" sx={{ mt: '8px' }}>
                    {rowData.important_yn === 'Y' ? (
                      <Box
                        sx={{
                          width: '27px',
                          minWidth: '27px',
                          height: '18px',
                          bgcolor: '#00c4c4',
                          borderRadius: '3px',
                          fontWeight: '700',
                          fontSize: '10px',
                          lineHeight: '14px',
                          color: '#fff',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          mr: '12px',
                        }}
                      >
                        중요
                      </Box>
                    ) : null}
                    <Box
                      sx={{
                        fontWeight: isMobile ? '500' : '400',
                        fontSize: isMobile ? '16px' : '20px',
                        lineHeight: '28px',
                        color: '#212529',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        WebkitLineClamp: 2,
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        textAlign: 'left',
                        wordBreak: 'break-all',
                      }}
                    >
                      {rowData.title}
                    </Box>
                    {moment(rowData.reg_date).format('YYYY-MM-DD') ===
                    moment().format('YYYY-MM-DD') ? (
                      <Box>
                        <NewIcon
                          style={{ marginLeft: isMobile ? '8px' : '12px' }}
                        />
                      </Box>
                    ) : null}
                  </Box>
                  <Box
                    sx={{
                      fontWeight: '400',
                      fontSize: '14px',
                      lineHeight: '20px',
                      color: '#adb5bd',
                      mt: '8px',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      WebkitLineClamp: 2,
                      display: '-webkit-box',
                      WebkitBoxOrient: 'vertical',
                      textAlign: 'left',
                    }}
                  >
                    {rowData.summary}
                  </Box>
                </Box>
                {rowData.image_url && rowData.image_use_yn === 'Y' ? (
                  <Box
                    component="img"
                    alt="image"
                    sx={{
                      height: '60px',
                      width: '105px',
                      borderRadius: '0px',
                      ml: '20px',
                      mt: '10px',
                    }}
                    src={rowData.image_url}
                  />
                ) : null}
              </Box>
            </Box>
          </Box>
        );
      },
    });
  }

  const requestList = async () => {
    setLoading(true);
    try {
      params.search_type = searchType.param;
      params.category1_idx = category1;
      params.category2_idx = category2;
      params.platform = isMobile ? 'MOBILE' : 'PC';
      //   console.log('param', params);
      const { data } = await boardApi.basicList(params);
      // console.log(data);
      setDataList({
        list: data.data.lists,
        total_count: data.data.total_count,
        page_row: data.data.page_row,
        banner: data.data.banner,
      });
      setNumbering(data.data.numbering_yn === 'Y');
      const { category } = data.data;
      if (category && category[0]) {
        setCategory1List(category[0].category);
        if (!category1) {
          setCategory1('');
        }
      }
      //   if (data.result === 'success') {

      //   }
    } catch (err) {
      showError(err);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = searchStr => {
    params.search_text = searchStr;
    params.page = 1;
    setCurrentPage(1);
    navigate(
      `/menu/${menuId}?page=${params.page}&search=${params.search_text}`,
    );
  };

  const handleCategory1Change = value => {
    setCategory1(value);
  };
  const handleCategory2Change = value => {
    setCategory2(value);
  };

  const handlePage = changed_page => {
    setCurrentPage(changed_page);
    navigate(
      `/menu/${menuId}?page=${changed_page}&search=${params.search_text}`,
    );
  };

  const handleClickBanner = () => {
    if (dataList.banner[currentBannerIndex].popup_yn === 'N') {
      window.location.href = dataList.banner[currentBannerIndex].image_link;
    } else {
      window.open(dataList.banner[currentBannerIndex].image_link, '_blank');
    }
  };

  React.useLayoutEffect(() => {
    if (category1List) {
      const list2 = category1List.find(
        element => element.category_idx === category1,
      );
      if (list2?.category) {
        setCategory2List(list2.category);
        if (list2.category.length > 0) {
          setCategory2('');
        }
      } else {
        setCategory2List([]);
        setCategory2('');
      }
    }
    setCategoryChanged(prev => !prev);
  }, [category1]);

  React.useLayoutEffect(() => {
    requestList();
    setCurrentPage(Number(page));
    window.scrollTo(0, 0);
  }, [page, searchText, categoryChanged, category2, searchType]);

  return (
    <Container sx={{ width: '100%' }}>
      {dataList.banner.length > 0 ? (
        <WideRollingBanner
          kvBanners={dataList.banner}
          currentIndex={currentBannerIndex}
          setCurrentIndex={setCurrentBannerIndex}
          onClick={handleClickBanner}
        />
      ) : null}
      {isMobile ? (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            pl: '16px',
            pr: '16px',
            mt: '30px',
          }}
        >
          <Box display="flex" sx={{ width: '100%' }}>
            <Box>
              <Selector
                list={searchList}
                value={searchType?.value}
                onChange={handleChangeSearchType}
                sx={{ width: '120px', mr: '4px' }}
                mobile
              />
            </Box>
            <Box sx={{ flexGrow: 1 }}>
              <TableFilter
                onChange={handleSearch}
                value={params?.search_text || ''}
              />
            </Box>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{ width: '100%', mt: '18px', mb: '10px' }}
          >
            <CategorySelector
              list={category1List ?? []}
              sx={{ width: '100%', flexGrow: '1' }}
              value={category1}
              onChange={handleCategory1Change}
              disabled={(category1List || []).length === 0}
            />
            {category2List.length > 0 ? (
              <CategorySelector
                list={category2List ?? []}
                sx={{ width: '100%', ml: '10px', flexGrow: '1' }}
                value={category2}
                onChange={handleCategory2Change}
                disabled={(category2List || []).length === 0}
              />
            ) : null}
          </Box>
        </Box>
      ) : (
        <Box
          display="flex"
          justifyContent="space-between"
          sx={{ mb: '10px', width: '100%' }}
        >
          <Box display="flex">
            <CategorySelector
              list={category1List ?? []}
              sx={{ width: '140px' }}
              value={category1}
              onChange={handleCategory1Change}
              disabled={(category1List || []).length === 0}
            />
            {category2List.length > 0 ? (
              <CategorySelector
                list={category2List ?? []}
                sx={{ width: '140px', ml: '10px' }}
                value={category2}
                onChange={handleCategory2Change}
                disabled={(category2List || []).length === 0}
              />
            ) : null}
          </Box>
          <Box display="flex">
            <Selector
              list={searchList}
              value={searchType?.value}
              sx={{ width: '110px', mr: '10px' }}
              onChange={handleChangeSearchType}
            />
            <TableFilter
              onChange={handleSearch}
              value={params?.search_text || ''}
            />
          </Box>
        </Box>
      )}
      <BasicTable
        columns={TableHeader}
        data={dataList.list}
        numbering={numbering}
        mobile={isMobile}
      />
      {dataList.list.length === 0 ? null : (
        <Pagenation
          sx={
            isMobile
              ? {
                  mt: '30px',
                  pr: '16px',
                  pl: '16px',
                  width: '100%',
                }
              : { mt: '30px' }
          }
          key={board_idx}
          currentPage={currentPage}
          pageChangeHandler={handlePage}
          totalRows={dataList.total_count}
          rowsPerPage={dataList.page_row}
          mobile={isMobile}
        />
      )}
      {loading ? <Box sx={{ height: '400px' }} /> : null}
      {dataList.list.length === 0 && !loading ? (
        <EmptyBox>등록된 글이 없습니다.</EmptyBox>
      ) : null}
    </Container>
  );
};

BasicBoard.propTypes = {
  board_idx: PropTypes.number.isRequired,
  page: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  page_size: PropTypes.number,
  searchText: PropTypes.string,
  row: PropTypes.shape({
    original: PropTypes.shape({
      idx: PropTypes.number,
      title: PropTypes.string,
      contents: PropTypes.string,
      rownum: PropTypes.number,
      image_url: PropTypes.string,
      image_use_yn: PropTypes.string,
      important_yn: PropTypes.string,
      reg_date: PropTypes.bool,
      summary: PropTypes.string,
      category1_title: PropTypes.string,
      category2_title: PropTypes.string,
    }),
  }),
};

BasicBoard.defaultProps = {
  row: null,
  page: 1,
  page_size: 10,
  searchText: '',
};

export default BasicBoard;
