import React, { useEffect, useState } from 'react';
import qs from 'qs';
import { useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import queryString from 'query-string';
import { Box, styled, Link } from '@mui/material';
import { findRootByBoardId, getfindBoard } from '../../lib/utils';
import { menuState, detailState } from '../../atoms';

const BreadcrumbText = styled(Box)(({ theme }) => ({
  fontSize: '15px',
  marginBottom: '8px',

  [theme.breakpoints.up('desktop')]: {
    fontSize: '18px',
  },
}));

const Breadcrumb = () => {
  const menuList = useRecoilValue(menuState);
  const detailData = useRecoilValue(detailState);

  const location = useLocation();
  const query = queryString.parse(location.search);
  const [rootMenu, setRootMenu] = useState(null);
  const [subMenu, setSubMenu] = useState(null);
  const newQeury = qs.stringify({ page: query.page || 1 });

  useEffect(() => {
    if (!detailData || !menuList) return;

    const rootTitle = findRootByBoardId(menuList, detailData.board_idx);
    const boardId = query?.board_id
      ? Number(query.board_id)
      : detailData.board_idx;
    const subRootTitle = getfindBoard(menuList, boardId);
    setSubMenu(subRootTitle);
    setRootMenu(rootTitle);
  }, [menuList, detailData]);

  return (
    <BreadcrumbText>
      {rootMenu && (
        <>
          <Link
            href={`/menu/${rootMenu.menu[0].menu_idx}`}
            color="inherit"
            underline="hover"
          >
            {rootMenu?.menu_title}
          </Link>
          {' > '}
          <Link
            href={`/menu/${subMenu.menu_idx}?${newQeury}`}
            color="inherit"
            underline="hover"
          >
            {subMenu.menu_title}
          </Link>
        </>
      )}
    </BreadcrumbText>
  );
};

export default Breadcrumb;
