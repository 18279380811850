import React, { useEffect, useState } from 'react';
import { styled, Box, useTheme, useMediaQuery } from '@mui/material';
import { homeApi } from '../api/home';
import { homeContext } from '../lib/utils';
import KVBanner from '../components/home/KVBanner';
import ShortCut from '../components/home/ShortCut';
import AboutWadiz from '../components/home/AboutWadiz';
import Notice from '../components/home/Notice';
import MakerCalendar from '../components/home/MakerCalendar';
import Event from '../components/home/Event';
import Academy from '../components/home/Academy';
import MakerSuccessStory from '../components/home/MakerSuccessStory';
import Newsletter from '../components/home/Newsletter';
import HomePopup from '../components/home/HomePopup';
import Loading from '../components/common/Loading';

const Home = () => {
  const [homeData, setHomeData] = useState(null);
  const [popupData, setPopupData] = useState(null);
  const [open, setOpen] = useState(true);
  const theme = useTheme();
  const isDeskTop = useMediaQuery(theme.breakpoints.up('desktop'));

  // console.log('homeData :', homeData);

  // Home에 필요한 모든 데이터 호출
  useEffect(() => {
    (async () => {
      const apiParams = {
        platform: isDeskTop ? 'PC' : 'MOBILE',
      };
      try {
        const { data, status } = await homeApi.getMainData(apiParams);
        if (status === 200) {
          setHomeData(data.data);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    })();
  }, [isDeskTop]);

  // Popup 데이터 호출
  useEffect(() => {
    (async () => {
      try {
        const { data, status } = await homeApi.getPopupData();
        if (status === 200) {
          setPopupData(() => {
            const frontDatas = data.data.filter(
              popup => popup.priority !== null && popup.priority !== 0,
            );
            const rearDatas = data.data.filter(
              popup => popup.priority === null || popup.priority === 0,
            );
            const finalArr = [...frontDatas, ...rearDatas];
            return finalArr;
          });
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    })();
  }, []);

  return (
    <Container>
      {homeData === null ? (
        <Loading />
      ) : (
        <homeContext.Provider value={homeData}>
          <KVBanner />
          <FlexContainer
            sx={{
              display: isDeskTop ? 'flex' : 'block',
              mt: isDeskTop ? '80px' : '40px',
              mb: isDeskTop ? '60px' : '40px',
              justifyContent: isDeskTop ? 'space-between' : 'center',
            }}
          >
            <ShortCut />
            <AboutWadiz />
          </FlexContainer>
          <FlexContainer
            sx={{
              display: isDeskTop ? 'flex' : 'block',
              mb: isDeskTop ? '60px' : '40px',
              justifyContent: isDeskTop ? 'space-between' : 'center',
            }}
          >
            <Notice />
            <MakerCalendar />
          </FlexContainer>
          <Event />
          <MakerSuccessStory />
          <Academy />
          <Newsletter />
          {popupData === null ||
          popupData === undefined ||
          popupData.length < 1 ? null : (
            <HomePopup open={open} popupData={popupData} setOpen={setOpen} />
          )}
        </homeContext.Provider>
      )}
    </Container>
  );
};

const Container = styled(Box)({
  // border: '1px solid red',
  position: 'relative',
});
const FlexContainer = styled(Box)({
  // border: '1px solid red',
  margin: '0 auto',
  maxWidth: '1280px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexWrap: 'nowrap',
});

export default Home;
