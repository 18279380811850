import React, { useEffect, useState } from 'react';
import { Box, IconButton, useTheme, useMediaQuery } from '@mui/material';
import { styled } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import { homeApi } from '../../api/home';
import Viewer from '../../lib/Editor/Viewer';

const BannerBox = styled(Box)(({ theme }) => ({
  position: 'relative',
  zIndex: 101,
  backgroundColor: '#212529',
  height: '46px',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  paddingRight: '10px',
  [theme.breakpoints.up('desktop')]: {
    height: '55px',
  },
}));

const Banner = () => {
  const [bannerOpen, setBannerOpen] = useState(true);
  const [bannerData, setBannerData] = useState(null);
  const theme = useTheme();
  const isDeskTop = useMediaQuery(theme.breakpoints.up('desktop'));

  const closeBanner = () => {
    window.dataLayer.push({
      event: 'ga.custom.tag',
      GACategory: '상단고정바',
      GALabel: '닫기',
      GAAction: '클릭',
    });
  };
  const onClickBanner = () => {
    window.dataLayer.push({
      event: 'ga.custom.tag',
      GACategory: '상단고정바',
      GALabel: '프로젝트 만들기',
      GAAction: '클릭',
    });
  };

  useEffect(() => {
    const loadData = async () => {
      try {
        const apiParams = {
          banner_type: 'top',
          platform: isDeskTop ? 'PC' : 'MOBILE',
        };
        const { data } = await homeApi.bannerList(apiParams);
        if (data.data && data.data.length > 0) {
          setBannerData(data.data[0]);
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err.message);
      }
    };
    loadData();
  }, [isDeskTop]);

  if (bannerOpen && bannerData) {
    return (
      <BannerBox>
        <Box
          onClick={() => {
            if (bannerData.text_link) {
              if (bannerData.popup_yn === 'Y') {
                window.open(bannerData.text_link, '_blank');
              } else {
                window.location.href = bannerData.text_link;
              }
            }
            onClickBanner();
          }}
          sx={{
            display: 'flex',
            flexGrow: 1,
            justifyContent: 'center',
            cursor: bannerData.text_link ? 'pointer' : 'auto',
            ml: '40px',
            width: '100%',
            overflow: 'hidden',
          }}
        >
          <Viewer model={bannerData?.text} />
        </Box>
        <IconButton
          onClick={() => {
            setBannerOpen(false);
            closeBanner();
          }}
        >
          <CloseIcon sx={{ color: 'white' }} />
        </IconButton>
      </BannerBox>
    );
  }
  return <Box />;
};

export default Banner;
