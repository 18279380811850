import React from 'react';
import { styled, Box, Link, List, ListItem } from '@mui/material';

const Info = styled(ListItem)(() => ({
  display: 'inline',
  color: '#44484b',
  fontStyle: 'normal',
  padding: '0',
  '&::after': {
    margin: '0 5px',
    color: '#dde2e6',
    content: '"|"',
  },
  '&:last-child': {
    '&::after': {
      content: '""',
    },
  },
}));

const ContactBox = styled(Box)(({ theme }) => ({
  display: 'block',
  [theme.breakpoints.up('desktop')]: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
}));
const CopyRightBox = styled(Box)(() => ({
  display: 'inline-block',
  marginTop: '4px',
  color: '#000',
  fontWeight: '900',
  fontStyle: 'normal',
}));
export const FooterInfo = () => {
  const CompanyName = '와디즈㈜';
  const Representative = '신혜성';
  const CompanyNo = '258-87-01370';
  const ReportNo = '2021-성남분당C-1153';
  const Address = '경기 성남시 분당구 판교로 242 PDC A동 402호';
  const Email = 'info@wadiz.kr';
  const Tel = '1661-9056';
  const Copyright = '© wadiz Co., Ltd.';
  return (
    <address style={{ whiteSpace: 'normal' }}>
      <List style={{ padding: 0 }}>
        <Info>{CompanyName}</Info>
        <Info>대표이사 {Representative}</Info>
        <Info>사업자등록번호 {CompanyNo}</Info>
        {ReportNo !== undefined && <Info>통신판매업신고번호 {ReportNo}</Info>}
        <Info>{Address}</Info>
      </List>
      <ContactBox>
        <List style={{ marginTop: '4px', padding: 0 }}>
          <Info>
            <Link
              href="https://helpcenter.wadiz.kr/hc/ko/requests/new"
              target="_blank"
              rel="noreferrer"
              color="inherit"
              underline="none"
            >
              이메일 상담 {Email}
            </Link>
          </Info>
          <Info>
            유선 상담{' '}
            <Link href={`tel:${Tel}`} color="inherit" underline="none">
              {Tel}
            </Link>
          </Info>
        </List>
        <CopyRightBox>{Copyright}</CopyRightBox>
      </ContactBox>
    </address>
  );
};
