import { React, useEffect } from 'react';
import { styled } from '@mui/system';
import { Box, Button, Icon, Link, Typography } from '@mui/material';
import { ReactComponent as AndroidIcon } from '../../assets/images/android.svg';
import { ReactComponent as IosIcon } from '../../assets/images/ios.svg';
import QuestionImage from './01-icon-1-system-help.png';
import { ReactComponent as ChevronRightIcon } from '../../assets/images/ic_next_m.svg';
import FooterMenuArea from './FooterMenu';
import { FooterInfo } from './FooterInfo';

const Container = styled(Box)(({ theme }) => ({
  whiteSpace: 'nowrap',
  [theme.breakpoints.up('desktop')]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));
const WadizContainer = styled(Box)(({ theme }) => ({
  paddingBottom: '58px',
  [theme.breakpoints.up('desktop')]: {
    padding: '0 80px',
  },
}));
const Inner = styled(Box)(({ theme }) => ({
  display: 'flex',
  paddingBottom: '58px',
  flexDirection: 'column',
  [theme.breakpoints.up('desktop')]: {
    flexDirection: 'row',
    gap: '80px',
    margin: '0 auto',
    maxWidth: '1280px',
  },
}));
const LeftSection = styled(Box)(({ theme }) => ({
  borderBottom: '1px solid #f2f4f6',
  padding: '16px',
  [theme.breakpoints.up('desktop')]: {
    padding: '40px 0 80px',
    width: '255px',
  },
}));
const QuestionIcon = styled(Icon)(() => ({
  marginRight: '8px',
  height: '20px',
  maxWidth: '20px',
  minWidth: '20px',
  backgroundImage: `url('${QuestionImage}')`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center center',
  backgroundSize: '20px 20px',
}));
const Title = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '12px',
  color: '#212529',
  lineHeight: '28px',
  fontSize: '16px',
  fontWeight: 700,
  [theme.breakpoints.up('desktop')]: {
    marginBottom: '4px',
    lineHeight: '28px',
    fontSize: '18px',
    fontWeight: 700,
  },
}));
const ButtonBlock = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
}));
const IconButton = styled(Button)(({ theme }) => ({
  lineHeight: '20px',
  fontSize: '14px',
  fontWeight: '500',
  display: 'inline-flex',
  alignItems: 'left',
  margin: '8px 0',
  cursor: 'pointer',
  padding: '0',
  width: '50%',
  textAlign: 'left',
  color: '#495057',
  justifyContent: 'flex-start',
  [theme.breakpoints.up('desktop')]: {
    lineHeight: '28px',
    fontSize: '16px',
    fontWeight: '500',
    margin: '4px 0',
    width: '100%',
  },
}));

const IconLink = styled(Link)(({ theme }) => ({
  lineHeight: '20px',
  fontSize: '14px',
  fontWeight: '500',
  display: 'inline-flex',
  alignItems: 'center',
  margin: '8px 0',
  cursor: 'pointer',
  padding: '0',
  width: '50%',
  textAlign: 'left',
  color: '#495057',
  [theme.breakpoints.up('desktop')]: {
    lineHeight: '28px',
    fontSize: '16px',
    fontWeight: '500',
    margin: '4px 0',
    width: '100%',
  },
}));
const EnableTime = styled(Box)(({ theme }) => ({
  borderTop: '1px solid #f2f4f6',
  paddingTop: '16px',
  whiteSpace: 'nowrap',
  color: '#868e96',
  fontSize: '12px',
  fontWeight: 400,
  [theme.breakpoints.up('desktop')]: {
    lineHeight: '20px',
    fontSize: '14px',
    fontWeight: 400,
    marginTop: '12px',
    paddingTop: '20px',
  },
}));
const RightSection = styled(Box)(({ theme }) => ({
  padding: '24px 16px 0',
  lineHeight: '18px',
  fontSize: '12px',
  fontWeight: '400',
  [theme.breakpoints.up('desktop')]: {
    padding: '40px 0 80px',
    lineHeight: '20px',
    fontSize: '14px',
    fontWeight: '400',
  },
}));

const InfoNotice = styled(Box)(({ theme }) => ({
  color: '#868e96',
  padding: 0,
  [theme.breakpoints.up('desktop')]: {
    lineHeight: '20px',
    fontSize: '14px',
    fontWeight: '400',
  },
}));

const AppLinkContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-evenly',
  marginTop: '42px',
  [theme.breakpoints.up('desktop')]: {
    marginTop: '0px',
    marginLeft: '0px',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '0px',
    width: '240px',
    height: '128px',
  },
}));

const AppLinkTitle = styled(Typography)(({ theme }) => ({
  marginLeft: '8px',
  color: '#212529',
  fontSize: '12px',
  fontWeight: 400,
  [theme.breakpoints.up('desktop')]: {},
}));

const SCRIPT_ID = 'ze-snippet';
const SCRIPT_SRC =
  'https://static.zdassets.com/ekr/snippet.js?key=92d1cefe-1d59-4a98-b813-13d400d168d2';
const loadZendesk = () =>
  new Promise((resolve, reject) => {
    if (document.getElementById(SCRIPT_ID)) {
      resolve();
      return;
    }

    const script = document.createElement('script');
    script.src = SCRIPT_SRC;
    script.setAttribute('id', SCRIPT_ID);
    document.body.append(script);
    script.onload = () => setTimeout(resolve, 2000);
    script.onerror = () => reject(new Error('Failed to load zendesk script.'));
  });

export const WadizFooter = ({ sideOn }) => {
  useEffect(() => {
    loadZendesk();
  }, []);
  const openHelpMessage = () => {
    const openZendesk = async () => {
      await loadZendesk();
      window.zE('messenger', 'open');
    };
    openZendesk();
  };
  return (
    <Container>
      {!sideOn && <FooterMenuArea />}
      <WadizContainer>
        <Inner>
          <LeftSection>
            <Title>
              <QuestionIcon />
              와디즈 고객센터
            </Title>
            <ButtonBlock>
              <IconButton onClick={() => openHelpMessage()}>
                채팅 상담하기
                <ChevronRightIcon />
              </IconButton>
              <IconLink
                href="https://helpcenter.wadiz.kr/hc/ko/requests/new"
                target="_blank"
                rel="noreferrer"
                color="inherit"
                underline="none"
              >
                문의 등록하기
                <ChevronRightIcon />
              </IconLink>
            </ButtonBlock>

            <EnableTime>
              <dt style={{ fontWeight: 'bolder' }}>상담 가능 시간</dt>
              <dd style={{ margin: 0 }}>
                평일 오전 9시 ~ 오후 6시 (주말, 공휴일 제외)
              </dd>
            </EnableTime>
          </LeftSection>
          <RightSection>
            <FooterInfo />
            <Box style={{ marginTop: '16px', whiteSpace: 'normal' }}>
              <InfoNotice>
                일부 상품의 경우 와디즈는 통신판매중개자이며 통신판매 당사자가
                아닙니다.
              </InfoNotice>
              <InfoNotice>
                해당되는 상품의 경우 상품, 상품정보, 거래에 관한 의무와 책임은
                판매자에게 있으므로, 각 상품 페이지에서 구체적인 내용을
                확인하시기 바랍니다.
              </InfoNotice>
            </Box>
            <AppLinkContainer>
              <Box sx={{ width: '120px', textAlign: 'center' }}>
                <Button
                  onClick={() => {
                    window.open(
                      'https://play.google.com/store/apps/details?id=com.markmount.wadiz&pli=1',
                      '_blank',
                    );
                  }}
                >
                  <AndroidIcon />
                  <AppLinkTitle>Android앱</AppLinkTitle>
                </Button>
              </Box>
              <Box sx={{ width: '120px', textAlign: 'center' }}>
                <Button
                  onClick={() => {
                    window.open(
                      'https://apps.apple.com/kr/app/wadijeu/id1107828621',
                      '_blank',
                    );
                  }}
                  variant="text"
                >
                  <IosIcon />
                  <AppLinkTitle>IOS앱</AppLinkTitle>
                </Button>
              </Box>
            </AppLinkContainer>
          </RightSection>
        </Inner>
      </WadizContainer>
    </Container>
  );
};
