import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { styled, Box } from '@mui/material';
import Typewriter from 'typewriter-effect';
import { ReactComponent as SearchIcon } from '../../../assets/images/m-search-w.svg';

const SearchInput = ({ popularWord }) => {
  const navigate = useNavigate();
  const [isFocused, setIsFocused] = useState(false);
  const [searchInput, setSearchInput] = useState(null);
  const savedSearchInput = useRef(null);

  // console.log('input :', searchInput);

  // 검색어 입력시 ref에 저장 -> 아이콘 클릭에 활용
  useEffect(() => {
    if (searchInput !== null) {
      savedSearchInput.current = searchInput;
    }
  }, [searchInput]);

  // 검색어 엔터시 이벤트
  const onSearchEnter = useCallback(() => {
    if (searchInput === null || searchInput.length < 1 || searchInput === '%') {
      // eslint-disable-next-line no-useless-return
      return;
    }
    if (searchInput.length >= 1) {
      navigate(`/search/${encodeURIComponent(searchInput)}`);
    }
  }, [searchInput]);

  // 검색 아이콘 클릭시 이벤트
  const onSearchIconClick = useCallback(() => {
    if (
      savedSearchInput.current !== null &&
      savedSearchInput.current.length >= 1
    ) {
      navigate(`/search/${encodeURIComponent(savedSearchInput.current)}`);
    }
  }, [savedSearchInput]);

  return (
    <Container sx={{ mb: '20px' }}>
      <InputField
        value={searchInput || ''}
        onFocus={() => setIsFocused(true)}
        onBlur={() => {
          setIsFocused(false);
          setSearchInput(null);
        }}
        onChange={e => {
          setSearchInput(e.target.value);
        }}
        onKeyPress={e => {
          if (e.key === 'Enter') {
            onSearchEnter();
          }
        }}
        sx={{ pr: '50px' }}
      />
      <SearchIconBox onClick={onSearchIconClick}>
        <SearchIcon width={32} height={32} />
      </SearchIconBox>
      <TextBox sx={{ display: isFocused ? 'none' : 'block' }}>
        <Typewriter
          options={{
            strings: [...popularWord.map(word => word.word)],
            autoStart: true,
            loop: true,
          }}
        />
      </TextBox>
    </Container>
  );
};

SearchInput.defaultProps = {
  popularWord: null,
};

SearchInput.propTypes = {
  popularWord: PropTypes.arrayOf(
    PropTypes.shape({
      word: PropTypes.string,
    }),
  ),
};

const Container = styled(Box)({
  // border: '1px solid red',
  position: 'relative',
  width: '610px',
});
const TextBox = styled(Box)({
  position: 'absolute',
  left: 0,
  top: '50%',
  transform: 'translate(0, -50%)',
  color: '#fff',
  fontSize: '18px',
  fontWeight: 500,
  paddingLeft: '20px',
});

const InputField = styled('input')({
  width: '610px',
  height: '60px',
  border: '2px solid #fff',
  borderRadius: '10px',
  backgroundColor: 'transparent',
  paddingLeft: '20px',
  color: '#fff',
  fontSize: '18px',
  fontWeight: 500,
  outline: 'none',
  // '&:hover': {
  //   borderColor: '#00B4FF',
  // },
  '&:focus': {
    border: '3px solid #fff',
  },
});
const SearchIconBox = styled(Box)({
  position: 'absolute',
  right: 15,
  top: '50%',
  transform: 'translate(0, -50%)',
  zIndex: 2,
  cursor: 'pointer',
});

export default SearchInput;
