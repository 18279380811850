import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { styled, Box } from '@mui/material';

const RollingBannerNaviBar = ({ currentStep, slideLength }) => {
  // 네비 바의 움직여야 할 퍼센티지 계산
  const movingPoint = useCallback(
    currentIndex => {
      let movingRatio;
      if (currentIndex === 1 || currentIndex === slideLength + 1) {
        movingRatio = 0;
      } else if (currentIndex === 0) {
        movingRatio = `${(100 / slideLength) * (slideLength - 1)}`;
      } else {
        movingRatio = `${(100 / slideLength) * (currentIndex - 1)}`;
      }
      return movingRatio;
    },
    [currentStep, slideLength],
  );

  return (
    <Container
    // sx={{ width: isDeskTop ? '580px' : '100%' }}
    >
      <NaviBarContainer>
        {/* 바를 직접 클릭하여 이동할 투명바 설정 */}
        <NaviBackground />
        <MovingBar
          sx={{
            width: `${100 / slideLength}%`, // 자동너비
            left: `${movingPoint(currentStep)}%`, // 이동
          }}
        />
      </NaviBarContainer>
    </Container>
  );
};

RollingBannerNaviBar.defaultProps = {
  slideLength: null,
};

RollingBannerNaviBar.propTypes = {
  currentStep: PropTypes.number.isRequired,
  slideLength: PropTypes.number,
};

const Container = styled(Box)({
  // border: '1px solid blue',
  position: 'relative',
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
});
const NaviBarContainer = styled('div')({
  width: '100%', // 바의 전체 너비 결정
  height: '3px', // 바의 전체 높이 결정
  position: 'relative', // 포지션 부모 설정
  backgroundColor: 'rgba(255, 255, 255, .1)',
  border: 0,
  boxSizing: 'border-box',
});
const MovingBar = styled('div')({
  position: 'absolute', // 중요!
  top: '0', // 중요
  transition: '.2s ease-in-out',
  height: '100%', // width는 인라인에서 설정
  backgroundColor: '#fff', // 색깔
});
const NaviBackground = styled('div')({
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  border: 0,
  boxSizing: 'border-box',
  backgroundColor: 'rgba(255, 255, 255, .3)',
});

export default React.memo(RollingBannerNaviBar);
