import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { styled, Box, IconButton } from '@mui/material';
import { ReactComponent as LeftIcon } from '../../assets/images/ic-leftArrow.svg';
import { ReactComponent as RightIcon } from '../../assets/images/ic-rightArrow.svg';

const ConditionalSearchCalendar = ({
  condition,
  period,
  setPeriod,
  setAnchorEl,
}) => {
  const [year, setYear] = useState(moment());
  const [month, setMonth] = useState('01');

  // console.log('condition :', condition);

  // 날짜 string 만들기
  useEffect(() => {
    if (condition === 'start_date') {
      setPeriod({
        ...period,
        search_start_date: `${moment(year).format('YYYY')}-${month}-01`,
      });
    }
    if (condition === 'end_date') {
      const chosenMonth = `${moment(year).format('YYYY')}-${month}-01`;
      setPeriod({
        ...period,
        search_end_date: `${moment(year).format('YYYY')}-${month}-${moment(
          chosenMonth,
        )
          .endOf('month')
          .format('DD')}`,
      });
    }
  }, [condition, year, month]);

  return (
    <Container>
      <YearBox>
        <IconButton
          onClick={() =>
            setYear(current => moment(current).subtract(1, 'year'))
          }
        >
          <LeftIcon />
        </IconButton>
        <YearNumber>{moment(year).format('YYYY')}</YearNumber>
        <IconButton
          onClick={() => setYear(current => moment(current).add(1, 'year'))}
        >
          <RightIcon />
        </IconButton>
      </YearBox>
      <MonthBox>
        <Month
          onClick={() => {
            setMonth('01');
            setAnchorEl(null);
          }}
          sx={{
            color: month === '01' ? '#fff' : '#495057',
            background: month === '01' ? '#00C4C4' : '#fff',
          }}
        >
          1월
        </Month>
        <Month
          onClick={() => {
            setMonth('02');
            setAnchorEl(null);
          }}
          sx={{
            color: month === '02' ? '#fff' : '#495057',
            background: month === '02' ? '#00C4C4' : '#fff',
          }}
        >
          2월
        </Month>
        <Month
          onClick={() => {
            setMonth('03');
            setAnchorEl(null);
          }}
          sx={{
            color: month === '03' ? '#fff' : '#495057',
            background: month === '03' ? '#00C4C4' : '#fff',
          }}
        >
          3월
        </Month>
        <Month
          onClick={() => {
            setMonth('04');
            setAnchorEl(null);
          }}
          sx={{
            color: month === '04' ? '#fff' : '#495057',
            background: month === '04' ? '#00C4C4' : '#fff',
          }}
        >
          4월
        </Month>
        <Month
          onClick={() => {
            setMonth('05');
            setAnchorEl(null);
          }}
          sx={{
            color: month === '05' ? '#fff' : '#495057',
            background: month === '05' ? '#00C4C4' : '#fff',
          }}
        >
          5월
        </Month>
        <Month
          onClick={() => {
            setMonth('06');
            setAnchorEl(null);
          }}
          sx={{
            color: month === '06' ? '#fff' : '#495057',
            background: month === '06' ? '#00C4C4' : '#fff',
          }}
        >
          6월
        </Month>
        <Month
          onClick={() => {
            setMonth('07');
            setAnchorEl(null);
          }}
          sx={{
            color: month === '07' ? '#fff' : '#495057',
            background: month === '07' ? '#00C4C4' : '#fff',
          }}
        >
          7월
        </Month>
        <Month
          onClick={() => {
            setMonth('08');
            setAnchorEl(null);
          }}
          sx={{
            color: month === '08' ? '#fff' : '#495057',
            background: month === '08' ? '#00C4C4' : '#fff',
          }}
        >
          8월
        </Month>
        <Month
          onClick={() => {
            setMonth('09');
            setAnchorEl(null);
          }}
          sx={{
            color: month === '09' ? '#fff' : '#495057',
            background: month === '09' ? '#00C4C4' : '#fff',
          }}
        >
          9월
        </Month>
        <Month
          onClick={() => {
            setMonth('10');
            setAnchorEl(null);
          }}
          sx={{
            color: month === '10' ? '#fff' : '#495057',
            background: month === '10' ? '#00C4C4' : '#fff',
          }}
        >
          10월
        </Month>
        <Month
          onClick={() => {
            setMonth('11');
            setAnchorEl(null);
          }}
          sx={{
            color: month === '11' ? '#fff' : '#495057',
            background: month === '11' ? '#00C4C4' : '#fff',
          }}
        >
          11월
        </Month>
        <Month
          onClick={() => {
            setMonth('12');
            setAnchorEl(null);
          }}
          sx={{
            color: month === '12' ? '#fff' : '#495057',
            background: month === '12' ? '#00C4C4' : '#fff',
          }}
        >
          12월
        </Month>
      </MonthBox>
    </Container>
  );
};

ConditionalSearchCalendar.defaultProps = {
  period: {
    search_start_date: null,
    search_end_date: null,
  },
};

ConditionalSearchCalendar.propTypes = {
  condition: PropTypes.string.isRequired,
  period: PropTypes.shape({
    search_start_date: PropTypes.string,
    search_end_date: PropTypes.string,
  }),
  setPeriod: PropTypes.func.isRequired,
  setAnchorEl: PropTypes.func.isRequired,
};

const Container = styled(Box)({
  width: '268px',
  height: '188px',
  padding: '16px',
});
const YearBox = styled(Box)({
  // border: '1px solid red',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderBottom: '1px solid #e6e6e6',
  padding: '0 0 3px 0',
  marginBottom: '10px',
});
const MonthBox = styled('ul')({
  padding: 0,
  margin: 0,
  textAlign: 'center',
});
const Month = styled('li')({
  // border: '1px solid red',
  border: 0,
  borderRadius: '3px',
  listStyle: 'none',
  display: 'inline-flex',
  width: '56px',
  height: '36px',
  justifyContent: 'center',
  alignItems: 'center',
  color: '#495057',
  fontSize: '14px',
  fontWeight: 500,
  cursor: 'pointer',
});
const YearNumber = styled(Box)({
  color: '#212529',
  fontSize: '16px',
  fontWeight: 700,
  width: '80px',
  textAlign: 'center',
});

export default ConditionalSearchCalendar;
