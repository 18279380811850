import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  styled,
  Box,
  IconButton,
  Button,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import Modal from '@mui/material/Modal';
import Popover from '@mui/material/Popover';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import moment from 'moment';
import Tooltip from '@mui/material/Tooltip';
import { searchModalStyle } from '../../lib/styles';
import { ReactComponent as ClearIcon } from '../../assets/images/ic-clear.svg';
import ConditionalSearchCalendar from './ConditionalSearchCalendar';

const ConditionalSearchModal = ({
  modal,
  setModal,
  searchCondition,
  setSearchCondition,
  periodDefalut,
  setPeriodDefault,
  period,
  setPeriod,
}) => {
  const [sort, setSort] = useState('new');
  const [calendarMode, setCalendarMode] = useState(null);
  const [isSelfPeriod, setIsSelfPeriod] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchArea, setSearchArea] = useState(
    searchCondition.search_area || 'whole',
  );
  const [alertTooltip, setAlertTooltip] = useState(false);
  const theme = useTheme();
  const isDeskTop = useMediaQuery(theme.breakpoints.up('desktop'));

  // console.log('sort :', sort);

  // '기간' 입력시 형식에 맞춰 스테이트에 저장
  const onPeriodSetting = useCallback(
    event => {
      if (event.target.value === 'whole') {
        setPeriod({
          search_start_date: '',
          search_end_date: '',
        });
        setPeriodDefault('whole');
      } else if (event.target.value === 'oneMonth') {
        setPeriod({
          search_start_date: moment().subtract(1, 'month').format('YYYY-MM-DD'),
          search_end_date: moment().format('YYYY-MM-DD'),
        });
        setPeriodDefault('oneMonth');
      } else if (event.target.value === 'sixMonth') {
        setPeriod({
          search_start_date: moment().subtract(6, 'month').format('YYYY-MM-DD'),
          search_end_date: moment().format('YYYY-MM-DD'),
        });
        setPeriodDefault('sixMonth');
      } else if (event.target.value === 'oneYear') {
        setPeriod({
          search_start_date: moment().subtract(1, 'year').format('YYYY-MM-DD'),
          search_end_date: moment().format('YYYY-MM-DD'),
        });
        setPeriodDefault('oneYear');
      } else if (event.target.value === 'threeYear') {
        setPeriod({
          search_start_date: moment().subtract(3, 'year').format('YYYY-MM-DD'),
          search_end_date: moment().format('YYYY-MM-DD'),
        });
        setPeriodDefault('threeYear');
      }
      if (event.target.value === 'selfInput') {
        setPeriod({
          search_start_date: undefined,
          search_end_date: undefined,
        });
        setIsSelfPeriod(true);
        setPeriodDefault('selfInput');
      } else {
        setIsSelfPeriod(false);
      }
    },
    [modal, calendarMode],
  );

  // 시작일이 종료일보다 클 경우 이벤트
  useEffect(() => {
    if (period.search_start_date !== null && period.search_end_date !== null) {
      if (period.search_start_date > period.search_end_date) {
        setAlertTooltip(true);
      } else {
        setAlertTooltip(false);
      }
    }
  }, [period]);

  // '검색'버튼 클릭시 이벤트
  const onSubmit = () => {
    setSearchCondition({
      sort,
      search_start_date: period.search_start_date,
      search_end_date: period.search_end_date,
      search_area: searchArea === 'whole' ? '' : searchArea,
    });
    setModal(false);
    if (isSelfPeriod === false) {
      setPeriod({
        search_start_date: undefined,
        search_end_date: undefined,
      });
    }
    if (periodDefalut === 'selfInput') {
      setIsSelfPeriod(true);
    } else {
      setIsSelfPeriod(false);
    }

    // setSort('new');
    // setSearchArea('whole');
  };

  return (
    <Modal
      open={modal}
      onClose={() => {
        setModal(false);
        setPeriod({
          search_start_date: '',
          search_end_date: '',
        });
        // setSearchArea('whole');
      }}
    >
      <Box
        sx={{
          ...searchModalStyle,
          width: isDeskTop ? '560px' : '360px',
          height: isDeskTop ? '500px' : '508px',
        }}
      >
        <FlexBox
          sx={{
            justifyContent: 'space-between',
            mb: isDeskTop ? '32px' : '15px',
          }}
        >
          <PageTitle>상세검색</PageTitle>
          <IconButton onClick={() => setModal(false)}>
            <ClearIcon width={16} height={16} />
          </IconButton>
        </FlexBox>
        <Box sx={{ height: isDeskTop ? 'auto' : '371px' }}>
          {/* 정렬 */}
          <Inner sx={{ mb: isDeskTop ? '15px' : '10px' }}>
            <Title>정렬</Title>
            <FormControl sx={{ mb: '15px' }}>
              <RadioGroup
                row
                defaultValue={
                  searchCondition.sort === null ? 'new' : searchCondition.sort
                }
                onChange={e => setSort(e.target.value)}
              >
                <FormControlLabel
                  value="new"
                  control={
                    <Radio
                      size="small"
                      sx={{
                        '&.Mui-checked': {
                          color: '#00C4C4',
                        },
                      }}
                    />
                  }
                  label="최신순"
                  sx={{
                    color: '#212529',
                    fontSize: '16px',
                    fontWeight: 400,
                    mr: '40px',
                  }}
                />
                <FormControlLabel
                  value="view_count"
                  control={
                    <Radio
                      size="small"
                      sx={{
                        '&.Mui-checked': {
                          color: '#00C4C4',
                        },
                      }}
                    />
                  }
                  label="조회순"
                  sx={{
                    color: '#212529',
                    fontSize: '16px',
                    fontWeight: 400,
                    mr: '40px',
                  }}
                />
              </RadioGroup>
            </FormControl>
          </Inner>
          {/* 기간 */}
          <Inner sx={{ mb: isDeskTop ? '15px' : '10px' }}>
            <Title>기간</Title>
            <FormControl sx={{ mb: '15px' }}>
              <RadioGroup row value={periodDefalut} onChange={onPeriodSetting}>
                <FormControlLabel
                  value="whole"
                  control={
                    <Radio
                      size="small"
                      sx={{
                        '&.Mui-checked': {
                          color: '#00C4C4',
                        },
                      }}
                    />
                  }
                  label="전체"
                  sx={{
                    color: '#212529',
                    fontSize: '16px',
                    fontWeight: 400,
                    mr: isDeskTop ? '40px' : '56px',
                  }}
                />
                <FormControlLabel
                  value="oneMonth"
                  control={
                    <Radio
                      size="small"
                      sx={{
                        '&.Mui-checked': {
                          color: '#00C4C4',
                        },
                      }}
                    />
                  }
                  label="1개월"
                  sx={{
                    color: '#212529',
                    fontSize: '16px',
                    fontWeight: 400,
                    mr: isDeskTop ? '40px' : '49px',
                  }}
                />
                <FormControlLabel
                  value="sixMonth"
                  control={
                    <Radio
                      size="small"
                      sx={{
                        '&.Mui-checked': {
                          color: '#00C4C4',
                        },
                      }}
                    />
                  }
                  label="6개월"
                  sx={{
                    color: '#212529',
                    fontSize: '16px',
                    fontWeight: 400,
                    mr: isDeskTop ? '40px' : 0,
                  }}
                />
                <FormControlLabel
                  value="oneYear"
                  control={
                    <Radio
                      size="small"
                      sx={{
                        '&.Mui-checked': {
                          color: '#00C4C4',
                        },
                      }}
                    />
                  }
                  label="1년"
                  sx={{
                    color: '#212529',
                    fontSize: '16px',
                    fontWeight: 400,
                    mr: isDeskTop ? '40px' : '62px',
                  }}
                />
                <FormControlLabel
                  value="threeYear"
                  control={
                    <Radio
                      size="small"
                      sx={{
                        '&.Mui-checked': {
                          color: '#00C4C4',
                        },
                      }}
                    />
                  }
                  label="3년"
                  sx={{
                    color: '#212529',
                    fontSize: '16px',
                    fontWeight: 400,
                    mr: isDeskTop ? '40px' : '63px',
                  }}
                />
                {isDeskTop ? <br /> : null}
                <FormControlLabel
                  value="selfInput"
                  control={
                    <Radio
                      size="small"
                      sx={{
                        '&.Mui-checked': {
                          color: '#00C4C4',
                        },
                      }}
                    />
                  }
                  label="직접입력"
                  sx={{
                    mr: isDeskTop ? '20px' : '-40px',
                    color: '#212529',
                    fontSize: '16px',
                    fontWeight: 400,
                  }}
                />
                {isSelfPeriod && (
                  <>
                    <Calendar
                      onClick={e => {
                        setAnchorEl(e.currentTarget);
                        setCalendarMode('start_date');
                      }}
                      sx={{ width: isDeskTop ? '81px' : '141px' }}
                    >
                      {period.search_start_date === undefined ? (
                        <>월입력</>
                      ) : (
                        moment(period?.search_start_date).format('YYYY.MM')
                      )}
                    </Calendar>
                    <Tooltip
                      open={alertTooltip}
                      onClose={() => setAlertTooltip(false)}
                      onOpen={() => setAlertTooltip(true)}
                      title="검색조건에 맞지 않습니다"
                      placement="top"
                      arrow
                    >
                      <Box
                        sx={{
                          display: 'inline-flex',
                          alignItems: 'center',
                          color: '#ADB5BD',
                          mx: '10px',
                        }}
                      >
                        ~
                      </Box>
                    </Tooltip>
                    <Calendar
                      onClick={e => {
                        setAnchorEl(e.currentTarget);
                        setCalendarMode('end_date');
                      }}
                      sx={{ width: isDeskTop ? '81px' : '141px' }}
                    >
                      {period.search_end_date === undefined ? (
                        <>월입력</>
                      ) : (
                        moment(period?.search_end_date).format('YYYY.MM')
                      )}
                    </Calendar>
                  </>
                )}
                <Popover
                  open={Boolean(anchorEl)}
                  anchorEl={anchorEl}
                  onClose={() => setAnchorEl(null)}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                >
                  <ConditionalSearchCalendar
                    condition={
                      calendarMode === 'start_date' ? 'start_date' : 'end_date'
                    }
                    period={period}
                    setPeriod={setPeriod}
                    setAnchorEl={setAnchorEl}
                  />
                </Popover>
              </RadioGroup>
            </FormControl>
          </Inner>
          {/* 영역 */}
          <Inner sx={{ mb: '30px', border: 0 }}>
            <Title>영역</Title>
            <FormControl sx={{ mb: '5px' }}>
              <RadioGroup
                row
                value={searchArea}
                defaultValue="whole"
                onChange={e => setSearchArea(e.target.value)}
              >
                <FormControlLabel
                  value="whole"
                  control={
                    <Radio
                      size="small"
                      sx={{
                        '&.Mui-checked': {
                          color: '#00C4C4',
                        },
                      }}
                    />
                  }
                  label="전체"
                  sx={{
                    color: '#212529',
                    fontSize: '16px',
                    fontWeight: 400,
                    mr: '40px',
                  }}
                />
                <FormControlLabel
                  value="hashtag"
                  control={
                    <Radio
                      size="small"
                      sx={{
                        '&.Mui-checked': {
                          color: '#00C4C4',
                        },
                      }}
                    />
                  }
                  label="해시태그"
                  sx={{
                    color: '#212529',
                    fontSize: '16px',
                    fontWeight: 400,
                    mr: '40px',
                  }}
                />
                <FormControlLabel
                  value="title"
                  control={
                    <Radio
                      size="small"
                      sx={{
                        '&.Mui-checked': {
                          color: '#00C4C4',
                        },
                      }}
                    />
                  }
                  label="제목"
                  sx={{
                    color: '#212529',
                    fontSize: '16px',
                    fontWeight: 400,
                    mr: '40px',
                  }}
                />
                <FormControlLabel
                  value="summary"
                  control={
                    <Radio
                      size="small"
                      sx={{
                        '&.Mui-checked': {
                          color: '#00C4C4',
                        },
                      }}
                    />
                  }
                  label="요약"
                  sx={{
                    color: '#212529',
                    fontSize: '16px',
                    fontWeight: 400,
                    // mr: '40px',
                  }}
                />
              </RadioGroup>
            </FormControl>
          </Inner>
        </Box>
        {/* 버튼 */}
        <SubmitBtn
          onClick={onSubmit}
          sx={{
            backgroundColor:
              new Date(period?.search_start_date).getTime() >
              new Date(period?.search_end_date).getTime()
                ? // || period.search_start_date === undefined
                  // || period.search_end_date === undefined
                  'lightgray'
                : 'black',
          }}
          disabled={
            new Date(period?.search_start_date).getTime() >
            new Date(period?.search_end_date).getTime()
            // || period.search_start_date === undefined
            // || period.search_end_date === undefined
          }
        >
          검색
        </SubmitBtn>
      </Box>
    </Modal>
  );
};

ConditionalSearchModal.defaultProps = {
  searchCondition: {},
  period: {
    search_start_date: null,
    search_end_date: null,
  },
};

ConditionalSearchModal.propTypes = {
  modal: PropTypes.bool.isRequired,
  setModal: PropTypes.func.isRequired,
  searchCondition: PropTypes.shape({
    search_area: PropTypes.string,
    search_end_date: PropTypes.string,
    search_start_date: PropTypes.string,
    sort: PropTypes.string,
  }),
  setSearchCondition: PropTypes.func.isRequired,
  periodDefalut: PropTypes.string.isRequired,
  setPeriodDefault: PropTypes.func.isRequired,
  period: PropTypes.shape({
    search_start_date: PropTypes.string,
    search_end_date: PropTypes.string,
  }),
  setPeriod: PropTypes.func.isRequired,
};

const FlexBox = styled(Box)({
  // border: '1px solid red',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});
const Inner = styled(Box)({
  borderBottom: '1px solid #e6e6e6',
});
const PageTitle = styled(Box)({
  color: '#212529',
  fontSize: '18px',
  fontWeight: 700,
});
const Title = styled(Box)({
  color: '#212529',
  fontSize: '16px',
  fontWeight: 700,
  lineHeight: '28px',
  // marginBottom: '8px',
});
const Calendar = styled(Box)({
  height: '40px',
  border: '1px solid #E6EAED',
  borderRadius: '3px',
  display: 'flex',
  // justifyContent: 'center',
  justifyContent: 'flex-start',
  alignItems: 'center',
  color: '#ADB5BD',
  fontSize: '14px',
  fontWeight: 400,
  cursor: 'pointer',
});
const SubmitBtn = styled(Button)({
  width: '100%',
  height: '48px',
  backgroundColor: '#000',
  color: '#fff',
  '&:hover': { backgroundColor: '#000' },
});

export default ConditionalSearchModal;
