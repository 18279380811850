import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { HelmetProvider } from 'react-helmet-async';
import { CssBaseline } from '@mui/material';
import { RecoilRoot } from 'recoil';
import { Toaster } from 'react-hot-toast';

import 'react-datepicker/dist/react-datepicker.css';
import Router from './Router';

const theme = createTheme({
  typography: {
    fontFamily: ['NotoSansKR'],
    button: {
      textTransform: 'none',
    },
  },
  palette: {
    primary: { main: '#00B2B2' },
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableTouchRipple: true,
      },
    },
  },
  breakpoints: {
    values: {
      desktop: 1300, // 와디즈 기준
    },
  },
});

const App = () => {
  try {
    window.document.domain = process.env.REACT_APP_DOMAIN;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
  }
  return (
    <HelmetProvider>
      <ThemeProvider theme={theme}>
        <Toaster />
        <CssBaseline />
        <RecoilRoot>
          <Router />
        </RecoilRoot>
      </ThemeProvider>
    </HelmetProvider>
  );
};

export default App;
