import React, { useRef, useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  styled,
  Box,
  IconButton,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { homeApi } from '../../api/home';
import { ReactComponent as SearchIcon } from '../../assets/images/m-search.svg';
import { ReactComponent as ClearIcon } from '../../assets/images/ic-clear.svg';
import { ReactComponent as CancelIcon } from '../../assets/images/ic_cancel_black.svg';
import { ReactComponent as DeleteAllIcon } from '../../assets/images/ic-deleteAll.svg';
import PopularWordforSearchDrawer from '../common/PopularWordforSearchDrawer';

const SearchDrawer = ({ searchOpen, setSearchOpen }) => {
  // const searchOpen = useContext(SearchStateContext);
  const [popularWord, setPopulaWord] = useState([]);
  const [recentSearchWords, setRecentSearchWords] = useState([]);
  const inputRef = useRef('');
  const theme = useTheme();
  const isDeskTop = useMediaQuery(theme.breakpoints.up('desktop'));
  const navigate = useNavigate();

  // console.log('searchOpen :', searchOpen);

  // 추천 검색어 api get
  useEffect(() => {
    (async () => {
      const apiParams = {
        platform: 'PC',
      };
      try {
        const { data, status } = await homeApi.getMainData(apiParams);
        if (status === 200) {
          setPopulaWord(data.data.popular_word);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    })();
  }, []);

  // 검색창 오픈시 자동 포커스
  useEffect(() => {
    if (searchOpen) {
      setTimeout(() => {
        inputRef.current.focus();
      }, 500);
    } else {
      inputRef.current.blur();
    }
  }, [searchOpen]);

  // 최근 검색어 get from 로컬스토리지, only first rendering
  useEffect(() => {
    const localWords = localStorage.getItem('popularWord');
    if (localWords !== null) {
      setRecentSearchWords([...JSON.parse(localWords)]);
    }
  }, []); // searchOpen

  // SearchDrawer가 열릴 때 마다 로컬스토리지에서 최신 리스트 호출
  useEffect(() => {
    if (searchOpen) {
      const localWords = localStorage.getItem('popularWord');
      if (localWords !== null) {
        setRecentSearchWords([...JSON.parse(localWords)]);
      }
    }
  }, [searchOpen]);

  // 최근 검색어 변경시, 로컬스토리지에 set
  useEffect(() => {
    // 배열변수를 로컬스토리지에 저장
    localStorage.setItem('popularWord', JSON.stringify(recentSearchWords));
  }, [recentSearchWords]);

  // 검색어 입력후 엔터시 이벤트
  const onSearchEnter = useCallback(() => {
    // 최근 검색어 스테이트에 저장 - 로컬스토리지용
    if (inputRef.current.value.length < 1 || inputRef.current.value === '%') {
      // eslint-disable-next-line no-useless-return
      return;
    }
    const validatedSearchWordArray = recentSearchWords.filter(
      word => word?.word !== inputRef.current.value,
    );
    validatedSearchWordArray.unshift({
      word: inputRef.current.value,
      date: Date.now(),
    });
    setRecentSearchWords(validatedSearchWordArray);
    navigate(`/search/${encodeURIComponent(inputRef.current.value)}`);
    setSearchOpen(false);
    inputRef.current.focus();
    inputRef.current.value = null;
  }, [inputRef, recentSearchWords]);

  // 최근 검색어 삭제 이벤트
  const onRemoveRecentWord = dateInfo => {
    setRecentSearchWords(current => {
      const copiedWords = current.slice();
      const newWordsArr = copiedWords.filter(word => word.date !== dateInfo);
      return newWordsArr;
    });
  };

  return (
    <TopSearchContainer onMouseLeave={() => setSearchOpen(false)}>
      <Inner sx={{ margin: '0 auto', padding: isDeskTop ? 0 : '0 15px' }}>
        <Box sx={{ position: 'relative', mb: '50px' }}>
          <InputField
            ref={inputRef}
            placeholder="검색어를 입력해 주세요"
            onKeyPress={e => {
              if (e.key === 'Enter') onSearchEnter();
            }}
            sx={{ paddingRight: isDeskTop ? '50px' : '80px' }}
          />
          <CancelIconBox
            sx={{ display: isDeskTop ? 'none' : 'block' }}
            onClick={() => {
              inputRef.current.value = null;
            }}
          >
            <CancelIcon width={17} height={17} />
          </CancelIconBox>
          <SearchIconBox onClick={onSearchEnter}>
            {isDeskTop ? (
              <SearchIcon width={26} height={26} />
            ) : (
              <SearchIcon width={19} height={19} />
            )}
          </SearchIconBox>
        </Box>
        <InnerBox
          sx={{
            // border: '1px solid red',
            display: 'flex',
            flexDirection: isDeskTop ? 'row' : 'column',
          }}
        >
          <PopularWordBox
            sx={{
              // border: '1px solid blue',
              order: 1,
              width: isDeskTop ? '400px' : '100%',
              marginBottom: isDeskTop ? 0 : '48px',
            }}
          >
            <Box sx={{ mb: '16px' }}>추천 검색어</Box>
            {popularWord.length < 1 && (
              <EmptyBox>추천 검색어가 없습니다.</EmptyBox>
            )}
            <PopularWordforSearchDrawer
              popularWord={popularWord}
              textColor="#212529"
              bgColor="#F2F4F6"
              setSearchOpen={setSearchOpen}
            />
          </PopularWordBox>
          <Box
            sx={{
              display: isDeskTop ? 'block' : 'none',
              order: 2,
              width: '1px',
              background: '#E6EAED',
            }}
          />
          <RecentWordBox
            sx={{
              // border: '1px solid blue',
              order: 3,
              pl: isDeskTop ? '60px' : '0',
              width: isDeskTop ? '400px' : '100%',
            }}
          >
            <FlexBox
              sx={{
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                mb: '8px',
              }}
            >
              <Box>최근 검색어</Box>
              <FlexBox sx={{ width: 'auto', mt: '-8px' }}>
                <Box sx={{ fontSize: '13px', fontWeight: 500 }}>전체삭제</Box>
                <IconButton
                  onClick={() => {
                    setRecentSearchWords([]);
                  }}
                  disabled={recentSearchWords.length < 1}
                >
                  <DeleteAllIcon />
                </IconButton>
              </FlexBox>
            </FlexBox>
            <Box>
              {recentSearchWords.length < 1 && (
                <EmptyBox>최근 검색기록이 없습니다.</EmptyBox>
              )}
              {recentSearchWords.map(word => {
                return (
                  <Box key={`${word.word}-${word.date}`}>
                    <RecentWordInner sx={{ mb: '8px' }}>
                      <RecentWord
                        onClick={() => {
                          navigate(`/search/${encodeURIComponent(word.word)}`);
                          setSearchOpen(false);
                        }}
                        sx={{ width: isDeskTop ? '200px' : '57vw' }}
                      >
                        <Box
                          sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
                        >
                          {word.word}
                        </Box>
                      </RecentWord>
                      <RecentDate>
                        {moment(word.date).format('YYYY.MM.DD')}
                      </RecentDate>
                      <FlexBox sx={{ width: 'auto' }}>
                        <IconButton
                          onClick={() => onRemoveRecentWord(word.date)}
                        >
                          <ClearIcon width={11} height={11} />
                        </IconButton>
                      </FlexBox>
                    </RecentWordInner>
                  </Box>
                );
              })}
            </Box>
          </RecentWordBox>
        </InnerBox>
      </Inner>
    </TopSearchContainer>
  );
};
SearchDrawer.defaultProps = {
  setSearchOpen: null,
};

SearchDrawer.propTypes = {
  searchOpen: PropTypes.bool.isRequired,
  setSearchOpen: PropTypes.func,
};

const TopSearchContainer = styled(Box)({
  // border: '1px solid powderblue',
});
const Inner = styled(Box)({
  // border: '1px solid red',
  maxWidth: '800px',
  minWidth: '312px',
  overflow: 'hidden',
});
const FlexBox = styled(Box)({
  // border: '1px solid red',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});
const InnerBox = styled(Box)({
  position: 'relative',
});
const InputField = styled('input')({
  appearance: 'none',
  width: '100%',
  height: '64px',
  border: 0,
  borderBottom: '4px solid black',
  borderRadius: 0,
  backgroundColor: 'transparent',
  padding: '18px 0',
  color: '#212529',
  fontSize: '18px',
  fontWeight: 500,
  outline: 'none',
  '&::placeholder': {
    color: '#ADB5BD',
  },
});
const CancelIconBox = styled(IconButton)({
  padding: 0,
  position: 'absolute',
  right: 42,
  top: '50%',
  transform: 'translate(0, -50%)',
  zIndex: 3,
});
const SearchIconBox = styled(IconButton)({
  padding: 0,
  position: 'absolute',
  right: 5,
  top: '50%',
  transform: 'translate(0, -50%)',
  zIndex: 3,
});
const PopularWordBox = styled(Box)({
  // border: '1px solid blue',
  width: '400px',
  fontSize: '14px',
  fontWeight: 700,
});
const RecentWordBox = styled(Box)({
  // border: '1px solid red',
  width: '400px',
  height: '200px',
  fontSize: '14px',
  fontWeight: 700,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
});
const RecentWordInner = styled(Box)({
  // border: '1px solid blue',
  // width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  whiteSpace: 'nowrap',
  // cursor: 'pointer',
});
const RecentWord = styled(Box)({
  // border: '1px solid red',
  whiteSpace: 'nowrap',
  color: '#212529',
  fontSize: '15px',
  fontWeight: 400,
  cursor: 'pointer',
});
const RecentDate = styled(Box)({
  // border: '1px solid red',
  color: '#ADB5BD',
  fontSize: '14px',
  fontWeight: 400,
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
});
const EmptyBox = styled(Box)({
  // border: '1px solid red',
  width: '100%',
  minHeight: '120px',
  color: '#ADB5BD',
  fontSize: '14px',
  fontWeight: 400,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export default SearchDrawer;
