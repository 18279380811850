export const getCalendarColor = calendar_type => {
  let bgColor = 'grey';
  let textColor = 'black';
  let matched = true;
  if (calendar_type === 'red') {
    bgColor = '#FFF1F1';
    textColor = '#F25555';
  } else if (calendar_type === 'orange') {
    bgColor = '#FFF6EA';
    textColor = '#EE9D3D';
  } else if (calendar_type === 'blue') {
    bgColor = '#EFF3FD';
    textColor = '#4167D9';
  } else if (calendar_type === 'green') {
    bgColor = '#E7F9F9';
    textColor = '#00B2B2';
  } else if (calendar_type === 'purple') {
    bgColor = '#F8F2FF';
    textColor = '#8D4AE1';
  } else {
    matched = false;
  }
  return { bgColor, textColor, matched };
};

export const getStringMomentDayOfWeek = moment_dayOfWeek => {
  let strDay = '';
  if (moment_dayOfWeek === 0) {
    strDay = '일요일';
  } else if (moment_dayOfWeek === 1) {
    strDay = '월요일';
  } else if (moment_dayOfWeek === 2) {
    strDay = '화요일';
  } else if (moment_dayOfWeek === 3) {
    strDay = '수요일';
  } else if (moment_dayOfWeek === 4) {
    strDay = '목요일';
  } else if (moment_dayOfWeek === 5) {
    strDay = '금요일';
  } else if (moment_dayOfWeek === 6) {
    strDay = '토요일';
  }
  return strDay;
};

export const getDayOfWeekString = moment_dayOfWeek => {
  const strDays = ['일', '월', '화', '수', '목', '금', '토'];
  if (moment_dayOfWeek >= 0 && moment_dayOfWeek <= 6) {
    return strDays[moment_dayOfWeek];
  }
  return '';
};
