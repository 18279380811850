import { DefineIcon, DefineIconTemplate } from 'froala-editor';

const template = 'material_design';

// Define a font icon called smartphoneIcon that uses the Material Design font.
// You should also make sure that you include the Material Design Iconic font in your <head>:
// <link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/material-design-iconic-font/2.1.2/css/material-design-iconic-font.min.css" />

DefineIconTemplate(template, '<i class="zmdi zmdi-[NAME]"></i>');

DefineIcon('quoteLine', { NAME: 'format-line-spacing', template });
DefineIcon('unquote', { NAME: 'menu', template });
