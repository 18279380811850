import qs from 'qs';
import _ from 'lodash';
import axiosInstance from './axiosInstance';

export const boardApi = {
  basicList: data => {
    return axiosInstance.get('board/user/lists', {
      params: {
        data,
      },
      paramsSerializer: () => {
        return qs.stringify(data);
      },
    });
  },
  basicDetail: data => {
    const sendData = _.omitBy(
      data,
      v => Number.isNaN(v) || v === null || v === undefined,
    );

    return axiosInstance.get('board/user/detail', {
      params: {
        sendData,
      },
      paramsSerializer: () => {
        return qs.stringify(sendData);
      },
    });
  },
  file: url => {
    return axiosInstance.get(url, { responseType: 'blob' });
  },
};
