/* eslint-disable no-param-reassign */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Box, Button, styled, useTheme, useMediaQuery } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Autoplay } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import 'swiper/components/navigation/navigation.min.css';
import Modal from '@mui/material/Modal';
import { popupPcStyle } from '../../lib/styles';
import { ReactComponent as PreIcon } from '../../assets/images/ic-pre-w.svg';
import { ReactComponent as NextIcon } from '../../assets/images/ic-next-w.svg';
import { ReactComponent as ClosIcon } from '../../assets/images/ic-close-w.svg';
import Viewer from '../../lib/Editor/Viewer';

const HomePopup = ({ open, popupData, setOpen }) => {
  SwiperCore.use([Navigation, Autoplay]); // swiper 기능 임포트
  const [currentStep, setCurrentStep] = useState(1); // swiper 스텝
  const [realStep, setRealStep] = useState(1); // 진짜 스텝 번호
  const [checkedPopups, setCheckedPopups] = useState([]); // 보지않을 팝업들
  const [renderingPopup, setRenderingPopup] = useState([]); // 최종 보여줄 팝업들
  const navigationPrevRef = useRef(null); // swiper 커스텀 네비버튼
  const navigationNextRef = useRef(null); // swiper 커스텀 네비버튼
  const theme = useTheme();
  const isDeskTop = useMediaQuery(theme.breakpoints.up('desktop'));

  // console.log('renderingPopup :', renderingPopup);

  // currentStep 처음/끝의 인덱스 반영한 현재 슬라이드 번호도출
  useEffect(() => {
    if (renderingPopup.length >= 1) {
      if (currentStep === 0) {
        setRealStep(renderingPopup?.length);
      } else if (currentStep === renderingPopup.length + 1) {
        setRealStep(1);
      } else {
        setRealStep(currentStep);
      }
    }
  }, [currentStep]);

  // N일간 보지않기 체크박스 이벤트
  const onNdayDisable = (checked, popupId, disableDay) => {
    if (checked) {
      setCheckedPopups([
        ...checkedPopups,
        {
          id: popupId,
          disable_day: Number(disableDay), // 다시보지 않는 N일
          checked_date: moment().format('YYYY-MM-DD'), // 보지않기 체크한 날짜
          able_date: moment()
            .add(Number(disableDay), 'days')
            .format('YYYY-MM-DD'), // 다시 보기 시작하는 날짜
        },
      ]);
    } else {
      setCheckedPopups(current => {
        const copiedArr = current.slice();
        const finalArr = copiedArr.filter(popup => popup.id !== popupId);
        return finalArr;
      });
    }
  };

  // 마운트와 동시 로컬스토리지에서 disable_day지난 것들은 삭제하고 checkedPopups에 저장
  useEffect(() => {
    // 먼저 로컬스토리지에서 불러와 임시저장
    const lsSaved = JSON.parse(localStorage.getItem('disabledPopup'));
    if (lsSaved === null) {
      setRenderingPopup(popupData);
    } else {
      // 임시저장 내용 validation
      const validated = lsSaved.filter(
        popup => new Date(popup.able_date).getTime() > Date.now(),
      );
      // 스테이트에 보지않을 팝업 리스트 저장
      setCheckedPopups(validated);
      // popupData에서 보지않을 리스트를 제외하여 렌더링한 최종 리스트
      const finalArr = popupData.filter(
        popup => !validated.map(i => i.id).includes(popup.popup_idx),
      );
      setRenderingPopup(finalArr);
    }
  }, [popupData]);

  // 로컬스토리지에 보지않을 리스트 저장
  useEffect(() => {
    localStorage.setItem('disabledPopup', JSON.stringify(checkedPopups));
  }, [checkedPopups]);

  return (
    <Box sx={{ width: '100%' }}>
      {renderingPopup.length < 1 ? null : (
        <Modal
          open={open}
          onClose={(event, reason) => {
            if (reason !== 'backdropClick') {
              setOpen(false);
            }
          }}
        >
          <Box
            sx={{
              ...popupPcStyle,
              // border: '1px solid red',
              height: isDeskTop ? '590px' : '540px',
              width: isDeskTop ? '500px' : '100%',
              left: isDeskTop ? '50%' : 0,
              bottom: isDeskTop ? '50%' : 0,
              transform: isDeskTop ? 'translate(-50%, 50%)' : 'translate(0, 0)',
              borderRadius: isDeskTop ? '20px' : '20px 20px 0 0',
            }}
          >
            <Container>
              <ClearBtn
                sx={{
                  display: isDeskTop ? 'block' : 'none',
                  top: isDeskTop ? '-50px' : '-9%',
                  right: isDeskTop ? '-24px' : '3%',
                  transform: 'translate(50%, 50%)',
                }}
                onClick={() => setOpen(false)}
              >
                <ClosIcon width={21} height={21} />
              </ClearBtn>
              <BtnBox
                sx={{
                  // border: '1px solid red',
                  display: renderingPopup.length >= 2 ? 'flex' : 'none',
                  width: isDeskTop ? '124%' : '20%',
                  top: isDeskTop ? '50%' : '-60px',
                  left: isDeskTop ? '-12%' : '50%',
                  transform: isDeskTop
                    ? 'translate(0, -50%)'
                    : 'translate(-50%, 0)',
                }}
              >
                <DesktopPreBtn ref={navigationPrevRef}>
                  {isDeskTop ? (
                    <PreIcon width={17} height={31} />
                  ) : (
                    <PreIcon width={9} height={16} />
                  )}
                </DesktopPreBtn>
                <DesktopNextBtn ref={navigationNextRef}>
                  {isDeskTop ? (
                    <NextIcon width={17} height={31} />
                  ) : (
                    <NextIcon width={9} height={16} />
                  )}
                </DesktopNextBtn>
              </BtnBox>
              <CountBox
                sx={{
                  left: '50%',
                  bottom: isDeskTop ? '-40px' : '105%',
                  transform: 'translate(-50%, 0)',
                }}
              >
                <FlexBox sx={{ width: '100%', zIndex: 1 }}>
                  <Step>
                    {realStep}
                    <span style={{ fontWeight: 400 }}>
                      &nbsp;/&nbsp;{renderingPopup.length}
                    </span>
                  </Step>
                  <MobileClearBtn
                    sx={{
                      display: isDeskTop ? 'none' : 'block',
                      top: 0,
                      right: '12px',
                    }}
                    onClick={() => setOpen(false)}
                  >
                    <ClosIcon width={16} height={16} />
                  </MobileClearBtn>
                </FlexBox>
              </CountBox>
              <RadiusBox
                sx={{
                  position: 'relative',
                  overflow: 'hidden',
                  borderRadius: isDeskTop ? '20px' : '20px 20px 0 0',
                }}
              >
                <SwiperBox
                  sx={{
                    position: 'relative',
                    overflow: 'hidden',
                    borderRadius: isDeskTop ? '20px' : '20px 20px 0 0',
                  }}
                >
                  <Swiper
                    className="mySwiper"
                    allowSlidePrev={renderingPopup.length >= 2}
                    allowSlideNext={renderingPopup.length >= 2}
                    modules={[Navigation, Autoplay]}
                    spaceBetween={0}
                    slidesPerView={1}
                    loop={renderingPopup?.length >= 2}
                    centeredSlides
                    navigation={{
                      prevEl: navigationPrevRef.current,
                      nextEl: navigationNextRef.current,
                    }}
                    onBeforeInit={swiper => {
                      swiper.params.navigation.prevEl =
                        navigationPrevRef.current;
                      swiper.params.navigation.nextEl =
                        navigationNextRef.current;
                    }}
                    onSlideChange={swiper => setCurrentStep(swiper.activeIndex)}
                  >
                    {renderingPopup &&
                      renderingPopup.map((popup, idx) => {
                        return (
                          <SwiperSlide
                            key={`popup-${popup.popup_idx}-${toString(idx)}`}
                            style={{
                              // border: '1px solid red',
                              overflow: 'hidden',
                              borderRadius: isDeskTop
                                ? '20px'
                                : '20px 20px 0 0',
                              zIndex: -1,
                            }}
                          >
                            {popup.type === 1 ? (
                              <TextTypeSlideInner
                                sx={{
                                  width: isDeskTop ? '100%' : '100%',
                                  height: isDeskTop ? '540px' : '480px',
                                }}
                              >
                                <Box>
                                  <Title>{popup.title}</Title>
                                  <Content
                                    sx={{
                                      width: isDeskTop ? '440px' : '90vw',
                                      maxHeight:
                                        popup.btn_use_yn === 'N'
                                          ? '400px'
                                          : '320px',
                                      height: isDeskTop ? 'auto' : '270px',
                                    }}
                                  >
                                    <Viewer model={popup.contents} />
                                  </Content>
                                </Box>
                                <TextTypeGotoBtn
                                  href={popup.btn_link}
                                  target={
                                    popup.btn_blank_yn === 'Y'
                                      ? '_blank'
                                      : '_self'
                                  }
                                  sx={{
                                    display:
                                      popup.btn_use_yn === 'Y'
                                        ? 'flex'
                                        : 'none',
                                  }}
                                >
                                  {popup.btn_name}
                                </TextTypeGotoBtn>
                              </TextTypeSlideInner>
                            ) : (
                              <ImageTypeSlideInner
                                sx={{
                                  // border: '1px solid red',
                                  width: isDeskTop ? '100%' : '100%',
                                  height: isDeskTop ? '540px' : '480px',
                                }}
                              >
                                {isDeskTop ? (
                                  <Atag
                                    href={
                                      popup.pc_image_link === ''
                                        ? null
                                        : popup.pc_image_link
                                    }
                                    target={
                                      popup.btn_blank_yn === 'Y'
                                        ? '_blank'
                                        : '_self'
                                    }
                                    rel="noreferrer"
                                    style={{
                                      position: 'relative',
                                      overflow: 'hidden',
                                      backgroundImage: `url(${popup.pc_image_url})`,
                                    }}
                                  >
                                    &nbsp;
                                  </Atag>
                                ) : (
                                  <Atag
                                    href={
                                      popup.mobile_image_link === ''
                                        ? null
                                        : popup.mobile_image_link
                                    }
                                    target={
                                      popup.btn_blank_yn === 'Y'
                                        ? '_blank'
                                        : '_self'
                                    }
                                    rel="noreferrer"
                                    style={{
                                      position: 'relative',
                                      overflow: 'hidden',
                                      backgroundImage: `url(${popup.mobile_image_url})`,
                                    }}
                                  >
                                    &nbsp;
                                  </Atag>
                                )}
                                <ImageTypeGotoBtn
                                  href={
                                    popup.btn_link === ''
                                      ? null
                                      : popup.btn_link
                                  }
                                  target={
                                    popup.btn_blank_yn === 'Y'
                                      ? '_blank'
                                      : '_self'
                                  }
                                  sx={{
                                    display:
                                      popup.btn_use_yn === 'Y'
                                        ? 'flex'
                                        : 'none',
                                  }}
                                >
                                  {popup.btn_name}
                                </ImageTypeGotoBtn>
                              </ImageTypeSlideInner>
                            )}
                            <BottomBox
                              sx={{
                                // border: '1px solid blue',
                                borderRadius: isDeskTop ? '0 0 20px 20px' : 0,
                                backgroundColor:
                                  popup.type === 1 ? '#F2F4F6' : '#fff',
                              }}
                            >
                              <Box sx={{ ml: '13px' }}>
                                {popup.activated_yn === 'N' ? (
                                  ''
                                ) : (
                                  <>
                                    <Checkbox
                                      sx={{
                                        color: '#CDD3D8',
                                        '&.Mui-checked': {
                                          color: '#00C4C4',
                                        },
                                        '& .MuiSvgIcon-root': { fontSize: 20 },
                                      }}
                                      onChange={e => {
                                        onNdayDisable(
                                          e.target.checked,
                                          popup.popup_idx,
                                          popup.disable_day === null
                                            ? '7'
                                            : popup.disable_day,
                                        );
                                        setOpen(false);
                                        setRenderingPopup([]);
                                      }}
                                      checked={
                                        checkedPopups?.filter(
                                          p => p?.id === popup?.popup_idx,
                                        ).length >= 1 && true
                                      }
                                    />
                                    {popup.disable_day === null
                                      ? '7'
                                      : popup.disable_day}
                                    일간 보지 않기
                                  </>
                                )}
                              </Box>
                              <Button
                                onClick={() => setOpen(false)}
                                sx={{ mr: '13px', color: '#212529' }}
                              >
                                닫기
                              </Button>
                            </BottomBox>
                          </SwiperSlide>
                        );
                      })}
                  </Swiper>
                </SwiperBox>
              </RadiusBox>
            </Container>
          </Box>
        </Modal>
      )}
    </Box>
  );
};

HomePopup.defaultProps = {
  popupData: null,
};

HomePopup.propTypes = {
  open: PropTypes.bool.isRequired,
  popupData: PropTypes.array,
  setOpen: PropTypes.func.isRequired,
};

const Container = styled(Box)({
  // border: '1px solid red',
  position: 'relative',
  width: '100%',
  height: '100%',
  borderRadius: '20px',
  // overflow: 'hidden',
});
const FlexBox = styled(Box)({
  // border: '1px solid red',
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});
const RadiusBox = styled(Box)({
  // border: '1px solid red',
  position: 'relative',
  width: '100%',
  // height: '100%',
  height: '590px',
  borderRadius: '20px',
  overflow: 'hidden',
});
const SwiperBox = styled(Box)({
  // border: '1px solid red',
  position: 'relative',
  height: '590px',
  width: '100%',
  overflow: 'hidden',
});
const TextTypeSlideInner = styled(Box)({
  // border: '1px solid red',
  position: 'relative',
  width: '100%',
  height: '540px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
});
const Title = styled(Box)({
  // border: '1px solid blue',
  position: 'relative',
  paddingBottom: '20px',
  margin: '32px 24px 4px 24px',
  fontWeight: 700,
  fontSize: '18px',
  lineHeight: '28px',
  color: '#212529',
  borderBottom: '1px solid lightgray',
});
const Content = styled(Box)({
  // border: '1px solid blue',
  position: 'relative',
  width: '440px',
  height: '320px',
  margin: '24px 24px 0',
  overflow: 'auto',
  overflowX: 'none',
});
const ImageTypeSlideInner = styled(Box)({
  // border: '1px solid red',
  position: 'relative',
  width: '100%',
  height: '540px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  alignItems: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center center',
  backgroundSize: 'cover',
  borderRadius: '20px 20px 0 0',
  overflow: 'hidden',
});
const Atag = styled('a')({
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center center',
  backgroundSize: 'cover',
  textDecoration: 'none',
  width: '100%',
  height: '100%',
  '&:hover': {
    color: '#fff',
    textDecoration: 'none',
  },
  '&:focus': {
    color: '#fff',
    textDecoration: 'none',
  },
  '&:link': {
    color: '#fff',
    textDecoration: 'none',
  },
  '&:vlink': {
    color: '#fff',
    textDecoration: 'none',
  },
  '&:visited': {
    textDecoration: 'none',
  },
});
const ImageTypeGotoBtn = styled('a')({
  // border: '1px solid red',
  position: 'absolute',
  bottom: 0,
  zIndex: 100,
  width: '88%',
  minWidth: '150px',
  height: '48px',
  margin: '0 0 24px 0',
  boxShadow: 'none',
  fontSize: '16px',
  fontWeight: 500,
  padding: '0 12px',
  border: 0,
  borderRadius: '3px',
  color: '#fff',
  backgroundColor: '#212529',
  textDecoration: 'none',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});
const ClearBtn = styled('button')({
  // border: '1px solid blue',
  border: 0,
  padding: 0,
  position: 'absolute',
  color: '#fff',
  borderRadius: '50%',
  backgroundColor: 'transparent',
  cursor: 'pointer',
  width: '30px',
  height: '30px',
});
const BtnBox = styled(Box)({
  // border: '1px solid red',
  position: 'absolute',
  top: '50%',
  left: '-15%',
  zIndex: 2,
  minWidth: '170px',
  transform: 'translate(0, -50%)',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});
const DesktopPreBtn = styled('button')({
  border: 0,
  borderRadius: '50%',
  backgroundColor: 'transparent',
  cursor: 'pointer',
  width: '38px',
  height: '38px',
});
const DesktopNextBtn = styled('button')({
  border: 0,
  borderRadius: '50%',
  backgroundColor: 'transparent',
  cursor: 'pointer',
  width: '38px',
  height: '38px',
});
const MobileClearBtn = styled('button')({
  position: 'absolute',
  padding: 0,
  border: 0,
  borderRadius: '50%',
  backgroundColor: 'transparent',
  cursor: 'pointer',
  width: '30px',
  height: '30px',
});
const BottomBox = styled(Box)({
  // border: '1px solid blue',
  position: 'relative',
  width: '100%',
  height: '52px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  border: 0,
});

const TextTypeGotoBtn = styled('a')({
  width: '88%',
  // maxWidth: '452px',
  minWidth: '150px',
  height: '48px',
  margin: '0 0 24px 0',
  boxShadow: 'none',
  fontSize: '16px',
  fontWeight: 500,
  padding: '0 12px',
  border: 0,
  borderRadius: '3px',
  color: '#fff',
  backgroundColor: '#00C4C4',
  cursor: 'pointer',
  textDecoration: 'none',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});
const CountBox = styled(Box)({
  position: 'absolute',
  left: '50%',
  bottom: '-40px',
  transform: 'translate(-50%, 0)',
  width: '100%',
});
const Step = styled(Box)({
  padding: 0,
  color: '#fff',
  fontSize: '16px',
  fontWeight: 700,
  paddingBottom: '2px',
  margin: '0 30px',
});

export default HomePopup;
