import { React } from 'react';
import { IconButton, Box, Toolbar, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ReactComponent as LogoIcon } from '../../assets/images/m-logo.svg';
import { ReactComponent as LeftArrow } from '../../assets/images/m-leftArrow.svg';
import { ReactComponent as SearchIcon } from '../../assets/images/m-search.svg';

const MobileToolbar = ({ searchOpen, setSearchOpen, title, setOpen }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const isSearchPage = path_name => {
    const pathArr = path_name.split('/');
    return pathArr.includes('search');
  };

  return (
    <Toolbar
      id="back-to-top-anchor"
      sx={{
        borderBottom: '1px solid #E6EAED',
        zIndex: 101,
        background: '#fff',
      }}
    >
      {/* 홍화면 */}
      {location.pathname === '/' && (
        <>
          <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
            <LogoIcon />
          </Box>
          <IconButton
            onClick={() => {
              setSearchOpen(current => !current);
            }}
          >
            {searchOpen === false && <SearchIcon width={24} height={24} />}
            {searchOpen === true && <CloseIcon sx={{ color: 'gray' }} />}
          </IconButton>
        </>
      )}
      {/* 검색화면 */}
      {isSearchPage(location.pathname) && (
        <>
          <IconButton
            onClick={() => {
              navigate(-1);
            }}
          >
            <LeftArrow />
          </IconButton>
          <Box
            sx={{
              flexGrow: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography
              sx={{ fontSize: '18px', fontWeight: 500, color: '#212529' }}
            >
              검색결과
            </Typography>
          </Box>
        </>
      )}
      {/* 일반화면 */}
      {location.pathname !== '/' && !isSearchPage(location.pathname) && (
        <>
          <IconButton
            onClick={() => {
              navigate(-1);
            }}
          >
            <LeftArrow />
          </IconButton>
          <Box
            sx={{
              flexGrow: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography
              sx={{
                fontSize: '18px',
                fontWeight: 500,
                color: '#212529',
                ml: location.pathname.includes('menu') ? '36px' : '0px',
              }}
            >
              {title}
            </Typography>
          </Box>
          {location.pathname.includes('menu') && (
            <IconButton
              onClick={() => {
                setSearchOpen(current => !current);
              }}
            >
              {searchOpen === false && <SearchIcon width={24} height={24} />}
              {searchOpen === true && <CloseIcon sx={{ color: 'gray' }} />}
            </IconButton>
          )}
        </>
      )}
      {!(searchOpen === true) && (
        <IconButton onClick={() => setOpen(true)}>
          <MenuIcon />
        </IconButton>
      )}
    </Toolbar>
  );
};
MobileToolbar.propTypes = {
  searchOpen: PropTypes.bool.isRequired,
  setSearchOpen: PropTypes.func.isRequired,
  title: PropTypes.string,
  setOpen: PropTypes.func.isRequired,
};
MobileToolbar.defaultProps = {
  title: '',
};

export default MobileToolbar;
