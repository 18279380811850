import React from 'react';
import toast from 'react-hot-toast';

const showToastError = message => {
  toast.error(message, {
    style: {
      borderRadius: '10px',
      background: '#333',
      color: '#fff',
    },
  });
};

export function showError(error) {
  if (error.response) {
    showToastError(error.response.data.message);
  } else if (error.message) {
    showToastError(error.message);
  } else {
    showToastError('에러가 발생했습니다.');
  }
}

export const getfindMenu = (menuList, menuId) => {
  if (menuList) {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < menuList.length; i++) {
      if (menuList[i].menu_idx === menuId) {
        return menuList[i];
      }
      if (menuList[i].menu_type === 'category') {
        const find = getfindMenu(menuList[i].menu, menuId);
        if (find) {
          return find;
        }
      }
    }
  }
  return null;
};

export const findCategory = (menuList, menuId) => {
  if (menuList) {
    for (let i = 0; i < menuList.length; i += 1) {
      if (menuList[i].menu_idx === menuId) {
        return menuList[i];
      }
      if (menuList[i].menu_type === 'category') {
        const find = getfindMenu(menuList[i].menu, menuId);
        if (find) {
          return menuList[i];
        }
      }
    }
  }
  return null;
};

export const getfindBoard = (menuList, boardId) => {
  if (menuList) {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < menuList.length; i++) {
      if (menuList[i].board_idx === boardId) {
        return menuList[i];
      }
      if (menuList[i].menu_type === 'category') {
        const find = getfindBoard(menuList[i].menu, boardId);
        if (find) {
          return find;
        }
      }
    }
  }
  return null;
};

export const findCategoryByBoardId = (menuList, boardId) => {
  if (menuList) {
    for (let i = 0; i < menuList.length; i += 1) {
      if (menuList[i].board_idx === boardId) {
        return menuList[i];
      }
      if (menuList[i].menu_type === 'category') {
        const find = getfindBoard(menuList[i].menu, boardId);
        if (find) {
          return menuList[i];
        }
      }
    }
  }
  return null;
};

export const findRoot = (menuList, menuId) => {
  const menu = findCategory(menuList, menuId);
  if (menu) {
    if (menu.menu_depth === 2) return findCategory(menuList, menu.menu_idx);
    return menu;
  }
  return null;
};

export const findRootByBoardId = (menuList, boardId) => {
  const menu = findCategoryByBoardId(menuList, boardId);
  if (menu) {
    if (menu.menu_depth === 2)
      return findCategoryByBoardId(menuList, menu.board_idx);
    return menu;
  }
  return null;
};

export const findBoardByMenuId = (menuList, menuId) => {
  if (menuList) {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < menuList.length; i++) {
      if (menuList[i].menu_idx === menuId) {
        return menuList[i];
      }
      if (menuList[i].menu_type === 'category') {
        const find = findBoardByMenuId(menuList[i].menu, menuId);
        if (find) {
          return find;
        }
      }
    }
  }
  return null;
};

export const homeContext = React.createContext();

export const SetLocalStorageSearchWord = newSearchWord => {
  let recentSearchWords = [];
  // 최근 검색어 get from 로컬스토리지
  const localWords = localStorage.getItem('popularWord');
  if (localWords !== null) {
    recentSearchWords = [...JSON.parse(localWords)];
  }
  // 새 검색어 로컬 변수에 저장
  if (newSearchWord < 1) {
    // eslint-disable-next-line no-useless-return
    return;
  }
  const validatedSearchWordArray = recentSearchWords.filter(
    word => word?.word !== newSearchWord,
  );
  validatedSearchWordArray.unshift({
    word: newSearchWord,
    date: Date.now(),
  });
  // 로컬 변수를 로컬스토리지에 저장
  localStorage.setItem('popularWord', JSON.stringify(validatedSearchWordArray));
};

export const numberOfThisWeek = dateFrom => {
  const currentDate = dateFrom.getDate();
  const startOfMonth = new Date(dateFrom.setDate(1));
  const weekDay = startOfMonth.getDay();
  return parseInt((weekDay - 2 + currentDate) / 7, 10) + 1;
};

export const makeDotColor = color => {
  let finalColor;
  if (color === 'red') {
    finalColor = '#F25555';
  } else if (color === 'orange') {
    finalColor = '#EE9D3D';
  } else if (color === 'green') {
    finalColor = '#00B2B2';
  } else if (color === 'blue') {
    finalColor = '#4167D9';
  } else if (color === 'purple') {
    finalColor = '#8D4AE1';
  }
  return finalColor;
};

export const kakaoShare = (title, summary, image) => {
  window.Kakao.Share.sendDefault({
    objectType: 'feed',
    content: {
      title: title || '',
      description: summary || '',
      imageUrl: image || 'https://static.makercenter.wadiz.kr/600X600.jpg',
      imageWidth: 302,
      imageHeight: 160,
      link: {
        mobileWebUrl: window.location.href,
        webUrl: window.location.href,
      },
    },
    buttons: [
      {
        title: '웹으로 보기',
        link: {
          mobileWebUrl: window.location.href,
          webUrl: window.location.href,
        },
      },
    ],
  });
};
