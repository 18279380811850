import React from 'react';
import PropTypes from 'prop-types';
import { Box, ImageListItem, styled } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import dayjs from 'dayjs';

const CardView = ({ item, onClick, mobile, boardTitle }) => {
  const theme = useTheme();
  const Container = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '16px',
    marginBottom: '30px',
    [theme.breakpoints.up('desktop')]: {
      height: '185px',
      paddingRight: '22px',
      borderBottom: '1px solid #e6eaed',
      marginBottom: '40px',
    },
  });

  return (
    <ImageListItem
      onClick={() => onClick(item.idx, item.rownum)}
      sx={{
        cursor: 'pointer',
      }}
    >
      <Box display="flex" flexDirection="column">
        <img
          src={item.image_url}
          loading="lazy"
          alt={item.title}
          style={{
            width: mobile ? '100%' : '302px',
            height: mobile ? '174px' : '160px',
            borderRadius: '8px',
            objectFit: 'cover',
          }}
          data-promo-card={boardTitle === '성공사례' ? true : null}
        />
        <Container>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            // sx={{ height: '18px' }}
          >
            <Box
              sx={{
                fontWeight: '400',
                fontSize: '13px',
                lineHeight: '18px',
                color: '#495057',
              }}
            >
              {item?.category1_title ? item?.category1_title : ''}
              {item?.category2_title ? ` > ${item?.category2_title}` : ''}
            </Box>
            {item?.important_yn === 'Y' ? (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: '3px',
                  bgcolor: '#00c4c4',
                  width: '27px',
                  height: '18px',
                  color: '#fff',
                  fontWeight: '700',
                  fontSize: '10px',
                  lineHeight: '14px',
                }}
              >
                중요
              </Box>
            ) : null}
          </Box>
          <Box
            sx={{
              fontWeight: '500',
              fontSize: '16px',
              lineHeight: '24px',
              color: '#212529',
              mt: '8px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              WebkitLineClamp: 2,
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
            }}
          >
            {item.title}
          </Box>
          {item.summary ? (
            <Box
              sx={{
                fontWeight: '400',
                fontSize: '13px',
                lineHeight: '18px',
                color: '#90949c',
                mt: '8px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                WebkitLineClamp: 2,
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
              }}
            >
              {item.summary}
            </Box>
          ) : null}
          <Box
            sx={{
              fontWeight: '400',
              fontSize: mobile ? '13px' : '14px',
              lineHeight: '16px',
              color: '#868e96',
              mt: '8px',
            }}
          >
            {item?.reg_date
              ? dayjs(new Date(item.reg_date)).format('YYYY.MM.DD')
              : ''}
          </Box>
        </Container>
      </Box>
    </ImageListItem>
  );
};

CardView.propTypes = {
  item: PropTypes.shape({
    idx: PropTypes.number,
    title: PropTypes.string,
    contents: PropTypes.string,
    rownum: PropTypes.number,
    image_url: PropTypes.string,
    important_yn: PropTypes.string,
    reg_date: PropTypes.string,
    summary: PropTypes.string,
    category1_title: PropTypes.string,
    category2_title: PropTypes.string,
  }),
  onClick: PropTypes.func.isRequired,
  mobile: PropTypes.bool,
  boardTitle: PropTypes.string.isRequired,
};

CardView.defaultProps = {
  item: null,
  mobile: false,
};

export default CardView;
