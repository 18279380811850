import { React } from 'react';
import { Box, Button } from '@mui/material';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const MenuBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: '0px',
  width: '89px',
  height: 'auto',
}));

const MenuTextDepth1 = styled(Button)(() => ({
  padding: '0px',
  display: 'block',
  marginBottom: '12px',
  height: '24px',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '15px',
  lineHeight: '24px',
  textAlign: 'start',
  letterSpacing: '-0.3px',
  '&:hover': {
    color: '#00B2B2',
    background: '#fff',
  },
}));
const MenuTextDepth2 = styled(Button)(() => ({
  display: 'block',
  padding: '0px',
  marginBottom: '12px',
  height: '18px',
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: '13px',
  lineHeight: '18px',
  textAlign: 'start',
  letterSpacing: '-0.3px',
  color: '#868E96',
  '&:hover': {
    color: '#00B2B2',
    background: '#fff',
  },
}));

const Depth1 = ({ select, m, close }) => {
  // const location = useLocation();
  const navigate = useNavigate();
  const handleClick = (menuIdx, title) => {
    window.dataLayer.push({
      event: 'ga.custom.tag',
      GACategory: 'GNB',
      GALabel: title,
      GAAction: '클릭',
    });
    navigate(`/menu/${menuIdx}`);
  };

  let selectColor = '#212529';
  if (select.menu_idx === m.menu_idx) {
    selectColor = '#00B2B2';
  }

  return (
    <>
      <MenuTextDepth1
        sx={{ color: selectColor }}
        onClick={() => {
          if ('menu' in m)
            handleClick(m.menu[0].menu_idx, m.menu[0].menu_title);
          else handleClick(m.menu_idx, m.menu_title);
          close();
        }}
      >
        {m.menu_title}
      </MenuTextDepth1>
      {m?.menu &&
        m.menu.map(one => {
          let tcolor = '#868E96';
          if (one.menu_idx === select.menu_idx) {
            tcolor = '#00B2B2';
          }
          return (
            <MenuTextDepth2
              sx={{ color: tcolor }}
              onClick={() => {
                handleClick(one?.menu_idx, one?.menu_title);
                close();
              }}
              key={one.menu_idx}
            >
              {one.menu_title}
            </MenuTextDepth2>
          );
        })}
    </>
  );
};
Depth1.propTypes = {
  m: PropTypes.object.isRequired,
  select: PropTypes.object.isRequired,
  close: PropTypes.func.isRequired,
};

const PCToolbarMenuBox = ({ selected, menus, close }) => {
  const widStyle = {
    width: `150px`,
  };
  return (
    <MenuBox sx={widStyle}>
      {menus.map(m => {
        return (
          <Depth1 select={selected} key={m.menu_idx} m={m} close={close} />
        );
      })}
    </MenuBox>
  );
};
PCToolbarMenuBox.propTypes = {
  menus: PropTypes.array,
  selected: PropTypes.object.isRequired,
  close: PropTypes.func,
};
PCToolbarMenuBox.defaultProps = {
  menus: [],
  close: null,
};
export default PCToolbarMenuBox;
