import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  styled,
  Box,
  IconButton,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import moment from 'moment';
import { padStart } from 'lodash';
import Row from './CalendarRow';
import { dataApi } from '../../api/data';
import { boardApi } from '../../api/board';
import { ReactComponent as PrevArrow } from '../../assets/images/calendar/ic_arrow_left_20.svg';
import { ReactComponent as NextArrow } from '../../assets/images/calendar/ic_arrow_right_20.svg';
import { ReactComponent as IconList } from '../../assets/images/calendar/ic_list_16.svg';
import { ReactComponent as IconCalendar } from '../../assets/images/calendar/ic_calendar_16.svg';
import CalendarList from './CalendarList';
import CalendarDateDialog from './CalendarDateDialog';
import CalendarScheduleDialog from './CalendarScheduleDialog';
import WideRollingBanner from '../home/KVBanner/WideRollingBanner';

const Header = styled(Box)(({ theme }) => ({
  fontWeight: 400,
  fontSize: '15px',
  lineHeight: '24px',
  color: '#212529',

  borderTop: '2px solid #212529',
  borderBottom: '1px solid #E6EAED',
  display: 'flex',

  [theme.breakpoints.down('desktop')]: {
    fontSize: '14px',
    lineHeight: '20px',
  },
}));

const HeaderCell = styled(Box)({
  flexBasis: 1,
  flexGrow: 1,
  flexShrink: 1,
  padding: '10px 0',
  textAlign: 'center',
});

const HeaderVLine = styled(Box)({
  width: '1px',
  flexGrow: 0,
  flexShrink: 0,
  backgroundColor: '#E6EAED',
});

function getDateString(year, month, day, type) {
  const strYear = padStart(year.toString(), 4, '0');
  const strMonth = padStart(month.toString(), 2, '0');
  const strDay = padStart(day.toString(), 2, '0');
  if (type === 'YYYY-MM-DD' || type === 'yyyy-mm-dd') {
    return `${strYear}-${strMonth}-${strDay}`;
  }
  return `${strYear}${strMonth}${strDay}`;
}

function getDateNumber(strDate) {
  const splits = strDate.split('-');
  const year = parseInt(splits[0], 10) || 0;
  const momth = parseInt(splits[1], 10) || 0;
  const day = parseInt(splits[2], 10) || 0;
  return year * 10000 + momth * 100 + day;
}
const McCalendar = ({ board_idx, page, page_size }) => {
  const [mode, setMode] = useState('calendar'); // or 'list'
  const [time, setTime] = useState(moment());
  const [dataSeed, setDataSeed] = useState(null);
  const [holidaySeed, setHolidaySeed] = useState(null);
  const [weeks, setWeeks] = useState([]);
  const [schedules, setSchedules] = useState([]);
  const [contentWidth, setContentWidth] = useState(0);
  const [openDateDlg, setOpenDateDlg] = useState({
    open: false,
    schedules: [],
  });
  const [openScheduleDlg, setOpenScheduleDlg] = useState({
    open: false,
  });
  const [banner, setBanner] = useState([]);
  const [currentBannerIndex, setCurrentBannerIndex] = useState(0);

  const contentRef = useRef();
  const theme = useTheme();
  const isDeskTop = useMediaQuery(theme.breakpoints.up('desktop'));

  const onChangeMode = text => {
    window.dataLayer.push({
      event: 'ga.custom.tag',
      GACategory: '메이커캘린더_보기유형',
      GALabel: text,
      GAAction: '클릭',
    });
  };

  useEffect(() => {
    const today = moment();
    const isToday = (year, month, date) => {
      return (
        today.year() === year &&
        today.month() === month &&
        today.date() === date
      );
    };
    const prevMonth = time.clone().add(-1, 'month');
    const nextMonth = time.clone().add(+1, 'month');
    const daysInPrevMonth = prevMonth.daysInMonth();

    const firstDayInMonth = time.clone().date(1);
    const prevDisplayCount = firstDayInMonth.day();

    const weeks0 = [];
    let weekIndex = 0;

    let dayInWeek = -1;
    for (let i = 0; i < prevDisplayCount; i += 1) {
      dayInWeek = i;
      if (dayInWeek === 0) {
        weeks0.push({ days: [] });
        weekIndex = weeks0.length - 1;
      }
      const year = prevMonth.year();
      const month = prevMonth.month();
      const date = daysInPrevMonth - prevDisplayCount + i + 1;

      weeks0[weekIndex].days.push({
        monthOffset: -1,
        today: isToday(year, month, date),
        year,
        month,
        date,
        dayInWeek,
        color: 'grey',
      });
    }

    const daysInMonth = time.daysInMonth();
    for (let i = 0; i < daysInMonth; i += 1) {
      dayInWeek = (dayInWeek + 1) % 7;

      if (dayInWeek === 0) {
        weeks0.push({ days: [] });
        weekIndex = weeks0.length - 1;
      }
      let color = 'black';
      if (dayInWeek === 0) {
        color = 'red';
      } else if (dayInWeek === 6) {
        color = 'blue';
      }
      const year = time.year();
      const month = time.month();
      const date = i + 1;
      weeks0[weekIndex].days.push({
        monthOffset: 0,
        today: isToday(year, month, date),
        year,
        month,
        date,
        dayInWeek,
        color,
      });
    }
    const nextCount = 6 - dayInWeek;

    for (let i = 0; i < nextCount; i += 1) {
      dayInWeek = (dayInWeek + 1) % 7;

      const year = nextMonth.year();
      const month = nextMonth.month();
      const date = i + 1;

      weeks0[weekIndex].days.push({
        monthOffset: 1,
        today: isToday(year, month, date),
        year,
        month,
        date,
        dayInWeek,
        color: 'grey',
      });
    }

    setWeeks(weeks0);
    setHolidaySeed({ year: time.year(), month: time.month(), daysInMonth });
  }, [time]);

  useEffect(() => {
    if (holidaySeed !== null) {
      const loadHoliday = async () => {
        const strStart = getDateString(
          holidaySeed.year,
          holidaySeed.month + 1,
          1,
          'YYYY-MM-DD',
        );
        const strEnd = getDateString(
          holidaySeed.year,
          holidaySeed.month + 1,
          holidaySeed.daysInMonth,
          'YYYY-MM-DD',
        );
        try {
          const { data } = await dataApi.getHoliday(strStart, strEnd);
          const mapHoliday = new Map(
            data.data.lists.map(item => {
              const key = parseInt(item.locdate, 10) || 0;
              return [key, item];
            }),
          );
          const newWeeks = weeks.map(week => {
            return {
              days: week.days.map(day => {
                const key = day.year * 10000 + (day.month + 1) * 100 + day.date;
                return { ...day, holiday: mapHoliday.get(key) };
              }),
            };
          });
          setWeeks(newWeeks);
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error(err.message);
        }

        setDataSeed({
          board_type: 'calendar',
          board_idx,
          page,
          page_size,
          platform: isDeskTop ? 'PC' : 'MOBILE',
          search_start_date: strStart,
          search_end_date: strEnd,
        });
      };
      loadHoliday();
    }
  }, [holidaySeed]);

  useEffect(() => {
    if (dataSeed !== null) {
      const loadData = async () => {
        try {
          const { data } = await boardApi.basicList(dataSeed);
          const list = data.data.lists.map(item => {
            return {
              ...item,
              startDateNumber: getDateNumber(item.start_date),
              endDateNumber: getDateNumber(item.end_date),
            };
          });
          list.sort((a, b) => {
            if (a.rownum > b.rownum) return 1;
            if (a.rownum === b.rownum) return 0;
            return -1;
          });

          list.sort((a, b) => {
            if (a.startDateNumber > b.startDateNumber) return 1;
            if (a.startDateNumber === b.startDateNumber) return 0;
            return -1;
          });

          setSchedules(list);
          setBanner(data.data.banner);
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error(err.message);
        }
      };
      loadData();
    }
  }, [dataSeed]);

  useEffect(() => {
    if (contentRef.current) {
      setContentWidth(contentRef.current.offsetWidth);
    }
  }, []);

  const handleResize = () => {
    if (contentRef.current) {
      setContentWidth(contentRef.current.offsetWidth);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleClickPrev = () => {
    setTime(time.clone().add(-1, 'month'));
    setHolidaySeed(null);
    setDataSeed(null);
    setSchedules([]);
  };
  const handleClickNext = () => {
    setTime(time.clone().add(1, 'month'));
    setHolidaySeed(null);
    setDataSeed(null);
    setSchedules([]);
  };
  const handleClickCell = yyyymmdd => {
    const cellSchedules = schedules.filter(schedule => {
      return (
        schedule.startDateNumber <= yyyymmdd &&
        yyyymmdd <= schedule.endDateNumber
      );
    });
    setOpenDateDlg({ open: true, schedules: cellSchedules, yyyymmdd });
  };
  const handleClickSchedule = scheduleIdx => {
    if (openDateDlg.open) {
      setOpenDateDlg({ open: false, schedules: [] });
    }
    const found = schedules.find(schedule => schedule.idx === scheduleIdx);
    if (found) {
      setOpenScheduleDlg({ open: true, schedule: found });
    }
  };
  const handleClickBanner = () => {
    if (banner[currentBannerIndex].popup_yn === 'N') {
      window.location.href = banner[currentBannerIndex].image_link;
    } else {
      window.open(banner[currentBannerIndex].image_link, '_blank');
    }
  };
  return (
    <Box sx={{ minWidth: '230px' }}>
      {banner.length > 0 && (
        <Box sx={{ marginBottom: isDeskTop ? 0 : '20px' }}>
          <WideRollingBanner
            kvBanners={banner}
            currentIndex={currentBannerIndex}
            setCurrentIndex={setCurrentBannerIndex}
            onClick={handleClickBanner}
          />
        </Box>
      )}
      <Box sx={{ display: isDeskTop ? 'flex' : 'block', alignItems: 'center' }}>
        <Box sx={{ textAlign: isDeskTop ? 'start' : 'center' }}>
          <Box
            sx={{
              display: 'inline-flex',
              alignItems: 'center',
              margin: isDeskTop ? 0 : '0 auto',
            }}
          >
            <IconButton onClick={handleClickPrev} sx={{ padding: 0 }}>
              <PrevArrow />
            </IconButton>
            <Box
              sx={{
                fontWeight: 500,
                fontSize: isDeskTop ? '20px' : '14px',
                lineHeight: isDeskTop ? '20px' : '14px',
                color: '#212529',
                marginLeft: isDeskTop ? '24px' : '8px',
              }}
            >{`${time.format('YYYY')}년`}</Box>
            <Box
              sx={{
                fontWeight: 700,
                fontSize: isDeskTop ? '36px' : '24px',
                lineHeight: isDeskTop ? '36px' : '28px',
                color: '#212529',
                marginLeft: isDeskTop ? '16px' : '8px',
              }}
            >
              {time.format('MM')}
            </Box>
            <Box
              sx={{
                fontWeight: 700,
                fontSize: isDeskTop ? '32px' : '20px',
                lineHeight: isDeskTop ? '32px' : '28px',
                color: '#212529',
              }}
            >
              월
            </Box>
            <IconButton
              onClick={handleClickNext}
              sx={{ padding: 0, marginLeft: isDeskTop ? '24px' : '8px' }}
            >
              <NextArrow />
            </IconButton>
          </Box>
        </Box>
        <Box sx={{ flexGrow: 1, height: '16px' }} />
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: isDeskTop ? 'flex-start' : 'space-between',
          }}
        >
          <Box
            sx={{
              fontWeight: 400,
              fontSize: '14px',
              lineHeight: '14px',
              color: '#495057',
            }}
          >
            <Box
              sx={{
                display: 'inline-flex',
                alignItems: 'center',
                marginRight: isDeskTop ? '16px' : '10px',
              }}
            >
              <Box>
                <Box
                  sx={{
                    backgroundColor: '#F25555',
                    width: '6px',
                    height: '6px',
                    borderRadius: '50%',
                  }}
                />
              </Box>
              <Box sx={{ marginLeft: '4px' }}>
                {isDeskTop ? '이벤트/혜택' : '이벤트'}
              </Box>
            </Box>
            <Box
              sx={{
                display: 'inline-flex',
                alignItems: 'center',
                marginRight: isDeskTop ? '16px' : '10px',
              }}
            >
              <Box>
                <Box
                  sx={{
                    backgroundColor: '#EE9D3D',
                    width: '6px',
                    height: '6px',
                    borderRadius: '50%',
                  }}
                />
              </Box>
              <Box sx={{ marginLeft: '4px' }}>
                {isDeskTop ? '와디즈 스쿨' : '스쿨'}
              </Box>
            </Box>
            <Box
              sx={{
                display: 'inline-flex',
                alignItems: 'center',
                marginRight: isDeskTop ? '16px' : '10px',
              }}
            >
              <Box>
                <Box
                  sx={{
                    backgroundColor: '#00B2B2',
                    width: '6px',
                    height: '6px',
                    borderRadius: '50%',
                  }}
                />
              </Box>
              <Box sx={{ marginLeft: '4px' }}>기획전</Box>
            </Box>
            <Box
              sx={{
                display: 'inline-flex',
                alignItems: 'center',
                marginRight: isDeskTop ? '16px' : '10px',
              }}
            >
              <Box>
                <Box
                  sx={{
                    backgroundColor: '#4167D9',
                    width: '6px',
                    height: '6px',
                    borderRadius: '50%',
                  }}
                />
              </Box>
              <Box sx={{ marginLeft: '4px' }}>
                {isDeskTop ? '서비스/정책' : '정책'}
              </Box>
            </Box>
            <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
              <Box>
                <Box
                  sx={{
                    backgroundColor: '#8D4AE1',
                    width: '6px',
                    height: '6px',
                    borderRadius: '50%',
                  }}
                />
              </Box>
              <Box sx={{ marginLeft: '4px' }}>스토어 정산</Box>
            </Box>
          </Box>
          <Box
            sx={{
              marginLeft: isDeskTop ? '40px' : 0,
              height: isDeskTop ? '24px' : '16px',
              lineHeight: 0,
            }}
          >
            {mode === 'list' ? (
              <Box
                sx={{
                  cursor: 'pointer',
                  display: 'inline-flex',
                  alignItems: 'center',
                }}
                onClick={() => {
                  setMode('calendar');
                  onChangeMode('달력으로 보기');
                }}
              >
                <IconCalendar />
                {isDeskTop && (
                  <Box
                    sx={{
                      fontWeight: 500,
                      fontSize: '16px',
                      lineHeight: '24px',
                      color: '#212529',
                      marginLeft: '6px',
                    }}
                  >
                    달력으로 보기
                  </Box>
                )}
              </Box>
            ) : (
              <Box
                sx={{
                  cursor: 'pointer',
                  display: 'inline-flex',
                  alignItems: 'center',
                }}
                onClick={() => {
                  setMode('list');
                  onChangeMode('리스트로 보기');
                }}
              >
                <IconList />
                {isDeskTop && (
                  <Box
                    sx={{
                      fontWeight: 500,
                      fontSize: '16px',
                      lineHeight: '24px',
                      color: '#212529',
                      marginLeft: '6px',
                    }}
                  >
                    리스트로 보기
                  </Box>
                )}
              </Box>
            )}
          </Box>
        </Box>
      </Box>

      <Box ref={contentRef} sx={{ marginTop: isDeskTop ? '20px' : '10px' }}>
        {mode === 'list' ? (
          <Box>
            <CalendarList
              schedules={schedules}
              onClickSchedule={handleClickSchedule}
            />
          </Box>
        ) : (
          <Box>
            <Header>
              <HeaderCell sx={{ color: '#FF6666' }}>일</HeaderCell>
              <HeaderVLine />
              <HeaderCell>월</HeaderCell>
              <HeaderVLine />
              <HeaderCell>화</HeaderCell>
              <HeaderVLine />
              <HeaderCell>수</HeaderCell>
              <HeaderVLine />
              <HeaderCell>목</HeaderCell>
              <HeaderVLine />
              <HeaderCell>금</HeaderCell>
              <HeaderVLine />
              <HeaderCell sx={{ color: '#557CF2' }}>토</HeaderCell>
            </Header>
            <Box>
              {weeks.map(week => {
                const days = week.days.map(day => {
                  let { color } = day;
                  if (
                    color !== 'red' &&
                    day.holiday &&
                    day.holiday.isHoliday === 'Y'
                  ) {
                    color = 'red';
                  }
                  const yyyymmdd =
                    day.year * 10000 + (day.month + 1) * 100 + day.date;
                  return {
                    date: day.date,
                    color,
                    today: day.today,
                    yyyymmdd,
                  };
                });
                const weekSchedules = schedules.filter(schedule => {
                  return (
                    schedule.endDateNumber >= days[0].yyyymmdd &&
                    schedule.startDateNumber <= days[6].yyyymmdd
                  );
                });
                return (
                  <Row
                    days={days}
                    key={`week-${week.days[0].year}-${week.days[0].month}-${week.days[0].date}`}
                    rowWidth={contentWidth}
                    schedules={weekSchedules}
                    onClickCell={handleClickCell}
                    onClickSchedule={handleClickSchedule}
                  />
                );
              })}
            </Box>
          </Box>
        )}
      </Box>
      {openDateDlg.open && (
        <CalendarDateDialog
          open={openDateDlg.open}
          onClose={() => {
            setOpenDateDlg({ open: false, schedules: [] });
          }}
          schedules={openDateDlg.schedules}
          yyyymmdd={openDateDlg.yyyymmdd}
          onClickSchedule={handleClickSchedule}
        />
      )}
      {openScheduleDlg.open && (
        <CalendarScheduleDialog
          open={openScheduleDlg.open}
          onClose={() => {
            setOpenScheduleDlg({ open: false });
            window.dataLayer.push({
              event: 'ga.custom.tag',
              GACategory: '메이커캘린더_모달',
              GALabel: '닫기',
              GAAction: '클릭',
            });
          }}
          schedule={openScheduleDlg.schedule}
          scheduleIdx={openScheduleDlg.schedule.idx}
          board_idx={board_idx}
        />
      )}
    </Box>
  );
};

McCalendar.propTypes = {
  board_idx: PropTypes.number.isRequired,
  page: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  page_size: PropTypes.number,
};

McCalendar.defaultProps = {
  page: 1,
  page_size: 10,
};

export default McCalendar;
