import { atom } from 'recoil';

export const menuState = atom({
  key: 'menu',
  default: null,
});

export const menuOpenState = atom({
  key: 'menuOpenState',
  default: [],
});

export const currentMenuId = atom({
  key: 'currentMenuId',
  default: '',
});

export const detailState = atom({
  key: 'detailState',
  default: null,
});

export const popupLength = atom({
  key: 'popupLength',
  default: {
    initLength: 0,
  },
});

export const searchWordGlobal = atom({
  key: 'searchWord',
  default: null,
});
