/* eslint-disable no-void */
/* eslint-disable no-cond-assign */
/* eslint-disable no-unused-expressions */
import { PLUGINS, POPUP_TEMPLATES, RegisterCommand } from 'froala-editor';

RegisterCommand('quote', {
  title: '인용',
  icon: 'quote',
  plugin: 'quote',
  callback() {
    const { quote } = this;
    quote.showPopup();
  },
});
RegisterCommand('quoteDefault', {
  title: '기본형',
  icon: 'quote',
  callback() {
    const { quote } = this;
    quote.insert('type-default');
    quote.hidePopup();
  },
});
RegisterCommand('quoteLine', {
  title: '라인형',
  icon: 'quoteLine',
  callback() {
    const { quote } = this;
    quote.insert('type-line');
    quote.hidePopup();
  },
});
RegisterCommand('unquote', {
  title: '본문 스타일로 초기화',
  icon: 'unquote',
  callback() {
    const { quote } = this;
    quote.unquote();
    quote.hidePopup();
  },
});
function Quote(editor) {
  return {
    _init: () => {
      const { button, opts: options, popups } = editor;
      const templateProperties = {
        buttons: `<div class="fr-buttons">${button.buildList(
          options.quoteButtons,
        )}</div>`,
      };
      return popups.create('quote.popup', templateProperties);
    },
    showPopup: () => {
      const button = document.querySelector('[data-cmd="quote"]');
      if (button == null) return;
      const { pageYOffset } = window;
      const { left: leftOffset, top, height } = button.getBoundingClientRect();
      const topOffset = pageYOffset + top + height - 10;
      editor.popups.setContainer('quote.popup', editor.$tb);
      editor.popups.show('quote.popup', leftOffset, topOffset, height);
    },
    hidePopup: () => {
      editor.popups.hide('quote.popup');
    },
    insert: className => {
      // eslint-disable-next-line no-underscore-dangle
      let _a;
      const { html, selection } = editor;
      html.cleanEmptyTags();
      const selectedText = selection.text();
      const selectedElement = selection.element();
      if (selectedText) {
        const template = `
          <blockquote class="${className}">
            <p>${selectedText}</p>
          </blockquote>
        `;
        html.insert(template);
      } else {
        const blockquoteElement = document.createElement('blockquote');
        blockquoteElement.className = className;
        blockquoteElement.innerHTML = `<p>${selectedElement.innerText}</p>`;
        (_a = selectedElement.parentNode) === null || _a === void 0
          ? void 0
          : _a.insertBefore(blockquoteElement, selectedElement);
        selectedElement.remove();
      }
    },
    unquote: () => {
      const { html, selection } = editor;
      html.cleanEmptyTags();
      const selectedElement = selection.element();
      const { parentElement } = selectedElement;
      if (parentElement == null) return;
      if (parentElement.tagName === 'BLOCKQUOTE') {
        const { firstElementChild, parentNode } = parentElement;
        if (firstElementChild && parentNode) {
          parentNode.replaceChild(firstElementChild, parentElement);
        }
      }
    },
  };
}
Object.assign(POPUP_TEMPLATES, { 'quote.popup': '[_BUTTONS_]' });
PLUGINS.quote = Quote;
