/* eslint-disable no-param-reassign */
import React, { useRef, useContext, useCallback, useState } from 'react';
import moment from 'moment';
import LinesEllipsis from 'react-lines-ellipsis';
import qs from 'qs';
import { useNavigate } from 'react-router-dom';
import { styled, Box, useTheme, useMediaQuery } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Autoplay } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import 'swiper/components/navigation/navigation.min.css';
import { homeContext, getfindBoard } from '../../lib/utils';
import { menuState } from '../../atoms';
import { ReactComponent as LeftClickIcon } from '../../assets/images/ic-leftClick.svg';
import { ReactComponent as RightClickIcon } from '../../assets/images/ic-rightClick.svg';
import { ReactComponent as RightArrowIcon } from '../../assets/images/ic-rightArrow.svg';
import { ReactComponent as GrayArrowcon } from '../../assets/images/ic-arrow-right-gray.svg';

const Event = () => {
  const { board } = useContext(homeContext);
  const [currentStep, setCurrentStep] = useState(1);
  const menuList = useRecoilValue(menuState);
  const navigate = useNavigate();
  SwiperCore.use([Navigation, Autoplay]); // swiper 기능 임포트
  const navigationPrevRef = useRef(null); // swiper 커스텀 네비버튼
  const navigationNextRef = useRef(null); // swiper 커스텀 네비버튼
  const theme = useTheme();
  const isDeskTop = useMediaQuery(theme.breakpoints.up('desktop'));

  // console.log('board : ', board.event);

  // 네비 바의 움직여야 할 퍼센티지 계산
  const movingPoint = useCallback(
    currentIndex => {
      let movingRatio;
      if (currentIndex === 1 || currentIndex === board.event.lists.length + 1) {
        movingRatio = 0;
      } else if (currentIndex === 0) {
        movingRatio = `${
          (100 / board.event.lists.length) * (board.event.lists.length - 1)
        }`;
      } else {
        movingRatio = `${
          (100 / board.event.lists.length) * (currentIndex - 1)
        }`;
      }
      return movingRatio;
    },
    [currentStep, board.event.lists.length],
  );

  // 이벤트 게시판으로 이동 by '더보기'
  const onToEventBoard = () => {
    const currentMenu = getfindBoard(menuList, board.event.board_idx);
    navigate(`/menu/${currentMenu.menu_idx}`);
  };

  // 이벤트 상세내용으로 이동
  const onToEventBoardDetail = useCallback(
    (listIdx, listRownum) => {
      const data = {
        rownum: listRownum,
        search_type: null,
        search_text: null,
        category1_idx: null,
        category2_idx: null,
        board_id: board.event.board_idx,
      };
      const query = qs.stringify(data);
      navigate(`/board/${board.event.board_type}/${listIdx}?${query}`);
    },
    [board],
  );

  return (
    <Container
      sx={{
        mb: isDeskTop ? '60px' : '40px',
        px: isDeskTop ? 0 : '8px',
      }}
    >
      <InnerBox>
        <LeftClickBtn
          type="button"
          ref={navigationPrevRef}
          sx={{
            display:
              isDeskTop && board.event.lists.length >= 5 ? 'block' : 'none',
          }}
        >
          <LeftClickIcon />
        </LeftClickBtn>
        <RightClickBtn
          type="button"
          ref={navigationNextRef}
          sx={{
            display:
              isDeskTop && board.event.lists.length >= 5 ? 'block' : 'none',
          }}
        >
          <RightClickIcon />
        </RightClickBtn>
        <Inner
          sx={{
            maxWidth: isDeskTop ? '1280px' : '100%',
            minWidth: isDeskTop ? '1090px' : '100%',
          }}
        >
          <TitleBox sx={{ justifyContent: 'space-between', pb: '10px' }}>
            <Title
              sx={{
                fontSize: isDeskTop ? '24px' : '18px',
                mb: '5px',
              }}
            >
              이벤트·혜택
            </Title>
            <FlexBox onClick={onToEventBoard} sx={{ cursor: 'pointer' }}>
              <MoreViewText>더보기</MoreViewText>
              <RightArrowIcon />
            </FlexBox>
          </TitleBox>
          {/* 빈배열 일때, 데스크탑 && 모바일 */}
          {board.event.lists.length < 1 && (
            <EmptyBox
              sx={{
                display: board.event.lists.length >= 4 ? 'none' : 'flex',
                height: isDeskTop ? '311px' : '160px',
                fontSize: isDeskTop ? '20px' : '16px',
              }}
            >
              메이커님을 위한
              <br />
              많은 혜택을 만나보세요
            </EmptyBox>
          )}
          {/* 게시물 1개 이고, 모바일 */}
          {board.event.lists.length >= 1 &&
            board.event.lists.length < 2 &&
            isDeskTop === false && (
              <ItemBox
                sx={{
                  width: '100%',
                  height: 'auto',
                }}
              >
                <ImageBox
                  sx={{
                    backgroundImage: `url(${board?.event?.lists[0]?.image_url})`,
                    maxWidth: '100%',
                    height: '174px',
                  }}
                  onClick={() => {
                    onToEventBoardDetail(
                      board.event.lists[0].idx,
                      board.event.lists[0].rownum,
                    );
                  }}
                />
                <CategoryBox>
                  <FlexBox>
                    <Box>{board.event.lists[0].category1_title}</Box>
                    <Box
                      sx={{
                        mx: '8px',
                        display:
                          board.event.lists[0].category2_title === null ||
                          board.event.lists[0].category1_title === null
                            ? 'none'
                            : 'block',
                      }}
                    >
                      <GrayArrowcon />
                    </Box>
                    <Box>{board.event.lists[0].category2_title}</Box>
                  </FlexBox>
                  <Box sx={{ mb: '10px' }}>
                    {board.event.lists[0].important_yn === 'Y' && (
                      <Importance>중요</Importance>
                    )}
                    {board.event.lists[0].event_yn === 'before' && (
                      <Before>진행예정</Before>
                    )}
                    {board.event.lists[0].event_yn === 'ing' && (
                      <Ing>진행중</Ing>
                    )}
                    {board.event.lists[0].event_yn === 'after' && (
                      <After>종료</After>
                    )}
                  </Box>
                </CategoryBox>
                <EventTitle
                  sx={{ width: '92vw' }}
                  onClick={() => {
                    onToEventBoardDetail(
                      board.event.lists[0].idx,
                      board.event.lists[0].rownum,
                    );
                  }}
                >
                  {board.event.lists[0]?.title === null ? (
                    ''
                  ) : (
                    <LinesEllipsis
                      text={board.event.lists[0]?.title ?? ''}
                      maxLine="2"
                      ellipsis="..."
                      trimRight
                      basedOn="letters"
                    />
                  )}
                </EventTitle>
                <Summary
                  sx={{
                    width: '92vw',
                    height: board.event.lists[0].summary === null ? 0 : 'auto',
                    maxHeight: '35px',
                  }}
                  onClick={() => {
                    onToEventBoardDetail(
                      board.event.lists[0].idx,
                      board.event.lists[0].rownum,
                    );
                  }}
                >
                  {board?.event?.lists[0] === null ? (
                    ''
                  ) : (
                    <LinesEllipsis
                      text={board?.event?.lists[0]?.summary ?? ''}
                      maxLine="2"
                      ellipsis="..."
                      trimRight
                      basedOn="letters"
                    />
                  )}
                </Summary>
                <Date
                  sx={{
                    color:
                      board.event.lists[0].event_yn === 'before'
                        ? '#868E96'
                        : '#212529',
                  }}
                >
                  {moment(board.event.lists[0].start_date).format('YYYY.MM.DD')}
                  &nbsp;~&nbsp;
                  {moment(board.event.lists[0].end_date).format('YYYY.MM.DD')}
                </Date>
              </ItemBox>
            )}
          {/* 1~3개의 게시물이 있고, 데스크탑 일 때 */}
          {isDeskTop &&
            board.event.lists.length >= 1 &&
            board.event.lists.length < 4 && (
              <Box sx={{ display: 'flex' }}>
                {board.event.lists.map((brd, idx) => {
                  return (
                    <ItemBox
                      sx={{
                        width: '305px',
                        mr: '24px',
                        order: idx + 1,
                      }}
                    >
                      <ImageBox
                        sx={{
                          backgroundImage: `url(${brd.image_url})`,
                          maxWidth: '305px',
                          height: '160px',
                        }}
                        onClick={() => {
                          onToEventBoardDetail(brd.idx, brd.rownum);
                        }}
                      />
                      <CategoryBox>
                        <FlexBox>
                          <Box>{brd.category1_title}</Box>
                          <Box
                            sx={{
                              mx: '8px',
                              display:
                                brd.category2_title === null ||
                                brd.category1_title === null
                                  ? 'none'
                                  : 'block',
                            }}
                          >
                            <GrayArrowcon />
                          </Box>
                          <Box>{brd.category2_title}</Box>
                        </FlexBox>
                        <Box>
                          {brd.important_yn === 'Y' && (
                            <Importance>중요</Importance>
                          )}
                          {brd.event_yn === 'before' && (
                            <Before>진행예정</Before>
                          )}
                          {brd.event_yn === 'ing' && <Ing>진행중</Ing>}
                          {brd.event_yn === 'after' && <After>종료</After>}
                        </Box>
                      </CategoryBox>
                      <EventTitle
                        sx={{ width: '300px' }}
                        onClick={() => {
                          onToEventBoardDetail(brd.idx, brd.rownum);
                        }}
                      >
                        {brd?.title === null ? (
                          ''
                        ) : (
                          <LinesEllipsis
                            text={brd?.title ?? ''}
                            maxLine="2"
                            ellipsis="..."
                            trimRight
                            basedOn="letters"
                          />
                        )}
                      </EventTitle>
                      <Summary
                        sx={{
                          width: '300px',
                          height: brd.summary === null ? 0 : 'auto',
                          maxHeight: '35px',
                        }}
                        onClick={() => {
                          onToEventBoardDetail(brd.idx, brd.rownum);
                        }}
                      >
                        {brd?.summary === null ? (
                          ''
                        ) : (
                          <LinesEllipsis
                            text={brd?.summary ?? ''}
                            maxLine="2"
                            ellipsis="..."
                            trimRight
                            basedOn="letters"
                          />
                        )}
                      </Summary>
                      <Date
                        sx={{
                          color:
                            brd.event_yn === 'before' ? '#868E96' : '#212529',
                        }}
                      >
                        {moment(brd.start_date).format('YYYY.MM.DD')}
                        &nbsp;~&nbsp;
                        {moment(brd.end_date).format('YYYY.MM.DD')}
                      </Date>
                    </ItemBox>
                  );
                })}
                <EmptyBox
                  sx={{
                    display: board.event.lists.length >= 4 ? 'none' : 'flex',
                    fontSize: '20px',
                    order: board.event.lists.length + 1,
                  }}
                >
                  메이커님을 위한
                  <br />
                  많은 혜택을 만나보세요
                </EmptyBox>
              </Box>
            )}
          {/* 게시물 2개 이상인 모바일 || 게시물 4개 이상인 데스크탑 */}
          {((board.event.lists.length >= 2 && isDeskTop === false) ||
            board.event.lists.length >= 4) && (
            <Swiper
              className="mySwiper"
              modules={[Navigation, Autoplay]}
              spaceBetween={24}
              slidesPerView={isDeskTop ? 4 : 1}
              loop
              navigation={{
                prevEl: navigationPrevRef.current,
                nextEl: navigationNextRef.current,
              }}
              onBeforeInit={swiper => {
                swiper.params.navigation.prevEl = navigationPrevRef.current;
                swiper.params.navigation.nextEl = navigationNextRef.current;
              }}
              // autoplay={{
              //   delay: 5000,
              //   disableOnInteraction: false,
              // }}
              onSlideChange={swiper => setCurrentStep(swiper.activeIndex)}
            >
              {board.event.lists &&
                board.event.lists.map(brd => {
                  return (
                    <SwiperSlide key={`home-event-${brd.idx}`}>
                      <ItemBox
                        sx={{
                          height: isDeskTop ? '311px' : '322px',
                        }}
                      >
                        <ImageBox
                          sx={{
                            backgroundImage: `url(${brd.image_url})`,
                            maxWidth: isDeskTop ? '305px' : '100%',
                            height: isDeskTop ? '160px' : '174px',
                          }}
                          onClick={() => {
                            onToEventBoardDetail(brd.idx, brd.rownum);
                          }}
                        />
                        <CategoryBox>
                          <FlexBox>
                            <Box>{brd.category1_title}</Box>
                            <Box
                              sx={{
                                mx: '8px',
                                display:
                                  brd.category2_title === null ||
                                  brd.category1_title === null
                                    ? 'none'
                                    : 'block',
                              }}
                            >
                              <GrayArrowcon />
                            </Box>
                            <Box>{brd.category2_title}</Box>
                          </FlexBox>
                          <Box>
                            {brd.important_yn === 'Y' && (
                              <Importance>중요</Importance>
                            )}
                            {brd.event_yn === 'before' && (
                              <Before>진행예정</Before>
                            )}
                            {brd.event_yn === 'ing' && <Ing>진행중</Ing>}
                            {brd.event_yn === 'after' && <After>종료</After>}
                          </Box>
                        </CategoryBox>
                        <EventTitle
                          sx={{
                            width: isDeskTop ? '300px' : '92vw',
                          }}
                          onClick={() => {
                            onToEventBoardDetail(brd.idx, brd.rownum);
                          }}
                        >
                          {brd?.title === null ? (
                            ''
                          ) : (
                            <LinesEllipsis
                              text={brd?.title ?? ''}
                              maxLine="2"
                              ellipsis="..."
                              trimRight
                              basedOn="letters"
                            />
                          )}
                        </EventTitle>
                        <Summary
                          sx={{
                            width: isDeskTop ? '300px' : '92vw',
                            height: brd.summary === null ? 0 : 'auto',
                            maxHeight: '35px',
                          }}
                          onClick={() => {
                            onToEventBoardDetail(brd.idx, brd.rownum);
                          }}
                        >
                          {brd?.summary === null ? (
                            ''
                          ) : (
                            <LinesEllipsis
                              text={brd?.summary ?? ''}
                              maxLine="2"
                              ellipsis="..."
                              trimRight
                              basedOn="letters"
                            />
                          )}
                        </Summary>
                        <Date
                          sx={{
                            color:
                              brd.event_yn === 'before' ? '#868E96' : '#212529',
                          }}
                        >
                          {moment(brd.start_date).format('YYYY.MM.DD')}
                          &nbsp;~&nbsp;
                          {moment(brd.end_date).format('YYYY.MM.DD')}
                        </Date>
                      </ItemBox>
                    </SwiperSlide>
                  );
                })}
            </Swiper>
          )}
        </Inner>
      </InnerBox>
      <Box
        sx={{
          display:
            isDeskTop || board.event.lists.length <= 1 ? 'none' : 'block',
        }}
      >
        <NaviBarContainer>
          {/* 바를 직접 클릭하여 이동할 투명바 설정 */}
          <NaviBackground />
          <MovingBar
            sx={{
              width: `${100 / board.event.lists.length}%`, // 자동너비
              left: `${movingPoint(currentStep)}%`, // 이동
            }}
          />
        </NaviBarContainer>
      </Box>
    </Container>
  );
};

const Container = styled(Box)({
  // border: '1px solid dodgerblue',
  position: 'relative',
  width: '100%',
  height: '100%',
});
const Inner = styled(Box)({
  // border: '1px solid red',
  position: 'relative',
  margin: '0 auto',
});
const InnerBox = styled(Box)({
  // border: '1px solid blue',
  maxWidth: '1420px',
  width: '98%', // 네비게이터 배치 용도, 최상위 부모 너비를 따름
  position: 'relative',
  margin: '0 auto 20px',
});
const FlexBox = styled(Box)({
  // border: '1px solid powderblue',
  position: 'relative',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  // cursor: 'pointer',
});
const EmptyBox = styled(Box)({
  // border: '1px solid red',
  flexGrow: 1,
  background: '#F9FAFB',
  justifyContent: 'center',
  alignItems: 'center',
  color: '#868E96',
  fontWeight: 700,
  // border: 0,
  borderRadius: '10px',
  textAlign: 'center',
});
const ItemBox = styled(Box)({
  // border: '1px solid red',
  position: 'relative',
});
const ImageBox = styled(Box)({
  marginBottom: '10px',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center center',
  backgroundSize: '100%',
  // backgroundSize: 'cover',
  border: 0,
  borderRadius: '10px',
  transition: 'all 0.2s ease-in-out',
  cursor: 'pointer',
  '&:hover': {
    backgroundSize: '120%',
  },
});
const CategoryBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: '3px',
  color: '#495057',
  fontSize: '13px',
  fontWeight: 400,
  lineHeight: '18px',
});
const TitleBox = styled(Box)({
  // border: '1px solid powderblue',
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  lineHeight: '24px',
});
const Title = styled(Box)({
  fontSize: '24px',
  fontWeight: 700,
  color: '#212529',
  lineHeight: '34px',
});
const MoreViewText = styled(Box)({
  // border: '1px solid red',
  marginRight: '8px',
  color: '#000',
  fontSize: '14px',
  fontWeight: 400,
  display: 'flex',
  alignItems: 'center',
});

const LeftClickBtn = styled('button')({
  position: 'absolute',
  top: '40%',
  left: '5px',
  transform: 'translate(0, -50%)',
  zIndex: 2,
  padding: 0,
  border: 0,
  borderRadius: '50%',
  background: 'transparent',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
});
const RightClickBtn = styled('button')({
  position: 'absolute',
  top: '40%',
  right: '5px',
  transform: 'translate(0, -50%)',
  zIndex: 2,
  padding: 0,
  border: 0,
  borderRadius: '50%',
  background: 'transparent',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
});
const Importance = styled(Box)({
  display: 'inline-block',
  height: '18px',
  padding: '0 4px',
  marginRight: '4px',
  border: 0,
  borderRadius: '3px',
  color: '#fff',
  backgroundColor: '#00C4C4',
  fontSize: '10px',
  fontWeight: 700,
});
const Before = styled(Box)({
  display: 'inline-block',
  height: '18px',
  padding: '0 4px',
  border: 0,
  borderRadius: '3px',
  color: '#00B2B2',
  backgroundColor: '#E7F9F9',
  fontSize: '10px',
  fontWeight: 700,
});
const Ing = styled(Box)({
  display: 'inline-block',
  height: '18px',
  padding: '0 4px',
  border: 0,
  borderRadius: '3px',
  color: '#fff',
  backgroundColor: '#557CF2',
  fontSize: '10px',
  fontWeight: 700,
});
const After = styled(Box)({
  display: 'inline-block',
  height: '18px',
  padding: '0 4px',
  border: 0,
  borderRadius: '3px',
  color: '#fff',
  backgroundColor: '#868e96',
  fontSize: '10px',
  fontWeight: 700,
});
const EventTitle = styled(Box)({
  // border: '1px solid red',
  color: '#212529',
  fontSize: '16px',
  fontWeight: 500,
  cursor: 'pointer',
  marginBottom: '8px',
});
const Summary = styled(Box)({
  // border: '1px solid red',
  color: '#90949C',
  fontSize: '13px',
  fontWeight: 400,
  lineHeight: '16px',
  marginBottom: '8px',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  cursor: 'pointer',
});
const Date = styled(Box)({
  color: '#212529',
  fontSize: '14px',
  fontWeight: 400,
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  lineHeight: '16px',
});
const NaviBarContainer = styled('div')({
  width: '100%', // 바의 전체 너비 결정
  height: '3px', // 바의 전체 높이 결정
  position: 'relative', // 포지션 부모 설정
  backgroundColor: 'rgba(0, 0, 0, .1)',
  border: 0,
  boxSizing: 'border-box',
});
const MovingBar = styled('div')({
  position: 'absolute', // 중요!
  top: '0', // 중요
  transition: '.2s ease-in-out',
  height: '100%', // width는 인라인에서 설정
  backgroundColor: '#000', // 색깔
});
const NaviBackground = styled('div')({
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  border: 0,
  boxSizing: 'border-box',
  backgroundColor: 'rgba(0), 0, 0, .3)',
});

export default Event;

// const Box = styled(motion.div)({
//   width: '100px',
//   height: '100px',
//   backgroundColor: 'red',
// }); <Box whileHover={{ scale: 1.2 }} />

// const board = {
//   event: {
//     lists: [
//       {
//         category1_title: '일반 머리말',
//         category2_title: null,
//         contents: '내용을 입력하면 됩니다~',
//         end_date: '2022-12-31',
//         event_yn: 'after',
//         idx: 11,
//         image_url:
//           'https://dev-static.wadiz.bnbcompany.io/board/attachment/727b8a3f-8ad2-425a-9ad9-e2faf74b3653.png',
//         important_yn: 'Y',
//         reg_date: '2022-11-02 13:38:08',
//         rownum: 10,
//         start_date: '2022-11-01',
//         summary: '테스트 요약!!',
//         title: '1 이벤트 테스트입니다.',
//       },
//       // {
//       //   category1_title: '일반 머리말',
//       //   category2_title: null,
//       //   contents: '내용을 입력하면 됩니다~',
//       //   end_date: '2022-12-31',
//       //   event_yn: 'after',
//       //   idx: 12,
//       //   image_url:
//       //     'https://dev-static.wadiz.bnbcompany.io/board/attachment/727b8a3f-8ad2-425a-9ad9-e2faf74b3653.png',
//       //   important_yn: 'Y',
//       //   reg_date: '2022-11-02 13:38:08',
//       //   rownum: 10,
//       //   start_date: '2022-11-01',
//       //   summary: '테스트 요약!!',
//       //   title: '2 이벤트 테스트입니다.',
//       // },
//     ],
//   },
// };
