import { React, useState } from 'react';
import { styled } from '@mui/system';
import {
  Box,
  Typography,
  ListItemButton,
  ListItemText,
  Collapse,
  List,
} from '@mui/material';
import PropTypes from 'prop-types';
import { ReactComponent as UpIcon } from '../../assets/images/m-arrow-up.svg';
import { ReactComponent as DownIcon } from '../../assets/images/m-arrow-down.svg';
import { ReactComponent as LinkIcon } from '../../assets/images/ArrowSquareOut.svg';
import Footer from './Footer';
import SNSBox from './FooterMenuBox/SNSBox';

const MenuStyle = styled(ListItemButton)(() => ({
  alignItems: 'center',
  padding: '0px',
  height: '56px',
  borderBottom: 'solid 1px #E6EAED',
}));
const OneMenu = ({ menu_title, children }) => {
  const [open, setopen] = useState(false);
  const handleClick = () => {
    setopen(!open);
  };
  const textstyle = {
    fontStyle: 'normal',
    color: '#212529',
    fontSize: '17px',
    letterSpacing: '-0.3px',
    fontWeight: 400,
    flexGrow: 1,
  };
  return (
    <Box>
      <MenuStyle onClick={handleClick}>
        <ListItemText primary={menu_title} sx={textstyle} />
        {open ? <UpIcon /> : <DownIcon />}
      </MenuStyle>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {children}
      </Collapse>
    </Box>
  );
};

OneMenu.propTypes = {
  menu_title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

OneMenu.deafultProps = {
  children: null,
};

const TermsText = styled(Typography)(() => ({
  fontSize: '15px',
  lineHieght: '24px',
  marginRight: '5px',
}));
const TermsBoldText = styled(Typography)(() => ({
  fontSize: '15px',
  lineHieght: '24px',
  marginRight: '5px',
}));
const PolicyBox = () => {
  return (
    <Box sx={{ background: '#F5F7FA' }}>
      <MenuStyle
        onClick={() => {
          window.open('https://www.wadiz.kr/web/wterms/signup', '_blank');
        }}
      >
        <TermsText>공통 정책·약관</TermsText>
        <LinkIcon />
      </MenuStyle>
      <MenuStyle
        onClick={() => {
          window.open(
            'https://www.wadiz.kr/web/wterms/service_reward',
            '_blank',
          );
        }}
      >
        <TermsText>와디즈 정책·약관</TermsText>
        <LinkIcon />
      </MenuStyle>
      <MenuStyle
        onClick={() => {
          window.open(
            'https://www.wadiz.kr/web/wterms/service_invest',
            '_blank',
          );
        }}
      >
        <TermsText>와디즈파이낸스 정책·약관</TermsText>
        <LinkIcon />
      </MenuStyle>
    </Box>
  );
};
const PrivacyPolicy = () => {
  return (
    <Box sx={{ background: '#F5F7FA' }}>
      <MenuStyle
        onClick={() => {
          window.open('https://www.wadiz.kr/web/wterms/privacy', '_blank');
        }}
      >
        <TermsBoldText>와디즈 개인정보 처리방침</TermsBoldText>
        <LinkIcon />
      </MenuStyle>
      <MenuStyle
        onClick={() => {
          window.open(
            'https://www.wadiz.kr/web/wterms/privacy_invest',
            '_blank',
          );
        }}
      >
        <TermsBoldText>와디즈파이낸스 개인정보 처리방침</TermsBoldText>
        <LinkIcon />
      </MenuStyle>
    </Box>
  );
};

const footermenu = [
  { title: '정책·약관', children: <PolicyBox />, bold: false },
  { title: '개인정보처리방침', children: <PrivacyPolicy />, bold: true },
  { title: 'SNS', children: <SNSBox sideOn />, bold: false },
];

const TitleFont = styled(Typography)(() => ({
  fontWeight: '500',
  fontSize: '13px',
  lineHeight: '24px',
  display: 'flex',
  alignItems: 'center',
  letterSpacing: '-0.3px',
  marginLeft: '16px',
  color: '#868E96',
}));

const SideFooter = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '24px 0px ',
  maxHeight: '1000px',
  background: '#F5F7FA',
}));

const SideBarFooter = () => {
  return (
    <SideFooter>
      <TitleFont>정책 약관</TitleFont>
      <List sx={{ marginX: '16px' }}>
        {footermenu.map(m => {
          return (
            <OneMenu key={m.title} menu_title={m.title}>
              {m.children}
            </OneMenu>
          );
        })}
      </List>
      <Footer sideOn />
    </SideFooter>
  );
};

export default SideBarFooter;
