import React, { useRef, useCallback, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import {
  styled,
  Box,
  IconButton,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { homeApi } from '../api/home';
import { searchWordGlobal } from '../atoms';
import { ReactComponent as SearchIcon } from '../assets/images/m-search.svg';
import { ReactComponent as ConditionIcon } from '../assets/images/ic-condition.svg';
import { ReactComponent as DownArrowIcon } from '../assets/images/m-arrow-down.svg';
import { ReactComponent as CancelIcon } from '../assets/images/ic_cancel_black.svg';
import { SetLocalStorageSearchWord } from '../lib/utils';
import ConditionalSearch from '../components/search/ConditionalSearch';
import SearchResultItem from '../components/search/SearchResultItem';
import Loading from '../components/common/Loading';

const Search = () => {
  const { searchText } = useParams();
  const inputRef = useRef(null);
  const [searchWord, setSearchWordSet] = useRecoilState(searchWordGlobal);
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [searchCondition, setSearchCondition] = useState({
    sort: null,
    search_start_date: null,
    search_end_date: null,
    search_area: null,
  });
  const [periodDefalut, setPeriodDefault] = useState('whole'); // '기간'defaultValue용
  const [period, setPeriod] = useState({
    search_start_date: undefined,
    search_end_date: undefined,
  });
  const [totalCount, setTotalCount] = useState(0);
  const [searchRes, setSearchRes] = useState([]);
  const [keywords, setKeywords] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);
  // const [searchResOffset, setSearchResOffset] = useState(0);
  const limit = 10;
  const [renderingData, setRenderingData] = useState([]); // tab선택 연동되어 검색결과 limit개씩 분배
  const theme = useTheme();
  const isDeskTop = useMediaQuery(theme.breakpoints.up('desktop'));
  const navigate = useNavigate();

  // console.log('renderingData :', renderingData);

  // 검색어 진입시, 전역변수와 로컬스토리지와 inputRef에 저장
  useEffect(() => {
    if (searchText.length >= 1) {
      SetLocalStorageSearchWord(searchText);
      inputRef.current.value = searchText;
      setSearchWordSet(searchText);
    }
  }, [searchText]);

  // 현 페이지에서 검색어 입력후 엔터시 이벤트
  const onSearchEnter = useCallback(() => {
    if (
      inputRef.current.value !== null &&
      inputRef.current.value.length >= 1 &&
      inputRef.current.value !== '%'
    ) {
      SetLocalStorageSearchWord(inputRef.current.value);
      setSearchWordSet(inputRef?.current?.value);
      (async () => {
        try {
          setLoading(true);
          const apiParams = {
            search_text: inputRef?.current?.value,
            ...searchCondition,
          };
          const { data } = await homeApi.getSearchData(apiParams);
          setTotalCount(data.data[0].total_count);
          setSearchRes(data.data);
          setTabIndex(0);
          setRenderingData([]);
          setLoading(false);
        } catch (error) {
          // error 가 날경우 검색한 결과가 없는걸로
          setTotalCount(0);
          setSearchRes([]);
          setTabIndex(0);
          setRenderingData([]);
          setKeywords([]);
          setLoading(false);
        } finally {
          inputRef.current.focus();
        }
      })();
    }
  }, [inputRef, searchCondition]);
  useEffect(() => {
    onSearchEnter();
  }, [onSearchEnter, searchText]);

  // 검색 후 첫 렌더링과 tab 클릭에 따른 데이터 새롭게 리스트업
  useEffect(() => {
    if (searchRes.length >= 1) {
      setKeywords(searchRes[tabIndex].keywords);
      setRenderingData([...searchRes[tabIndex].lists.slice(0, limit)]);
      window.scrollTo(0, 0);
    }
  }, [searchRes, tabIndex]);

  // 더보기 클릭시 데이터 업데이트
  const onMoreView = () => {
    if (searchRes.length >= 1) {
      setRenderingData(current => {
        const copiedArr = current.slice();
        const finalArr = [
          ...searchRes[tabIndex].lists.slice(0, copiedArr.length + limit),
        ];
        return finalArr;
      });
    }
  };

  // 해시태그 클릭시 검색유발하는 이벤트
  const onSearchByHashtag = tag => {
    navigate(`/search/${encodeURIComponent(tag)}`);
  };

  // Tab 타이틀 폰트 가변적으로 설정
  const makeDEFontSize = () => {
    let fontSize;
    if (isDeskTop) {
      if (searchRes.length <= 4) {
        fontSize = '16px';
      } else if (searchRes.length >= 5 && searchRes.length < 6) {
        fontSize = '14px';
      } else if (searchRes.length >= 6) {
        fontSize = '12px';
      }
    }
    return fontSize;
  };

  const makeMoFontSize = () => {
    let fontSize;
    if (!isDeskTop) {
      if (searchRes.length <= 4) {
        fontSize = '12px';
      } else if (searchRes.length >= 5 && searchRes.length < 6) {
        fontSize = '10px';
      } else if (searchRes.length >= 6) {
        fontSize = '9px';
      }
    }
    return fontSize;
  };

  // scroll top에 위치하도록
  useEffect(() => {
    if (renderingData.length < 1) {
      window.scrollTo(0, 0);
    }
  }, [searchText, loading, renderingData]);

  return (
    <Container>
      <SearchInner>
        <FlexBox
          sx={{
            mt: isDeskTop ? '80px' : '32px',
            mb: '40px',
            alignItems: 'flex-end',
          }}
        >
          <Box sx={{ position: 'relative', mr: isDeskTop ? '42px' : '15px' }}>
            <InputField
              ref={inputRef}
              defaultValue={searchText}
              placeholder="검색어를 입력해 주세요"
              onKeyPress={e => {
                if (
                  e.key === 'Enter' &&
                  inputRef.current.value !== null &&
                  inputRef.current.value.length >= 1 &&
                  inputRef.current.value !== '%'
                )
                  navigate(
                    `/search/${encodeURIComponent(inputRef.current.value)}`,
                  );
              }}
              sx={{
                width: isDeskTop ? '720px' : '272px',
                height: isDeskTop ? '64px' : '50px',
                padding: isDeskTop ? '18px 90px 18px 0' : '9px 70px 9px 0',
              }}
            />
            <CancelIconBox
              sx={{ right: isDeskTop ? 50 : 40 }}
              onClick={() => {
                inputRef.current.value = null;
              }}
            >
              {isDeskTop ? (
                <CancelIcon width={22} height={22} />
              ) : (
                <CancelIcon width={17} height={17} />
              )}
            </CancelIconBox>
            <SearchIconBox
              onClick={() => {
                if (
                  inputRef.current.value !== null &&
                  inputRef.current.value.length >= 1 &&
                  inputRef.current.value !== '%'
                ) {
                  navigate(
                    `/search/${encodeURIComponent(inputRef.current.value)}`,
                  );
                }
              }}
            >
              {isDeskTop ? (
                <SearchIcon width={26} height={26} />
              ) : (
                <SearchIcon width={19} height={19} />
              )}
            </SearchIconBox>
          </Box>
          <Box>
            <CustomButton sx={{ width: isDeskTop ? '105px' : '40px' }}>
              <IconButton onClick={() => setModal(true)} sx={{ width: '120%' }}>
                <SpanText>
                  <Box sx={{ display: isDeskTop ? 'block' : 'none' }}>
                    상세검색&nbsp;
                  </Box>
                  <ConditionIcon />
                </SpanText>
              </IconButton>
            </CustomButton>
            <ConditionalSearch
              modal={modal}
              setModal={setModal}
              searchCondition={searchCondition}
              setSearchCondition={setSearchCondition}
              periodDefalut={periodDefalut}
              setPeriodDefault={setPeriodDefault}
              period={period}
              setPeriod={setPeriod}
            />
          </Box>
        </FlexBox>
        {loading ? (
          <Loading />
        ) : (
          <>
            {totalCount !== 0 && (
              <TextBox
                sx={{ mb: '40px', fontSize: isDeskTop ? '28px' : '20px' }}
              >
                <Box sx={{ display: 'inline' }}>
                  &apos;
                  <Span sx={{ color: '#00B2B2', fontWeight: 700 }}>
                    {searchWord}
                  </Span>
                  &apos;
                </Box>
                <Box sx={{ display: 'inline' }}>(으)로 </Box>
                {isDeskTop ? null : <br />}
                <Box sx={{ display: 'inline' }}>
                  검색한 결과&nbsp;
                  <strong>{totalCount}</strong>
                  건입니다.
                </Box>
              </TextBox>
            )}
            {totalCount === 0 ? (
              <Box sx={{ py: isDeskTop ? '176px' : '100px' }}>
                <TextBox
                  sx={{ fontSize: isDeskTop ? '28px' : '20px', mb: '16px' }}
                >
                  <Box sx={{ display: 'inline' }}>
                    &apos;
                    <Span sx={{ color: '#00B2B2', fontWeight: 700 }}>
                      {searchWord}
                    </Span>
                    &apos;
                  </Box>
                  <Box sx={{ display: 'inline' }}>(으)로 </Box>
                  {isDeskTop ? null : <br />}
                  <Box sx={{ display: 'inline' }}>검색한 결과 없습니다.</Box>
                </TextBox>
                <NoResGuide>
                  단어의 철자가 정확한지 확인해 보세요.
                  <br /> 검색어의 단어 수를 줄이거나,{' '}
                  {isDeskTop ? null : <br />}보다 일반적인 검색어로 다시 검색해
                  보세요.
                </NoResGuide>
              </Box>
            ) : (
              <>
                <TabsBox
                  sx={{
                    fontSize: isDeskTop ? makeDEFontSize() : makeMoFontSize(),
                    margin: isDeskTop ? '0 0 48px 0' : '0 0 28px 0',
                  }}
                >
                  {searchRes.map((board, idx) => {
                    return (
                      <Tab
                        key={`tab-${board.title}-${idx.toString()}`}
                        sx={{
                          width: `${100 * (1 / searchRes.length)}%`,
                          color: tabIndex === idx && '#000',
                          borderColor: tabIndex === idx && '#000',
                          borderBottomColor: tabIndex === idx && '#fff',
                          borderLeftColor:
                            !isDeskTop && tabIndex === 0 && '#fff',
                          borderRightColor:
                            !isDeskTop &&
                            tabIndex === searchRes.length - 1 &&
                            '#fff',
                        }}
                        onClick={() => {
                          setTabIndex(idx);
                        }}
                      >
                        {board.title}&nbsp;
                        {isDeskTop ? searchRes[idx].total_count : null}
                      </Tab>
                    );
                  })}
                </TabsBox>
                <ResultBox sx={{ mb: '40px' }}>
                  {renderingData.length < 1 ? (
                    <EmptyBox sx={{ fontSize: isDeskTop ? '16px' : '14px' }}>
                      해당 카테고리 내 검색결과가 없습니다.
                    </EmptyBox>
                  ) : (
                    renderingData.map((item, idx) => {
                      return (
                        <Box
                          key={`search-result-${item.idx}-${
                            item.title
                          }-${idx.toString()}`}
                        >
                          <SearchResultItem
                            searchText={searchWord}
                            idx={item.idx}
                            board_type={item.board_type}
                            board_title={item.board_title}
                            hashtag={item.hashtag}
                            start_date={item.start_date}
                            end_date={item.end_date}
                            reg_date={item.reg_date}
                            summary={item.summary}
                            title={item.title}
                            rownum={item.rownum}
                            onSearchByHashtag={onSearchByHashtag}
                            board_idx={item.board_idx}
                            keywords={keywords}
                          />
                        </Box>
                      );
                    })
                  )}
                </ResultBox>
                <FlexBox>
                  <CustomBtn
                    sx={{
                      display:
                        renderingData.length === searchRes[tabIndex].total_count
                          ? 'none'
                          : 'block',
                    }}
                    onClick={onMoreView}
                  >
                    <Span2 sx={{ fontSize: isDeskTop ? '16px' : '14px' }}>
                      더보기&nbsp;{renderingData.length}
                    </Span2>
                    <Span2
                      sx={{
                        color: '#ADB5BD',
                        fontSize: isDeskTop ? '16px' : '14px',
                      }}
                    >
                      &nbsp;/&nbsp;
                      {searchRes[tabIndex].total_count}&nbsp;&nbsp;
                    </Span2>
                    <DownArrowIcon />
                  </CustomBtn>
                </FlexBox>
              </>
            )}
          </>
        )}
      </SearchInner>
    </Container>
  );
};

const Container = styled(Box)({
  // border: '1px solid red',
  position: 'relative',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  marginBottom: '100px',
});
const FlexBox = styled(Box)({
  // border: '1px solid powderblue',
  position: 'relative',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});
const SearchInner = styled(Box)({
  // border: '1px solid blue',
  position: 'relative',
  width: '1280px',
  margin: '0 atuo',
});
const CancelIconBox = styled(IconButton)({
  padding: 0,
  position: 'absolute',
  right: 40,
  top: '48%',
  transform: 'translate(0, -50%)',
  zIndex: 3,
});
const SearchIconBox = styled(IconButton)({
  padding: 0,
  position: 'absolute',
  right: 5,
  top: '50%',
  transform: 'translate(0, -50%)',
  zIndex: 3,
});
const InputField = styled('input')({
  appearance: 'none',
  height: '64px',
  border: 0,
  borderBottom: '4px solid black',
  borderRadius: 0,
  backgroundColor: 'transparent',
  padding: '18px 0',
  color: '#212529',
  fontWeight: 500,
  fontSize: '18px',
  outline: 'none',
  '&::placeholder': {
    color: '#ADB5BD',
  },
  '&:focus': {
    // border: '3px solid #fff',
  },
});
const TextBox = styled(Box)({
  // border: '1px solid powderblue',
  position: 'relative',
  width: '100%',
  fontSize: '28px',
  fontWeight: 400,
  textAlign: 'center',
});
const Span = styled('span')({});
const Span2 = styled('span')({
  color: '#212529',
  fontSize: '16px',
  fontWeight: 400,
});
const CustomButton = styled('div')({
  height: '40px',
  cursor: 'pointer',
  overflow: 'hidden',
  backgroundColor: 'transparent',
  padding: 0,
  border: '1px solid #CDD3D8',
  borderRadius: '3px',
  '&:hover': {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    borderColor: '#CECECE',
  },
  '&:active': {
    boxShadow: 'none',
  },
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});
const SpanText = styled(Box)({
  fontSize: '14px',
  fontWeight: 500,
  color: '#616161',
  width: '100%',
  height: '40px',
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
});
const NoResGuide = styled(Box)({
  // border: '1px solid powderblue',
  position: 'relative',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  fontSize: '16px',
  fontWeight: 400,
  color: '#495057',
  lineHeight: '28px',
});
const TabsBox = styled(Box)({
  // border: '1px solid powderblue',
  position: 'relative',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '16px',
  fontWeight: 500,
  color: '#90949C',
});
const Tab = styled(Box)({
  // border: '1px solid powderblue',
  position: 'relative',
  height: '48px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  border: '1px solid #E6EAED',
  borderBottomColor: '#000',
  wordBreak: 'keep-all',
  whiteSpace: 'pre-line',
  textAlign: 'center',
  overflow: 'auto',
});
const ResultBox = styled(Box)({
  // border: '1px solid powderblue',
  position: 'relative',
  width: '100%',
});
const EmptyBox = styled(Box)({
  height: '100px',
  width: '100%',
  fontWeight: 400,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: '#212529',
});
const CustomBtn = styled('button')({
  // border: '1px solid powderblue',
  position: 'relative',
  width: '140px',
  backgroundColor: 'transparent',
  cursor: 'pointer',
  border: 0,
  display: 'flex',
  alignItems: 'center',
});

// const = styled(Box)({
//   border: '1px solid powderblue',
//   position: 'relative',
//   width: '100%',
// })

export default Search;
