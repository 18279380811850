import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { styled, Box } from '@mui/material';

const PopularWord = ({ popularWord, textColor, bgColor, setSearchOpen }) => {
  const navigate = useNavigate();

  // console.log('popularWord :', popularWord);

  const onToSearchPage = popWord => {
    navigate(`/search/${encodeURIComponent(popWord)}`);
    if (setSearchOpen !== undefined) {
      setSearchOpen(false);
    }
  };

  return (
    <FlexBox>
      {popularWord &&
        popularWord.map((word, idx) => {
          return (
            <Box key={`popularWord-${word.word}-${idx.toString()}`}>
              {word.word.length >= 9 ? (
                <WordBox
                  key={`popularWord-${word.word}-${idx.toString()}`}
                  sx={{
                    width: '140px',
                    whiteSpace: 'nowrap',
                    color: textColor,
                    backgroundColor: bgColor,
                  }}
                  onClick={() => onToSearchPage(word.word)}
                >
                  <Box sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
                    #{word.word}
                  </Box>
                </WordBox>
              ) : (
                <WordBox
                  key={`popularWord-${word.word}-${idx.toString()}`}
                  sx={{
                    color: textColor,
                    backgroundColor: bgColor,
                  }}
                  onClick={() => onToSearchPage(word.word)}
                >
                  #{word.word}
                </WordBox>
              )}
            </Box>
          );
        })}
    </FlexBox>
  );
};

PopularWord.defaultProps = {
  popularWord: null,
  bgColor: null,
  textColor: null,
  setSearchOpen: undefined,
};

PopularWord.propTypes = {
  popularWord: PropTypes.arrayOf(
    PropTypes.shape({
      word: PropTypes.string,
    }),
  ),
  bgColor: PropTypes.string,
  textColor: PropTypes.string,
  setSearchOpen: PropTypes.func,
};

const FlexBox = styled(Box)({
  // border: '1px solid red',
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  flexWrap: 'wrap',
});

const WordBox = styled(Box)({
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '6px 16px 6px 16px',
  margin: '0 8px 16px 0',
  border: 0,
  borderRadius: '20px',
  fontSize: '14px',
  fontWeight: 500,
  cursor: 'pointer',
});

export default PopularWord;
