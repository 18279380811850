import qs from 'qs';
import _ from 'lodash';
import axiosInstance from './axiosInstance';

export const homeApi = {
  bannerList: data => {
    return axiosInstance.get(`banner/user/lists`, {
      params: {
        data,
      },
      paramsSerializer: () => {
        return qs.stringify(data);
      },
    });
  },
  // (legacy!!!) 추천 검색어
  popularWordList: () => {
    return axiosInstance.get(`popular_word/user/lists`);
  },
  // 메인 데이터 조회 : 여기에 다 있음
  getMainData: data => {
    return axiosInstance.get(`main/data`, {
      params: {
        ...data,
      },
    });
  },
  // 검색
  getSearchData: data => {
    const sendData = _.omitBy(data, v => v === null || v === undefined);
    const query = new URLSearchParams(sendData).toString();
    return axiosInstance.get(`search?${query}`);
  },
  // 팝업
  getPopupData: () => {
    return axiosInstance.get(`popup/user/info`);
  },
};
