import React from 'react';
import PropTypes from 'prop-types';
import {
  styled,
  Box,
  SvgIcon,
  IconButton,
  useTheme,
  useMediaQuery,
} from '@mui/material';

const MoreIcon = ({ textColor }) => {
  return (
    <SvgIcon viewBox="0 0 17 16" style={{ width: 16, height: 16 }}>
      <path
        d="M8.42969 8.75C8.8439 8.75 9.17969 8.41421 9.17969 8C9.17969 7.58579 8.8439 7.25 8.42969 7.25C8.01547 7.25 7.67969 7.58579 7.67969 8C7.67969 8.41421 8.01547 8.75 8.42969 8.75Z"
        fill={textColor}
      />
      <path
        d="M8.42969 4.75C8.8439 4.75 9.17969 4.41421 9.17969 4C9.17969 3.58579 8.8439 3.25 8.42969 3.25C8.01547 3.25 7.67969 3.58579 7.67969 4C7.67969 4.41421 8.01547 4.75 8.42969 4.75Z"
        fill={textColor}
      />
      <path
        d="M8.42969 12.75C8.8439 12.75 9.17969 12.4142 9.17969 12C9.17969 11.5858 8.8439 11.25 8.42969 11.25C8.01547 11.25 7.67969 11.5858 7.67969 12C7.67969 12.4142 8.01547 12.75 8.42969 12.75Z"
        fill={textColor}
      />
    </SvgIcon>
  );
};

MoreIcon.propTypes = {
  textColor: PropTypes.string,
};

MoreIcon.defaultProps = {
  textColor: 'white',
};

const Cell = styled(Box)({
  flexBasis: 1,
  flexGrow: 1,
  flexShrink: 1,
});
const Content = styled(Box)(({ theme }) => ({
  minHeight: '164px',
  paddingTop: '16px',
  [theme.breakpoints.down('desktop')]: {
    minHeight: '114px',
    paddingTop: '8px',
  },
}));
const Date = styled(Box)(({ theme }) => ({
  width: '32px',
  height: '32px',
  padding: '6px 0',
  textAlign: 'center',
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: '20px',
  margin: '0 auto 12px auto',
  borderRadius: '50%',
  [theme.breakpoints.down('desktop')]: {
    height: '24px',
    fontSize: '14px',
    padding: '2px 0',
    borderRadius: '12px',
    margin: '0 auto 8px auto',
  },
}));

const CalendarCell = ({
  day,
  rowWidth,
  plans,
  clickable,
  onClick,
  onClickSchedule,
}) => {
  const theme = useTheme();
  const isDeskTop = useMediaQuery(theme.breakpoints.up('desktop'));
  const handleClick = () => {
    if (clickable) {
      onClick();
    }
  };
  const handleClickSchedule = (event, scheduleIdx) => {
    event.stopPropagation();
    if (onClickSchedule) {
      onClickSchedule(scheduleIdx);
    }
  };
  const strDate = day.date ? day.date.toString() : '';
  let dateColor = day.color;
  if (day.color === 'red') {
    dateColor = '#FF6666';
  } else if (day.color === 'blue') {
    dateColor = '#557CF2';
  } else if (day.color === 'grey') {
    dateColor = '#ADB5BD';
  } else {
    dateColor = '#212529';
  }
  let sxCell = {};
  let sxDate = { color: dateColor };
  if (day.today && day.color !== 'grey') {
    sxDate = {
      color: '#FFFFFF',
      backgroundColor: '#00C4C4',
    };
    sxCell = {
      border: '2px solid #00C4C4',
    };
  }

  if (day.color === 'grey') {
    sxCell = { backgroundColor: '#F9FAFB' };
  }

  const mapPlans = new Map();
  let rowCount = 0;
  plans.forEach(plan => {
    if (plan.rowX + 1 > rowCount) {
      rowCount = plan.rowX + 1;
    }
    if (plan.isBoxStart) {
      mapPlans.set(plan.rowX, plan);
    }
  });
  const rows = [];
  for (let i = 0; i < rowCount; i += 1) {
    const plan = mapPlans.get(i);
    if (plan) {
      rows.push(plan);
    } else {
      rows.push({});
    }
  }

  return (
    <Cell>
      <Content
        sx={{ ...sxCell, cursor: clickable ? 'pointer' : 'auto' }}
        onClick={() => handleClick()}
      >
        <Date sx={sxDate}>{strDate}</Date>
        <Box>
          {rows.map((plan, index) => {
            if (plan.isBoxStart) {
              const planWidth =
                ((rowWidth - 6) * plan.boxCount) / 7 + (plan.boxCount - 1);
              let bgColor = 'grey';
              let textColor = 'black';
              if (plan.calendar_type === 'red') {
                bgColor = '#FFF1F1';
                textColor = '#F25555';
              } else if (plan.calendar_type === 'orange') {
                bgColor = '#FFF6EA';
                textColor = '#EE9D3D';
              } else if (plan.calendar_type === 'blue') {
                bgColor = '#EFF3FD';
                textColor = '#4167D9';
              } else if (plan.calendar_type === 'green') {
                bgColor = '#E7F9F9';
                textColor = '#00B2B2';
              } else if (plan.calendar_type === 'purple') {
                bgColor = '#F8F2FF';
                textColor = '#8D4AE1';
              }
              return (
                <Box
                  sx={{ width: '1px', height: '24px', position: 'relative' }}
                  key={`cell-row-${index.toString()}`}
                >
                  <Box
                    sx={{
                      backgroundColor: 'transparent',
                      width: `${planWidth}px`,
                      padding: isDeskTop ? '1px 8px' : '1px 2px',
                      position: 'absolute',
                      cursor: 'default',
                    }}
                    onClick={e => {
                      e.stopPropagation();
                    }}
                  >
                    <Box
                      sx={{
                        height: '22px',
                        backgroundColor: bgColor,
                        color: textColor,
                        borderRadius: isDeskTop ? '11px' : '3px',
                        paddingLeft: isDeskTop ? '12px' : '4px',
                        fontWeight: 500,
                        fontSize: '13px',
                        lineHeight: '14px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <Box
                        sx={{
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {plan.title}
                      </Box>
                      <IconButton
                        sx={{ padding: isDeskTop ? '3px 4px' : 0 }}
                        onClick={e => handleClickSchedule(e, plan.idx)}
                      >
                        <MoreIcon textColor={textColor} />
                      </IconButton>
                    </Box>
                  </Box>
                </Box>
              );
            }

            return (
              <Box
                sx={{ width: '1px', height: '24px' }}
                key={`cell-row-${index.toString()}`}
              />
            );
          })}
        </Box>
      </Content>
    </Cell>
  );
};

CalendarCell.propTypes = {
  day: PropTypes.shape({
    date: PropTypes.number,
    color: PropTypes.string,
    today: PropTypes.bool,
  }),
  rowWidth: PropTypes.number,
  plans: PropTypes.arrayOf(
    PropTypes.shape({
      isBoxStart: PropTypes.bool,
      boxCount: PropTypes.number,
      rowX: PropTypes.number,
      calendar_type: PropTypes.string,
      title: PropTypes.string,
      idx: PropTypes.number,
    }),
  ),
  clickable: PropTypes.bool,
  onClick: PropTypes.func,
  onClickSchedule: PropTypes.func,
};

CalendarCell.defaultProps = {
  day: {
    date: null,
    color: '#ADB5BD',
    today: false,
  },
  rowWidth: 0,
  plans: [],
  clickable: false,
  onClick: null,
  onClickSchedule: null,
};

export default CalendarCell;
