import { boardApi } from '../../api/board';
import { showError } from '../../lib/utils';

const numericKeys = ['idx', 'rownum', 'category1_idx', 'category2_idx'];
const queries = url =>
  url
    ?.split('?')[1]
    ?.split('&')
    .reduce((a, n) => {
      const [key, value] = n.split('=');
      return !!key && !!value
        ? {
            ...a,
            [key]: numericKeys.indexOf(key) > -1 ? parseInt(value, 10) : value,
          }
        : a;
    }, {});

// FIXME: 솔직히 이름이 마음에 들지 않지만 일단 선언된 API 함수를 사용해서 구현해야 하기에 API 함수 이름을 그대로 쓴다.
export const loadBasicDetail = board_type => async props => {
  try {
    const {
      request: { url },
      params: { idx },
    } = props;
    const { data } = await boardApi.basicDetail({
      idx,
      board_type,
      ...queries(url),
    });
    return data?.data;
  } catch (err) {
    showError(err);
  }
  return Promise.reject(new Error('page load failed'));
};
