import React from 'react';
import PropTypes from 'prop-types';
import { styled, Box } from '@mui/material';
import Cell from './CalendarCell';

const Row = styled(Box)(({ theme }) => ({
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: '20px',
  color: '#212529',
  borderBottom: '1px solid #E6EAED',
  display: 'flex',
  [theme.breakpoints.down('desktop')]: {
    fontSize: '14px',
  },
}));

const VLine = styled(Box)({
  width: '1px',
  flexGrow: 0,
  flexShrink: 0,
  backgroundColor: '#E6EAED',
});

const CalendarRow = ({
  days,
  schedules,
  rowWidth,
  onClickCell,
  onClickSchedule,
}) => {
  if (days.length !== 7) {
    return null;
  }
  const list = [...schedules];
  const cells = [
    { plans: [] },
    { plans: [] },
    { plans: [] },
    { plans: [] },
    { plans: [] },
    { plans: [] },
    { plans: [] },
  ]; // {item, isBoxStart, boxCount, rowX}

  let rowX = 0;
  const list1 = [];
  list.forEach(item => {
    if (
      item.startDateNumber <= days[0].yyyymmdd &&
      days[0].yyyymmdd <= item.endDateNumber
    ) {
      let boxCount = item.endDateNumber - days[0].yyyymmdd + 1;
      if (boxCount > 7) boxCount = 7;
      cells[0].plans.push({
        ...item,
        isBoxStart: true,
        boxCount,
        rowX,
      });
      for (let i = 1; i < boxCount; i += 1) {
        cells[i].plans.push({
          ...item,
          isBoxStart: false,
          boxCount: 0,
          rowX,
        });
      }
      rowX += 1;
    } else {
      list1.push(item);
    }
  });
  const setCellInfo = (aSchedules, cellX) => {
    const newSchedules = [];
    let rowIndex = 0;
    aSchedules.forEach(item => {
      if (item.startDateNumber === days[cellX].yyyymmdd) {
        let boxCount = item.endDateNumber - days[cellX].yyyymmdd + 1;
        if (boxCount > 7 - cellX) boxCount = 7 - cellX;
        let loop = true;
        do {
          // eslint-disable-next-line no-loop-func
          const found = cells[cellX].plans.find(plan => rowIndex === plan.rowX);
          if (!found) {
            loop = false;
          } else {
            rowIndex += 1;
          }
        } while (loop);
        cells[cellX].plans.push({
          ...item,
          isBoxStart: true,
          boxCount,
          rowX: rowIndex,
        });

        for (let i = cellX + 1; i < cellX + boxCount; i += 1) {
          cells[i].plans.push({
            ...item,
            isBoxStart: false,
            boxCount: 0,
            rowX: rowIndex,
          });
        }
        rowIndex += 1;
      } else {
        newSchedules.push(item);
      }
    });
    return newSchedules;
  };
  const list2 = setCellInfo(list1, 1);
  const list3 = setCellInfo(list2, 2);
  const list4 = setCellInfo(list3, 3);
  const list5 = setCellInfo(list4, 4);
  const list6 = setCellInfo(list5, 5);
  setCellInfo(list6, 6);

  const clickables = [false, false, false, false, false, false, false];
  days.forEach((day, index) => {
    const foundIdx = schedules.findIndex(schedule => {
      return (
        schedule.startDateNumber <= day.yyyymmdd &&
        day.yyyymmdd <= schedule.endDateNumber
      );
    });
    if (foundIdx >= 0) {
      clickables[index] = true;
    }
  });
  const handleClickCell = yyyymmdd => {
    if (onClickCell) {
      onClickCell(yyyymmdd);
    }
  };

  const handleClickSchedule = scheduleIdx => {
    if (onClickSchedule) {
      onClickSchedule(scheduleIdx);
    }
  };
  return (
    <Row>
      <Cell
        day={days[0]}
        rowWidth={rowWidth}
        plans={cells[0].plans}
        clickable={clickables[0]}
        onClick={() => handleClickCell(days[0].yyyymmdd)}
        onClickSchedule={handleClickSchedule}
      />
      <VLine />
      <Cell
        day={days[1]}
        rowWidth={rowWidth}
        plans={cells[1].plans}
        clickable={clickables[1]}
        onClick={() => handleClickCell(days[1].yyyymmdd)}
        onClickSchedule={handleClickSchedule}
      />
      <VLine />
      <Cell
        day={days[2]}
        rowWidth={rowWidth}
        plans={cells[2].plans}
        clickable={clickables[2]}
        onClick={() => handleClickCell(days[2].yyyymmdd)}
        onClickSchedule={handleClickSchedule}
      />
      <VLine />
      <Cell
        day={days[3]}
        rowWidth={rowWidth}
        plans={cells[3].plans}
        clickable={clickables[3]}
        onClick={() => handleClickCell(days[3].yyyymmdd)}
        onClickSchedule={handleClickSchedule}
      />
      <VLine />
      <Cell
        day={days[4]}
        rowWidth={rowWidth}
        plans={cells[4].plans}
        clickable={clickables[4]}
        onClick={() => handleClickCell(days[4].yyyymmdd)}
        onClickSchedule={handleClickSchedule}
      />
      <VLine />
      <Cell
        day={days[5]}
        rowWidth={rowWidth}
        plans={cells[5].plans}
        clickable={clickables[5]}
        onClick={() => handleClickCell(days[5].yyyymmdd)}
        onClickSchedule={handleClickSchedule}
      />
      <VLine />
      <Cell
        day={days[6]}
        rowWidth={rowWidth}
        plans={cells[6].plans}
        clickable={clickables[6]}
        onClick={() => handleClickCell(days[6].yyyymmdd)}
        onClickSchedule={handleClickSchedule}
      />
    </Row>
  );
};

CalendarRow.propTypes = {
  days: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.number,
      color: PropTypes.string,
      today: PropTypes.bool,
      yyyymmdd: PropTypes.number,
    }),
  ).isRequired,
  rowWidth: PropTypes.number,
  schedules: PropTypes.arrayOf(
    PropTypes.shape({
      calendar_type: PropTypes.string,
      idx: PropTypes.number,
      title: PropTypes.string,
      start_date: PropTypes.string,
      end_date: PropTypes.string,
      startDateNumber: PropTypes.number,
      endDateNumber: PropTypes.number,
    }),
  ),
  onClickCell: PropTypes.func,
  onClickSchedule: PropTypes.func,
};

CalendarRow.defaultProps = {
  schedules: [],
  rowWidth: 0,
  onClickCell: null,
  onClickSchedule: null,
};

export default CalendarRow;
