import { React } from 'react';
import { Divider, Typography, Box } from '@mui/material';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import { ReactComponent as SNSBranchIcon } from '../../../assets/images/sns_brunch.svg';
import { ReactComponent as SNSBlog } from '../../../assets/images/sns_blog.svg';
import { ReactComponent as SNSKakao } from '../../../assets/images/sns_kakao.svg';
import { ReactComponent as SNSFaceBook } from '../../../assets/images/sns_facebook.svg';
import { ReactComponent as SNSInsta } from '../../../assets/images/sns_insta.svg';
import { ReactComponent as SNSX } from '../../../assets/images/sns_x.svg';
import { ReactComponent as SNSYoutube } from '../../../assets/images/sns_youtube.svg';

const SubTitle = styled(Typography)(() => ({
  fontWeight: '400',
  fontSize: '15px',
  lineHeight: '24px',
}));
const SNSBoxStyle = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'start',
  alignItems: 'flex-start',
  height: '56px',
  padding: '0px 0px 8px',
  marginBottom: '20px',
};

const Terms = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  transition: 'none',
  color: 'black',
  height: '40px',
  padding: '0px',
}));
const Term = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  cursor: 'pointer',
}));
const SNSBox = ({ sideOn }) => {
  const sideStyle = {
    '&:hover': {
      backgroundColor: sideOn ? '#F5F7FA' : 'white',
    },
  };
  return (
    <Box sx={{ padding: '0px 0px' }}>
      <Box sx={{ SNSBoxStyle }}>
        <Terms>
          <Term
            sx={sideStyle}
            onClick={() => {
              window.open('https://pf.kakao.com/_xkzxbFd', '_blank');
            }}
          >
            <SNSKakao />
            <SubTitle sx={{ marginLeft: '9px' }}>카카오톡</SubTitle>
          </Term>
        </Terms>
      </Box>
      <Box sx={{ SNSBoxStyle }}>
        <Terms>
          <Term
            sx={sideStyle}
            onClick={() => {
              window.open(
                'https://www.youtube.com/@wadiz_makercenter',
                '_blank',
              );
            }}
          >
            <SNSYoutube />
            <SubTitle sx={{ marginLeft: '9px' }}>유튜브</SubTitle>
          </Term>
        </Terms>
      </Box>
      <Box sx={{ SNSBoxStyle }}>
        <Terms>
          <Term
            sx={sideStyle}
            onClick={() => {
              window.open(
                'https://www.instagram.com/wadiz_makercenter',
                '_blank',
              );
            }}
          >
            <SNSInsta />
            <SubTitle sx={{ marginLeft: '9px' }}>인스타그램</SubTitle>
          </Term>
        </Terms>
      </Box>
      <Box sx={{ SNSBoxStyle }}>
        <Terms>
          <Term
            onClick={() => {
              window.open('https://blog.wadiz.kr/', '_blank');
            }}
            sx={sideStyle}
          >
            <SNSBlog />
            <SubTitle sx={{ marginLeft: '9px' }}>블로그</SubTitle>
          </Term>
          <Divider
            orientation="vertical"
            variant="middle"
            sx={{
              margin: '0px',
              marginLeft: '10px',
              marginRight: '10px',
              height: '10px',
            }}
          />
          <Term
            sx={sideStyle}
            onClick={() => {
              window.open('https://brunch.co.kr/@wadiz', '_blank');
            }}
          >
            <SNSBranchIcon />
            <SubTitle sx={{ marginLeft: '9px' }}>브런치</SubTitle>
          </Term>
        </Terms>
      </Box>
      <Box sx={{ SNSBoxStyle }}>
        <Terms>
          <Term
            sx={sideStyle}
            onClick={() => {
              window.open('https://twitter.com/Wadiz_funding', '_blank');
            }}
          >
            <SNSX />
            <SubTitle sx={{ marginLeft: '9px' }}>X</SubTitle>
          </Term>
          <Divider
            orientation="vertical"
            variant="middle"
            sx={{
              margin: '0px',
              marginLeft: '10px',
              marginRight: '10px',
              height: '10px',
            }}
          />
          <Term
            sx={sideStyle}
            onClick={() => {
              window.open('https://www.facebook.com/wadiz.funding', '_blank');
            }}
          >
            <SNSFaceBook />
            <SubTitle sx={{ marginLeft: '9px' }}>펀딩</SubTitle>
          </Term>
        </Terms>
      </Box>
    </Box>
  );
};
SNSBox.propTypes = {
  sideOn: PropTypes.bool,
};

SNSBox.defaultProps = {
  sideOn: false,
};

export default SNSBox;
