import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Box, ImageList, styled } from '@mui/material';
import qs from 'qs';
import { boardApi } from '../api/board';
import Pagenation from '../components/common/Pagenation';
import TableFilter from '../components/common/TableFilter';
import Selector from '../components/common/Selector';
import CategorySelector from '../components/common/CategorySelector';
import { showError } from '../lib/utils';
import EventView from '../components/common/EventView';
import WideRollingBanner from '../components/home/KVBanner/WideRollingBanner';

const eventList = [
  { value: 1, name: '전체', param: '' },
  { value: 2, name: '진행예정', param: 'before' },
  { value: 3, name: '진행중', param: 'ing' },
  { value: 4, name: '종료', param: 'after' },
];

const searchList = [
  { value: 1, name: '제목+내용', param: 'title_contents' },
  { value: 2, name: '제목', param: 'title' },
  { value: 3, name: '내용', param: 'contents' },
  { value: 4, name: '해시태그', param: 'hashtag' },
];

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  [theme.breakpoints.down('desktop')]: {
    minWidth: '360px',
  },
}));

const EmptyBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '400px',
  color: '#495057',
  fontWeight: '400',
  fontSize: '15px',
  lineHeight: '24px',
  [theme.breakpoints.up('desktop')]: {
    fontSize: '18px',
    lineHeight: '28px',
  },
}));

const EventBoard = ({ board_idx, page, page_size, searchText }) => {
  const [dataList, setDataList] = useState({
    list: [],
    total_count: 0,
    page_row: 0,
    category1: '',
    category2: '',
    banner: [],
  });
  const [category1List, setCategory1List] = useState([]);
  const [category1, setCategory1] = useState('');
  const [categoryChanged, setCategoryChanged] = useState(false);
  const [category2List, setCategory2List] = useState([]);
  const [category2, setCategory2] = useState('');
  const [eventType, setEventType] = useState(eventList[0]);
  // eslint-disable-next-line no-unused-vars
  const [searchType, setSearchType] = useState(searchList[0]);
  const [currentPage, setCurrentPage] = useState(Number(page));
  const [currentBannerIndex, setCurrentBannerIndex] = useState(0); // 슬라이드 인덱스
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { menuId } = useParams();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('desktop'));
  const params = {
    board_type: 'event',
    board_idx,
    page,
    page_size,
    platform: 'PC',
    event_yn: '',
    search_type: 'all',
    search_text: searchText,
    category1_idx: '',
    category2_idx: '',
  };
  const handleClickItem = (idx, rownum) => {
    const data = {
      rownum,
      event_yn: eventType.param,
      search_type: searchType.param,
      search_text: searchText,
      category1_idx: category1,
      category2_idx: category2,
      page: params.page,
    };
    const query = qs.stringify(data);
    // console.log(query);
    navigate(`/board/event/${idx}?${query}`);
  };
  // const handleChangeSearchType = value => {
  //   setSearchType(searchList.find(element => element.value === value));
  // };

  const handleChangeEventType = value => {
    setEventType(eventList.find(element => element.value === value));
  };

  const requestList = async () => {
    setLoading(true);
    try {
      params.event_yn = eventType.param;
      params.search_type = searchType.param;
      params.category1_idx = category1;
      params.category2_idx = category2;
      params.platform = isMobile ? 'MOBILE' : 'PC';
      //   console.log('param', params);
      const { data } = await boardApi.basicList(params);
      // console.log(data);
      setDataList({
        list: data.data.lists,
        total_count: data.data.total_count,
        page_row: data.data.page_row,
        banner: data.data.banner,
        board_title: data.data.board_title,
      });
      //   console.log('data', data);
      const { category } = data.data;
      if (category && category[0]) {
        setCategory1List(category[0].category);
        if (!category1) {
          setCategory1('');
        }
      }
      //   if (data.result === 'success') {

      //   }
    } catch (err) {
      showError(err);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = searchStr => {
    params.search_text = searchStr;
    params.page = 1;
    setCurrentPage(1);
    navigate(
      `/menu/${menuId}?page=${params.page}&search=${params.search_text}`,
    );
  };

  const handleCategory1Change = value => {
    setCategory1(value);
  };
  const handleCategory2Change = value => {
    setCategory2(value);
  };

  const handlePage = changed_page => {
    setCurrentPage(changed_page);
    navigate(
      `/menu/${menuId}?page=${changed_page}&search=${params.search_text}`,
    );
  };

  const handleClickBanner = () => {
    if (dataList.banner[currentBannerIndex].popup_yn === 'N') {
      window.location.href = dataList.banner[currentBannerIndex].image_link;
    } else {
      window.open(dataList.banner[currentBannerIndex].image_link, '_blank');
    }
  };

  const getBoradTitle = title => {
    if (board_idx === 3) return '이벤트·혜택';
    return title;
  };

  React.useLayoutEffect(() => {
    if (category1List) {
      const list2 = category1List.find(
        element => element.category_idx === category1,
      );
      if (list2?.category) {
        setCategory2List(list2.category);
        if (list2.category.length > 0) {
          setCategory2('');
        }
      } else {
        setCategory2List([]);
        setCategory2('');
      }
    }
    setCategoryChanged(prev => !prev);
  }, [category1]);

  React.useLayoutEffect(() => {
    requestList();
    setCurrentPage(Number(page));
    window.scrollTo(0, 0);
  }, [page, searchText, categoryChanged, category2, eventType]);

  return (
    <Container sx={{ width: '100%' }}>
      {dataList.banner.length > 0 ? (
        <WideRollingBanner
          kvBanners={dataList.banner}
          currentIndex={currentBannerIndex}
          setCurrentIndex={setCurrentBannerIndex}
          onClick={handleClickBanner}
          gaCode="EKB"
        />
      ) : null}
      {isMobile ? (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            pr: '16px',
            pl: '16px',
            mt: '30px',
          }}
        >
          <Box display="flex" sx={{ width: '100%' }}>
            <Box display="flex" alignItems="center">
              <CategorySelector
                list={category1List ?? []}
                sx={{ width: '120px', mr: '4px' }}
                value={category1}
                onChange={handleCategory1Change}
                disabled={(category1List || []).length === 0}
                mobile
              />
              {category2List.length > 0 ? (
                <CategorySelector
                  list={category2List ?? []}
                  sx={{ width: '120px', mr: '4px' }}
                  value={category2}
                  onChange={handleCategory2Change}
                  disabled={(category2List || []).length === 0}
                  mobile
                />
              ) : null}
            </Box>
            <TableFilter
              onChange={handleSearch}
              value={params?.search_text || ''}
            />
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{ width: '100%', mt: '18px' }}
          >
            <Box
              sx={{
                fontWeight: '700',
                fontSize: '18px',
                lineHeight: '28px',
                color: '#212529',
              }}
            >
              {getBoradTitle(dataList.board_title)}
            </Box>
            <Selector
              list={eventList}
              value={eventType?.value}
              sx={{ width: '120px' }}
              onChange={handleChangeEventType}
            />
          </Box>
        </Box>
      ) : (
        <Box
          display="flex"
          justifyContent="space-between"
          sx={{ width: '100%' }}
        >
          <Box
            sx={{
              fontWeight: '700',
              fontSize: '24px',
              lineHeight: '34px',
              color: '#212529',
            }}
          >
            {getBoradTitle(dataList.board_title)}
          </Box>
          <Box display="flex">
            <CategorySelector
              list={category1List ?? []}
              sx={{ width: '140px', mr: '10px' }}
              value={category1}
              onChange={handleCategory1Change}
              disabled={(category1List || []).length === 0}
            />
            {category2List.length > 0 ? (
              <CategorySelector
                list={category2List ?? []}
                sx={{ width: '140px', mr: '10px' }}
                value={category2}
                onChange={handleCategory2Change}
                disabled={(category2List || []).length === 0}
              />
            ) : null}
            <Selector
              list={eventList}
              value={eventType?.value}
              sx={{ width: '110px', mr: '10px' }}
              onChange={handleChangeEventType}
            />
            {/* <Selector
            list={searchList}
            value={searchType?.value}
            sx={{ width: '110px' }}
            onChange={handleChangeSearchType}
          /> */}
            <TableFilter
              onChange={handleSearch}
              value={params?.search_text || ''}
            />
          </Box>
        </Box>
      )}
      <ImageList
        cols={isMobile ? 1 : 4}
        sx={{
          width: '100%',
          overflow: 'hidden',
          pr: isMobile ? '16px' : '0px',
          pl: isMobile ? '16px' : '0px',
        }}
        gap={0}
      >
        {dataList?.list.map(item => {
          return (
            <EventView
              key={item.idx}
              item={item}
              onClick={handleClickItem}
              mobile={isMobile}
            />
          );
        })}
      </ImageList>
      {dataList.list.length === 0 ? null : (
        <Pagenation
          sx={
            isMobile
              ? {
                  mt: '30px',
                  pr: '16px',
                  pl: '16px',
                  width: '100%',
                }
              : { mt: '30px' }
          }
          key={board_idx}
          currentPage={currentPage}
          pageChangeHandler={handlePage}
          totalRows={dataList.total_count}
          rowsPerPage={dataList.page_row}
          mobile={isMobile}
        />
      )}
      {loading ? <Box sx={{ height: '400px' }} /> : null}
      {dataList.list.length === 0 && !loading ? (
        <EmptyBox>등록된 글이 없습니다.</EmptyBox>
      ) : null}
    </Container>
  );
};

EventBoard.propTypes = {
  board_idx: PropTypes.number.isRequired,
  page: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  page_size: PropTypes.number,
  searchText: PropTypes.string,
  row: PropTypes.shape({
    original: PropTypes.shape({
      idx: PropTypes.number,
      title: PropTypes.string,
      contents: PropTypes.string,
      rownum: PropTypes.number,
      image_url: PropTypes.string,
      image_use_yn: PropTypes.string,
      important_yn: PropTypes.string,
      reg_date: PropTypes.bool,
      summary: PropTypes.string,
      category1_title: PropTypes.string,
      category2_title: PropTypes.string,
    }),
  }),
};

EventBoard.defaultProps = {
  row: null,
  page: 1,
  page_size: 10,
  searchText: '',
};

export default EventBoard;
