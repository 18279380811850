import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, useTheme, useMediaQuery } from '@mui/material';
import moment from 'moment';
import { ReactComponent as IconArrow } from '../../assets/images/calendar/ic_item_arrow.svg';
import { getCalendarColor } from './calendar_util';

const getDayOfWeekString = day => {
  const strDays = ['일', '월', '화', '수', '목', '금', '토'];
  if (day >= 0 && day <= 6) {
    return strDays[day];
  }
  return '';
};

const ScheduleTypeBox = ({ type, selected }) => {
  const theme = useTheme();
  const isDeskTop = useMediaQuery(theme.breakpoints.up('desktop'));
  let color = 'grey';
  let strType = '';
  if (type === 'red') {
    color = '#F25555';
    strType = isDeskTop ? '이벤트/혜택' : '이벤트';
  } else if (type === 'orange') {
    color = '#EE9D3D';
    strType = isDeskTop ? '와디즈 스쿨' : '스쿨';
  } else if (type === 'green') {
    color = '#00B2B2';
    strType = '기획전';
  } else if (type === 'blue') {
    color = '#4167D9';
    strType = isDeskTop ? '서비스/정책' : '정책';
  } else if (type === 'purple') {
    color = '#8D4AE1';
    strType = '스토어 정산';
  }

  let sx0 = {};
  if (selected) {
    sx0 = {
      height: '18px',
      fontWeight: 700,
      fontSize: '10px',
      lineHeight: '14px',
      borderRadius: '3px',
      padding: '2px 4px',

      backgroundColor: color,
      color: 'white',
    };
  } else {
    sx0 = {
      height: '18px',
      fontWeight: 700,
      fontSize: '10px',
      lineHeight: '14px',
      borderRadius: '3px',
      padding: '1px 3px',

      border: `1px solid ${color}`,
      color,
    };
  }
  return <Box sx={sx0}>{strType}</Box>;
};
ScheduleTypeBox.propTypes = {
  type: PropTypes.string.isRequired,
  selected: PropTypes.bool,
};
ScheduleTypeBox.defaultProps = {
  selected: false,
};

const MyItem = ({ schedule, onClick, selected }) => {
  const theme = useTheme();
  const isDeskTop = useMediaQuery(theme.breakpoints.up('desktop'));
  const [titleWidth, setTitleWidth] = useState(0);
  const titleRef = useRef();

  useEffect(() => {
    if (titleRef.current) {
      setTitleWidth(titleRef.current.offsetWidth);
    }
  }, []);

  const handleResize = () => {
    if (titleRef.current) {
      setTitleWidth(titleRef.current.offsetWidth);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const startMoment = moment(schedule.start_date);
  const endMoment = moment(schedule.end_date);

  const strDate = `${startMoment.format('MM.DD')}(${getDayOfWeekString(
    startMoment.day(),
  )})`;
  let dayOfWeekColor = '#212529';
  if (startMoment.day() === 0) {
    dayOfWeekColor = '#F25555';
  } else if (startMoment.day() === 6) {
    dayOfWeekColor = '#557CF2';
  }
  let strPeriod = '';
  if (schedule.startDateNumber === schedule.endDateNumber) {
    strPeriod = `${startMoment.format('YYYY.MM.DD')}(${getDayOfWeekString(
      startMoment.day(),
    )})`;
  } else {
    strPeriod = `${startMoment.format('YYYY.MM.DD')}(${getDayOfWeekString(
      startMoment.day(),
    )}) ~ ${endMoment.format('YYYY.MM.DD')}(${getDayOfWeekString(
      endMoment.day(),
    )})`;
  }
  let titleColor = '#212529';
  if (selected) {
    const typeColor = getCalendarColor(schedule.calendar_type);
    if (typeColor.matched) {
      titleColor = typeColor.textColor;
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        borderBottom: '1px solid #E6EAED',
        padding: isDeskTop ? '16px 0' : '12px 0',
        alignItems: 'center',
        cursor: 'pointer',
        backgroundColor: selected ? '#FBFBFB' : 'white',
      }}
      onClick={() => {
        if (onClick) onClick();
      }}
    >
      <Box
        sx={{
          width: isDeskTop ? '100px' : '64px',
          textAlign: isDeskTop ? 'center' : 'start',
          marginLeft: isDeskTop ? '16px' : '8px',
          fontWeight: selected ? 700 : 400,
          fontSize: isDeskTop ? '16px' : '14px',
          lineHeight: isDeskTop ? '28px' : '20px',
          color: dayOfWeekColor,
          flexGrow: 0,
          flexShrink: 0,
        }}
      >
        {strDate}
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          flexShrink: 1,
          marginLeft: isDeskTop ? '60px' : '9px',
        }}
      >
        <Box sx={{ display: 'flex', width: '100%' }}>
          <Box
            sx={{
              paddingTop: isDeskTop ? '6px' : '4px',
              flexGrow: 0,
              flexShrink: 0,
            }}
          >
            <ScheduleTypeBox
              type={schedule.calendar_type}
              selected={selected}
            />
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              flexShrink: 1,
              marginLeft: isDeskTop ? '10px' : '8px',
            }}
            ref={titleRef}
          >
            <Box sx={{ width: '1px' }}>
              <Box
                sx={{
                  width: titleWidth,
                  fontWeight: 500,
                  fontSize: isDeskTop ? '20px' : '16px',
                  lineHeight: isDeskTop ? '30px' : '24px',
                  color: titleColor,

                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  maxHeight: isDeskTop ? '30px' : '24px',
                }}
              >
                {schedule.title}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            marginTop: '4px',
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '20px',
            color: selected ? '#495057' : '#90949C',
          }}
        >
          {strPeriod}
        </Box>
      </Box>
      <Box
        sx={{ flexGrow: 0, flexShrink: 0, marginRight: isDeskTop ? '16px' : 0 }}
      >
        <IconArrow />
      </Box>
    </Box>
  );
};

MyItem.propTypes = {
  schedule: PropTypes.shape({
    calendar_type: PropTypes.string,
    idx: PropTypes.number,
    title: PropTypes.string,
    start_date: PropTypes.string,
    end_date: PropTypes.string,
    startDateNumber: PropTypes.number,
    endDateNumber: PropTypes.number,
  }).isRequired,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
};

MyItem.defaultProps = {
  onClick: null,
  selected: false,
};

const CalendarList = ({ schedules, onClickSchedule }) => {
  const [lastClickIdx, setLastClickIdx] = useState(null);
  return (
    <Box sx={{ borderTop: '2px solid #212529' }}>
      {schedules.map(schedule => {
        return (
          <MyItem
            key={`calendar-list-item-${schedule.idx}`}
            schedule={schedule}
            onClick={() => {
              if (onClickSchedule) {
                setLastClickIdx(schedule.idx);
                onClickSchedule(schedule.idx);
              }
            }}
            selected={lastClickIdx === schedule.idx}
          />
        );
      })}
    </Box>
  );
};

CalendarList.propTypes = {
  schedules: PropTypes.arrayOf(
    PropTypes.shape({
      calendar_type: PropTypes.string,
      idx: PropTypes.number,
      title: PropTypes.string,
      start_date: PropTypes.string,
      end_date: PropTypes.string,
      startDateNumber: PropTypes.number,
      endDateNumber: PropTypes.number,
    }),
  ),
  onClickSchedule: PropTypes.func,
};

CalendarList.defaultProps = {
  schedules: [],
  onClickSchedule: null,
};

export default CalendarList;
