export const searchModalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '560px',
  height: '500px',
  bgcolor: 'background.paper',
  border: 0,
  borderRadius: '16px',
  padding: '24px',
};

export const popupPcStyle = {
  appearance: 'none',
  MozAppearance: 'none',
  WebkitAppearance: 'none',
  position: 'absolute',
  border: 0,
  bottom: '50%',
  left: '50%',
  transform: 'translate(-50%, 50%)',
  outline: 0,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '20px',
};
