import React from 'react';
import { styled, Box, useTheme, useMediaQuery } from '@mui/material';
import { ReactComponent as KakaoIcon } from '../../assets/images/sns_kakao_plus.svg';
import KakaoServiceImg from '../../assets/images/kakaoService.jpg';

const Newsletter = () => {
  const theme = useTheme();
  const isDeskTop = useMediaQuery(theme.breakpoints.up('desktop'));

  return (
    <Container
      sx={{
        px: isDeskTop ? 0 : '16px',
        mb: isDeskTop ? '100px' : '50px',
      }}
    >
      <FlexBox
        sx={{
          height: isDeskTop ? '220px' : '226px',
          flexDirection: isDeskTop ? 'row' : 'column',
          justifyContent: isDeskTop ? 'space-between' : 'center',
        }}
      >
        <Box
          sx={{
            ml: isDeskTop ? '80px' : 0,
          }}
        >
          <MainText
            sx={{
              fontSize: isDeskTop ? '28px' : '20px',
              lineHeight: isDeskTop ? '42px' : '30px',
              textAlign: isDeskTop ? 'left' : 'center',
              mb: '0px',
            }}
          >
            {isDeskTop
              ? '와디즈 메이커 공식 채널 친구 추가하고'
              : '와디즈 메이커 공식 채널 추가하고'}
            <br />
            이벤트와 소식을 빠르게 받으세요
          </MainText>
        </Box>
        {isDeskTop ? (
          <ApplyBox
            href="https://pf.kakao.com/_xkzxbFd"
            target="_blank"
            sx={{ mr: '80px', mt: 0 }}
            data-promo-news
          >
            <KakaoIcon width={32} height={24} />
            <Letter
              sx={{
                fontSize: '18px',
              }}
            >
              채널 추가하고 소식 받기
            </Letter>
          </ApplyBox>
        ) : (
          <ApplyBoxMobile
            href="https://pf.kakao.com/_xkzxbFd"
            target="_blank"
            sx={{ mt: '16px' }}
            data-promo-news
          >
            <KakaoIcon width={24} height={18} />
            <Letter
              sx={{
                fontSize: '14px',
              }}
            >
              채널 추가하고 소식 받기
            </Letter>
          </ApplyBoxMobile>
        )}
      </FlexBox>
    </Container>
  );
};

const Container = styled(Box)({
  // border: '1px solid dodgerblue',
  position: 'relative',
});
const FlexBox = styled(Box)({
  // border: '1px solid red',
  position: 'relative',
  margin: '0 auto',
  // border: 0,
  borderRadius: '8px',
  // width: '1280px',
  height: '220px',
  maxWidth: '1280px',
  minWidth: '320px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundImage: `url('${KakaoServiceImg}')`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center center',
  backgroundSize: 'cover',
  '&::before': {
    content: '""',
    position: 'absolute',
    margin: '0 auto',
    borderRadius: '8px',
    height: '100%',
    width: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
  },
});
const MainText = styled(Box)({
  position: 'relative',
  fontWeight: 700,
  fontSize: '32px',
  color: '#fff',
  lineHeight: '44px',
  marginBottom: '16px',
});
const ApplyBox = styled('a')({
  position: 'relative',
  // border: '1px solid red',
  width: '280px',
  height: '56px',
  padding: '0px 16px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  border: 0,
  borderRadius: '8px',
  backgroundColor: '#FCE239',
  cursor: 'pointer',
  textDecoration: 'none',
});
const ApplyBoxMobile = styled('a')({
  position: 'relative',
  // border: '1px solid red',
  padding: '0px 16px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  border: 0,
  borderRadius: '8px',
  backgroundColor: '#FCE239',
  cursor: 'pointer',
  textDecoration: 'none',
  width: 'calc(100% - 32px)',
  height: '48px',
});
const Letter = styled(Box)({
  // border: '1px solid red',
  marginLeft: '8px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: '#212529',
  fontSize: '18px',
  fontWeight: 700,
});

export default Newsletter;
