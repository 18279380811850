import { React, useState, useEffect } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { styled } from '@mui/system';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import { ReactComponent as MyWadizNavIcon } from '../../assets/images/mywadiznav_icon.svg';
import { ReactComponent as NotUpdatedNavIcon } from '../../assets/images/not_updated.svg';
import { ReactComponent as UpdatedNavIcon } from '../../assets/images/updated.svg';
import { ReactComponent as WadizNavIcon } from '../../assets/images/wadiznav_icon.svg';
import { ReactComponent as PencilIcon } from '../../assets/images/icon_pencil.svg';
import { ReactComponent as NextIcon } from '../../assets/images/ic-next-w.svg';
import PCToolbarMenuBox from './PCToolbarMenuBox';

dayjs.extend(weekday);

const FixedBox = styled(Box)(() => ({
  position: 'absolute',
  width: '100%',
  background: '#fff',
  transition: '.4s ease-out',
  justifyContent: 'center',
  overflow: 'hidden',
  top: '81px',
  zIndex: 101,
}));

const NavBox = styled(Box)(() => ({
  margin: 'auto',
  paddingTop: '32px',
  paddingBottom: '32px',
  maxWidth: '1280px',
  display: 'flex',
}));
const LinkBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '178px',
  marginRight: '60px',
}));
const WadizLink = styled(Button)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  padding: '12px 16px',
  marginBottom: '8px',
  width: '178px',
  height: '56px',
  background: '#E6F6FF',
  borderRadius: '8px',
  '&:hover': {
    background: '#E6F6FF',
  },
}));
const MyWadizLink = styled(Button)(() => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '12px 16px',
  marginBottom: '8px',
  width: '178px',
  height: '56px',
  background: '#FFF8E7',
  borderRadius: '8px',
  '&:hover': {
    background: '#FFF8E7',
  },
}));
const ImportantUpdateLink = styled(Button)(() => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '12px 16px',
  marginBottom: '8px',
  width: '178px',
  height: '56px',
  background: 'rgb(255, 241, 241)',
  borderRadius: '8px',
  '&:hover': {
    background: 'rgb(255, 241, 241)',
  },
}));

const WadizText = styled(Typography)(() => ({
  marginLeft: '12px',
  fontStyle: 'normal',
  fontWeight: '700',
  fontSize: '15px',
  lineHeight: '24px',
  textAlign: 'center',
  letterSpacing: '-0.3px',
  color: '#212529',
}));

const BannerBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '24px',
  width: '260px',
  height: '160px',
  background: '#E7F9F9',
  borderRadius: '8px',
}));
const BannerTitle = styled(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: '700',
  fontSize: '18px',
  letterSpacing: '-0.3px',
  lineHeight: '28px',
  marginBottom: '4px',
}));
const BannerSubTitle = styled(Typography)(() => ({
  textAlign: 'center',
  letterSpacing: '-0.3px',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '14px',
  lineHeight: '20px',
  marginBottom: '16px',
}));
const ProjectOpenLink = styled(Button)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '10px 16px',
  width: '169px',
  height: '44px',
  background: '#00C4C4',
  borderRadius: '3px',
  '&:hover': {
    background: '#00C4C4',
  },
}));

const ProjectOpenText = styled(Typography)(() => ({
  marginLeft: '5px',
  width: '115px',
  height: '24px',
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: '15px',
  lineHeight: '24px',
  /* identical to box height, or 160% */
  textAlign: 'center',
  letterSpacing: '-0.3px',
  color: '#FFFFFF',
  whiteSpace: 'nowrap',
}));

const AIAdvisorOpenLink = styled(Button)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '10px 16px',
  width: '100%',
  height: '44px',
  background: '#00C4C4',
  borderRadius: '3px',
  '&:hover': {
    background: '#00C4C4',
  },
}));

const AIAdvisorOpenText = styled(Typography)(() => ({
  marginLeft: '5px',
  width: '115px',
  height: '24px',
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: '15px',
  lineHeight: '24px',
  /* identical to box height, or 160% */
  textAlign: 'center',
  letterSpacing: '-0.3px',
  color: '#FFFFFF',
  whiteSpace: 'nowrap',
}));

const PCToolbarNav = ({ selectedm, menu, close, open }) => {
  const [viewHeight, setViewHeight] = useState(0);

  // 매월 첫번째 월요일 ~ 일요일에 중요 업데이트 아이콘에 업데이트 표시
  const isUpdated = () => {
    const firstDayOfMonth = dayjs().date(1);
    // 매월 1일의 요일이 1이하면 첫째주에 월요일이 있음
    if (firstDayOfMonth.day() <= 1) {
      if (dayjs().date() >= 1 && dayjs().date() <= 5) return true;
      return false;
    }
    // https://day.js.org/docs/en/plugin/weekday
    // 두번째 주에 월요일이 있음 firstDayOfMonth.weekday(8) 이면 다음주 월요일
    if (
      firstDayOfMonth.weekday(8) <= dayjs() &&
      firstDayOfMonth.weekday(14) >= dayjs()
    ) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (open === true) {
      setViewHeight('450px');
    } else {
      setViewHeight(0);
    }
  }, [open]);
  // 메뉴 너무많이 받아와서 일단 자름
  const newmenu = menu.slice(0, 5);

  const clickMenu = title => {
    window.dataLayer.push({
      event: 'ga.custom.tag',
      GACategory: 'GNB',
      GALabel: title,
      GAAction: '클릭',
    });
  };

  return (
    <FixedBox sx={{ maxHeight: viewHeight }}>
      <NavBox>
        <LinkBox>
          <WadizLink
            onClick={() => {
              window.open('http://wadiz.kr', '_blank');
              clickMenu('와디즈');
            }}
          >
            <WadizNavIcon />
            <WadizText>와디즈</WadizText>
          </WadizLink>
          <MyWadizLink
            onClick={() => {
              window.open('https://www.wadiz.kr/web/mywadiz/maker', '_blank');
              clickMenu('마이 와디즈');
            }}
          >
            <MyWadizNavIcon />
            <WadizText>마이 와디즈</WadizText>
          </MyWadizLink>
          <ImportantUpdateLink
            onClick={() => {
              window.open('https://wadiz-maker.stibee.com', '_blank');
              clickMenu('중요 업데이트');
            }}
          >
            {isUpdated() ? <UpdatedNavIcon /> : <NotUpdatedNavIcon />}
            <WadizText>중요 업데이트</WadizText>
          </ImportantUpdateLink>
        </LinkBox>
        {newmenu.map(m => {
          return (
            <PCToolbarMenuBox
              selected={selectedm}
              close={close}
              key={m.menu_idx}
              menus={m.menu}
            />
          );
        })}
        <BannerBox>
          <BannerTitle>내 프로젝트에 필요한 서류는?</BannerTitle>
          <BannerSubTitle>ChatGPT 상담 받고 1분 만에 시작하기</BannerSubTitle>
          <AIAdvisorOpenLink
            onClick={() => {
              window.open(
                'https://www.wadiz.kr/web/project/registration?type=aiAdvisorModalOpen',
                '_blank',
              );
              clickMenu('AI 어드바이저');
            }}
          >
            <AIAdvisorOpenText>AI 어드바이저</AIAdvisorOpenText>
            <NextIcon style={{ height: '16px' }} />
          </AIAdvisorOpenLink>
        </BannerBox>
      </NavBox>
    </FixedBox>
  );
};
PCToolbarNav.propTypes = {
  menu: PropTypes.array.isRequired,
  selectedm: PropTypes.object.isRequired,
  close: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default PCToolbarNav;
export { ProjectOpenLink, ProjectOpenText, PencilIcon };
