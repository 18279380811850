import React, { useEffect } from 'react';
import { styled, Box } from '@mui/material';
import PropTypes from 'prop-types';
import McCalendar from '../components/calendar/McCalendar';

const MyContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  minWidth: '292px',
  padding: '0 16px',
  marginTop: '32px',
  [theme.breakpoints.up('desktop')]: {
    width: '100%',
    padding: 0,
    marginTop: '0px',
  },
}));

const CalendarBoard = ({ board_idx, page, page_size }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <MyContainer>
      <McCalendar board_idx={board_idx} page={page} page_size={page_size} />
    </MyContainer>
  );
};

CalendarBoard.propTypes = {
  board_idx: PropTypes.number.isRequired,
  page: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  page_size: PropTypes.number,
};

CalendarBoard.defaultProps = {
  page: 1,
  page_size: 10,
};

export default CalendarBoard;
