import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { styled, Box, useTheme, useMediaQuery } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Autoplay } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import 'swiper/components/navigation/navigation.min.css';
import IconButton from '@mui/material/IconButton';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import RollingBannerNaviBar from './RollingBannerNaviBar';

const WideRollingBanner = ({
  kvBanners,
  currentIndex,
  setCurrentIndex,
  onClick,
  gaCode, // GA 이벤트 구분
}) => {
  SwiperCore.use([Navigation, Autoplay]); // swiper 기능 임포트
  const navigationPrevRef = useRef(null); // swiper 커스텀 네비버튼
  const navigationNextRef = useRef(null); // swiper 커스텀 네비버튼
  const theme = useTheme();
  const isDeskTop = useMediaQuery(theme.breakpoints.up('desktop'));

  // console.log('kvBanners :', kvBanners);

  // currentStep 처음/끝의 인덱스 반영한 현재 슬라이드 번호도출
  const showCurrentStep = currentIdx => {
    let res;
    if (kvBanners !== null) {
      if (currentIdx === 0) {
        res = kvBanners.length;
      } else if (currentIdx === kvBanners.length + 1) {
        res = 1;
      } else {
        res = currentIdx;
      }
    }
    return res;
  };

  const getIndex = currentIdx => {
    let res;
    if (kvBanners !== null) {
      if (currentIdx === 0) {
        res = kvBanners.length - 1;
      } else if (currentIdx === kvBanners.length + 1) {
        res = 0;
      } else {
        res = currentIdx - 1;
      }
    }
    return res;
  };

  const handleChangeStep = step => {
    setCurrentIndex(getIndex(step));
  };

  const moveBanner = direction => {
    if (!gaCode) return;
    const category = gaCode === 'EKB' ? '이벤트·혜택_키비주얼배너' : null;

    window.dataLayer.push({
      event: 'ga.custom.tag',
      GACategory: category,
      GALabel: direction === 'left' ? '좌' : '우',
      GAAction: '클릭',
    });
  };

  return (
    <Container
      sx={{ mb: isDeskTop ? '60px' : '0px', width: '100%', overflow: 'hidden' }}
    >
      <RollingBannerBox
        sx={{
          width: isDeskTop ? '100%' : '100%',
          height: isDeskTop ? '320px' : '240px',
          borderRadius: isDeskTop ? '16px' : 0,
        }}
      >
        <Swiper
          className="mySwiper"
          modules={[Navigation, Autoplay]}
          spaceBetween={0}
          slidesPerView={1}
          loop={kvBanners.length > 1}
          centeredSlides
          autoplay={{
            delay: 2000,
            disableOnInteraction: false,
          }}
          navigation={{
            prevEl: navigationPrevRef.current,
            nextEl: navigationNextRef.current,
          }}
          allowTouchMove={kvBanners.length > 1}
          onSlideChange={swiper => handleChangeStep(swiper.activeIndex)}
        >
          {kvBanners &&
            kvBanners.map((banner, idx) => {
              return (
                <SwiperSlide
                  key={`kvbanner-${banner.image_url}-${banner.title}`}
                  data-ec-list={gaCode}
                  data-promo-event
                  data-promo-id={banner.image_url}
                  data-promo-name={null}
                  data-promo-position={`${gaCode}_${idx}`}
                >
                  <ImageBox
                    sx={{
                      backgroundImage: `url(${banner.image_url})`,
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'cover',
                      backgroundPosition: '50% 50%',
                      height: isDeskTop ? '320px' : '240px',
                      borderRadius: isDeskTop && '8px',
                      cursor: 'pointer',
                    }}
                    onClick={onClick}
                  />
                </SwiperSlide>
              );
            })}
        </Swiper>
      </RollingBannerBox>
      <NaviBarBox
        sx={{
          width: isDeskTop ? '580px' : '100%',
          left: isDeskTop ? '60px' : '20px',
          bottom: isDeskTop ? '25px' : '15px',
        }}
      >
        <BtnBox>
          <IconButton
            ref={navigationPrevRef}
            onClick={() => moveBanner('left')}
            sx={{ '&:hover': { backgroundColor: 'transparent' } }}
          >
            <ArrowBackIosIcon
              sx={{ color: '#fff', width: '13px', height: '13px' }}
            />
          </IconButton>
          <Span sx={{ fontWeight: '700' }}>
            {showCurrentStep(currentIndex + 1)}
          </Span>
          <Span>/</Span>
          <Span>{kvBanners?.length}</Span>
          <IconButton
            ref={navigationNextRef}
            onClick={() => moveBanner('right')}
            sx={{ '&:hover': { backgroundColor: 'transparent' } }}
          >
            <ArrowForwardIosIcon
              sx={{ color: '#fff', width: '13px', height: '13px' }}
            />
          </IconButton>
        </BtnBox>
        <RollingBannerNaviBar
          currentStep={currentIndex + 1}
          slideLength={kvBanners?.length}
        />
      </NaviBarBox>
    </Container>
  );
};

WideRollingBanner.defaultProps = {
  kvBanners: null,
  gaCode: null,
};

WideRollingBanner.propTypes = {
  kvBanners: PropTypes.arrayOf(
    PropTypes.shape({
      bgcolor: PropTypes.string,
      image_link: PropTypes.string,
      image_url: PropTypes.string,
      popup_yn: PropTypes.string,
      title: PropTypes.string,
    }),
  ),
  currentIndex: PropTypes.number.isRequired,
  setCurrentIndex: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  gaCode: PropTypes.string,
};

const Container = styled(Box)({
  // border: '1px solid blue',
  display: 'flex',
  justifyContent: 'flex-end',
  width: '100%',
  position: 'relative',
});

const RollingBannerBox = styled(Box)({
  // border: '2px solid blue',
  width: '100%',
});
const ImageBox = styled(Box)({
  // border: '2px solid red',
  width: '100%',
});
const NaviBarBox = styled(Box)({
  // border: '1px solid red',
  position: 'absolute',
  bottom: '15px',
  zIndex: 2,
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
});
const BtnBox = styled('div')({
  // border: '1px solid red',
  display: 'flex',
  justifyContent: 'flex-start',
  position: 'relative',
  alignItems: 'center',
});
const Span = styled('span')({
  // border: '1px solid red',
  marginTop: '2px',
  width: '13px',
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: '#fff',
  fontSize: '13px',
  fontWeight: 400,
});

export default WideRollingBanner;
