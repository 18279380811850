import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  styled,
  Box,
  IconButton,
  useTheme,
  useMediaQuery,
  Dialog,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
// import qs from 'qs';
import Viewer from '../../lib/Editor/Viewer';
import { getDayOfWeekString } from './calendar_util';
import { ReactComponent as IconClose } from '../../assets/images/calendar/ic_close_24.svg';
import { boardApi } from '../../api/board';

const ScheduleTypeBoxRoot = styled(Box)({
  fontWeight: 700,
  fontSize: '10px',
  lineHeight: '14px',
  color: 'white',
  padding: '2px 4px',
  borderRadius: '3px',
});

const ScheduleTypeBox = ({ type }) => {
  const theme = useTheme();
  const isDeskTop = useMediaQuery(theme.breakpoints.up('desktop'));
  let color = 'grey';
  let strType = '';
  if (type === 'red') {
    color = '#F25555';
    strType = isDeskTop ? '이벤트/혜택' : '이벤트';
  } else if (type === 'orange') {
    color = '#EE9D3D';
    strType = isDeskTop ? '와디즈 스쿨' : '스쿨';
  } else if (type === 'green') {
    color = '#00B2B2';
    strType = '기획전';
  } else if (type === 'blue') {
    color = '#4167D9';
    strType = isDeskTop ? '서비스/정책' : '정책';
  } else if (type === 'purple') {
    color = '#8D4AE1';
    strType = '스토어 정산';
  }

  return (
    <ScheduleTypeBoxRoot sx={{ backgroundColor: color }}>
      {strType}
    </ScheduleTypeBoxRoot>
  );
};
ScheduleTypeBox.propTypes = {
  type: PropTypes.string.isRequired,
};

const MoveButton = styled(Box)({
  fontWeight: 500,
  fontSize: '16px',
  lineHeight: '28px',
  color: '#00C4C4',
  paddingTop: '9px',
  paddingBottom: '9px',
  borderRadius: '3px',
  border: '1px solid #00C4C4',
  cursor: 'pointer',
  textAlign: 'center',
  marginTop: '24px',
});

const CalendarScheduleDialog = ({ open, onClose, schedule, scheduleIdx }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isDeskTop = useMediaQuery(theme.breakpoints.up('desktop'));
  const [tags, setTags] = useState([]);
  const [buttonInfo, setButtonInfo] = useState(null);
  useEffect(() => {
    if (scheduleIdx) {
      const requestList = async () => {
        try {
          const param = { board_type: 'calendar', idx: scheduleIdx };
          const { data } = await boardApi.basicDetail(param);
          // orange일 경우 와디즈 스쿨
          const addParam =
            schedule.calendar_type === 'orange' &&
            data.data.board_data.btn_name === '신청하기'
              ? '?utm_source=makercenter&utm_medium=quickmenu&utm_campaign=wadiz_school'
              : '';
          setButtonInfo({
            btn_link_url: data.data.board_data.btn_link_url + addParam,
            btn_name: data.data.board_data.btn_name,
            btn_popup_yn: data.data.board_data.btn_popup_yn,
            btn_use_yn: data.data.board_data.btn_use_yn,
          });
          setTags(data.data.board_data.hash_tag);
          // console.log(data.data);
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error(err.message);
        }
      };
      requestList();
    }
  }, [scheduleIdx]);
  const handleClickDetail = () => {
    window.dataLayer.push({
      event: 'ga.custom.tag',
      GACategory: '메이커캘린더_모달',
      GALabel: buttonInfo?.btn_name || null,
      GAAction: '클릭',
    });
    if (schedule.calendar_type === 'orange') {
      window.dataLayer.push({
        event: 'ga.custom.tag',
        GACategory: '메이커캘린더',
        GALabel: '와디즈스쿨',
        GAAction: '클릭',
      });
    }

    if (buttonInfo) {
      const match = buttonInfo.btn_link_url.match(/^http(s)?:\/\//i);
      let link = buttonInfo.btn_link_url;
      if (!match) {
        link = `https://${buttonInfo.btn_link_url}`;
      }
      if (buttonInfo.btn_popup_yn === 'Y') {
        window.open(link, '_blank');
      } else {
        window.location.href = link;
      }
    }
  };
  const handleClickTag = tag => {
    navigate(`/search/${encodeURIComponent(tag)}`);
  };
  const startMoment = moment(schedule.start_date);
  const endMoment = moment(schedule.end_date);

  let strPeriod = '일정 : ';
  if (schedule.startDateNumber === schedule.endDateNumber) {
    strPeriod = `일정 : ${startMoment.format(
      'YYYY.MM.DD',
    )}(${getDayOfWeekString(startMoment.day())})`;
  } else {
    strPeriod = `일정 : ${startMoment.format('MM.DD')}(${getDayOfWeekString(
      startMoment.day(),
    )}) ~ ${endMoment.format('MM.DD')}(${getDayOfWeekString(endMoment.day())})`;
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen={!isDeskTop}
      data-promo-modal
    >
      <Box
        sx={{
          minWidth: '240px',
          width: isDeskTop ? '480px' : 'auto',
          padding: '24px 24px 20px 24px',
          color: '#212529',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <ScheduleTypeBox type={schedule.calendar_type} />
          <IconButton sx={{ padding: 0 }} onClick={onClose}>
            <IconClose />
          </IconButton>
        </Box>
        <Box
          sx={{
            marginTop: '12px',
            fontWeight: 700,
            fontSize: '20px',
            lineHeight: '30px',
          }}
        >
          {schedule.title}
        </Box>
        <Box
          sx={{
            marginTop: '8px',
            fontWeight: 700,
            fontSize: '15px',
            lineHeight: '24px',
          }}
        >
          {strPeriod}
        </Box>
        <Box sx={{ marginTop: '8px' }}>
          <Viewer model={schedule.contents} />
        </Box>
        {tags.length > 0 && (
          <Box sx={{ margin: '22px -2px 0 -2px', display: 'flex' }}>
            {tags.map(tag => {
              return (
                <Box
                  key={`tag-${tag}`}
                  sx={{
                    margin: '2px',
                    fontWeight: 400,
                    fontSize: '12px',
                    lineHeight: '18px',
                    color: '#212529',
                    backgroundColor: '#F2F4F6',
                    borderRadius: '16px',
                    padding: '7px 12px',
                    cursor: 'pointer',
                  }}
                  onClick={() => handleClickTag(tag)}
                >
                  {`#${tag}`}
                </Box>
              );
            })}
          </Box>
        )}
        {buttonInfo && buttonInfo.btn_use_yn === 'Y' && (
          <MoveButton onClick={() => handleClickDetail()}>
            {buttonInfo.btn_name || ''}
          </MoveButton>
        )}
      </Box>
    </Dialog>
  );
};
CalendarScheduleDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  schedule: PropTypes.shape({
    calendar_type: PropTypes.string,
    idx: PropTypes.number,
    title: PropTypes.string,
    contents: PropTypes.string,
    start_date: PropTypes.string,
    end_date: PropTypes.string,
    startDateNumber: PropTypes.number,
    endDateNumber: PropTypes.number,
    rownum: PropTypes.number,
  }),
  scheduleIdx: PropTypes.number.isRequired,
};

CalendarScheduleDialog.defaultProps = {
  open: false,
  schedule: null,
};

export default CalendarScheduleDialog;
