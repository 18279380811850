import React from 'react';
import { styled, Box, useTheme, useMediaQuery } from '@mui/material';
import { ReactComponent as LogoIcon } from '../../assets/images/ic-wadizPC.svg';
import { ReactComponent as SchoolIcon } from '../../assets/images/m-edu.svg';
import { ReactComponent as PartnerIcon } from '../../assets/images/m-partner.svg';
import { ReactComponent as AdCenterIcon } from '../../assets/images/m-ad.svg';

const ShortCut = () => {
  const theme = useTheme();
  const isDeskTop = useMediaQuery(theme.breakpoints.up('desktop'));
  const onClickQuickMenu = name => {
    window.dataLayer.push({
      event: 'ga.custom.tag',
      GACategory: '홈_퀵메뉴',
      GALabel: name,
      GAAction: '클릭',
    });
  };
  return (
    <Container
      sx={{
        width: isDeskTop ? '610px' : '100%',
        mx: isDeskTop ? '5px' : '0',
        px: isDeskTop ? 0 : '16px',
        mb: isDeskTop ? 0 : '40px',
      }}
    >
      <Title
        sx={{
          fontSize: isDeskTop ? '24px' : '18px',
          mb: '16px',
        }}
      >
        Quick! 바로가기
      </Title>
      <FlexBox
        sx={{
          height: isDeskTop ? '168px' : '113px',
          pb: isDeskTop ? '40px' : '15px',
        }}
      >
        <Inner
          href="https://www.wadiz.kr/web/main"
          target="_blank"
          onClick={() => onClickQuickMenu('와디즈')}
        >
          <LogoIcon
            width={isDeskTop ? 54 : 43}
            height={isDeskTop ? 36 : 29}
            style={{ marginBottom: '5px' }}
          />
          <Text sx={{ fontSize: isDeskTop ? '16px' : '13px' }}>와디즈</Text>
        </Inner>
        <Inner
          href="https://www.wadiz.kr/web/school/main?utm_source=makercenter&utm_medium=quickmenu&utm_campaign=wadiz_school"
          target="_blank"
          onClick={() => {
            onClickQuickMenu('와디즈 스쿨');
          }}
        >
          <SchoolIcon
            width={isDeskTop ? 52 : 41}
            height={isDeskTop ? 44 : 35}
          />
          <Text sx={{ fontSize: isDeskTop ? '16px' : '13px' }}>
            와디즈 스쿨
          </Text>
        </Inner>
        <Inner
          href="https://www.partnerzone.wadiz.kr"
          target="_blank"
          onClick={() => onClickQuickMenu('파트너 서비스')}
        >
          <PartnerIcon
            width={isDeskTop ? 50 : 40}
            height={isDeskTop ? 44 : 35}
          />
          <Text sx={{ fontSize: isDeskTop ? '16px' : '13px' }}>
            파트너 서비스
          </Text>
        </Inner>
        <Inner
          href="https://business.wadiz.kr/"
          target="_blank"
          onClick={() => onClickQuickMenu('광고 서비스')}
        >
          <AdCenterIcon
            width={isDeskTop ? 47 : 37}
            height={isDeskTop ? 36 : 29}
            style={{ marginBottom: '5px' }}
          />
          <Text sx={{ fontSize: isDeskTop ? '16px' : '13px' }}>
            광고 서비스
          </Text>
        </Inner>
      </FlexBox>
    </Container>
  );
};

const Container = styled(Box)({
  // border: '1px solid dodgerblue',
  position: 'relative',
  // height: '218px',
});
const FlexBox = styled(Box)({
  // border: '1px solid red',
  width: '100%',
  minWidth: '320px',
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'flex-end',
  background: '#F2F4F6',
  border: 0,
  borderRadius: '8px',
});
const Inner = styled('a')({
  // border: '1px solid red',
  minWidth: '80px',
  textAlign: 'center',
  cursor: 'pointer',
  textDecoration: 'none',
});
const Title = styled(Box)({
  fontSize: '24px',
  fontWeight: 700,
  color: '#212529',
  lineHeight: '34px',
  marginBottom: '16px',
});
const Text = styled(Box)({
  fontWeight: 700,
  fontSize: '16px',
  color: '#212529',
  lineHeight: '28px',
  '&:hover': {
    color: '#00C4C4',
  },
});

export default ShortCut;

// const Box = styled(motion.div)({
//   width: '100px',
//   height: '100px',
//   backgroundColor: 'red',
// }); <Box whileHover={{ scale: 1.2 }} />
