import React from 'react';
import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';

const CommonHelmet = ({ title, url, image }) => {
  const titleFix = `${title} - 와디즈 메이커센터`;
  return (
    <Helmet>
      <meta name="title" content={titleFix} data-react-helmet="true" />
      <meta property="og:title" content={titleFix} />
      <meta property="og:url" content={url} />
      <meta name="twitter:title" content={titleFix} />
      <meta name="twitter:url" content={url} />
      <meta property="og:image" content={image} />
      <meta property="og:image:width" content="302" />
      <meta property="og:image:height" content="160" />
      <title>{title}</title>
    </Helmet>
  );
};

CommonHelmet.propTypes = {
  title: PropTypes.string,
  //  description: PropTypes.string,
  url: PropTypes.string,
  image: PropTypes.string,
};

CommonHelmet.defaultProps = {
  title: '와디즈 메이커센터',
  // description: '프로젝트 진행에 꼭 필요한 정보를 먼저 확인해 보세요.',
  url: '',
  image: '',
};

export default CommonHelmet;
