import { React } from 'react';
import { styled } from '@mui/system';
import { Divider, Typography, Box, Button } from '@mui/material';

const AfilliateTerms = styled(Button)(() => ({
  height: '24px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  transition: 'none',
  color: 'black',
  padding: '0px',
  marginBottom: '8px',
  '&:hover': {
    backgroundColor: 'white',
  },
}));

const AfilliateTermsExcept = styled(Box)(() => ({
  height: '24px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  transition: 'none',
  color: 'black',
  padding: '0px',
  marginBottom: '8px',
}));

const SubTitle = styled(Typography)(() => ({
  fontWeight: '400',
  fontSize: '15px',
  lineHeight: '24px',
}));
const afilliateBoxStyle = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'start',
  alignItems: 'flex-start',
  height: '56px',
  padding: '0px 0px 8px',
  marginBottom: '20px',
};

const FontStyle = {
  fontStyle: 'normal',
  fontWeight: '700',
  fontSize: '15px',
  lineHeight: '24px',
  color: '#495057',
};

const AfilliateBox = () => {
  return (
    <Box sx={{ padding: '8px 0px' }}>
      <Box sx={{ afilliateBoxStyle }}>
        <Typography sx={{ FontStyle }}>광고 서비스 문의</Typography>
        <AfilliateTermsExcept>
          <Typography
            onClick={() => {
              window.open('mailto:ad@wadiz.kr');
            }}
            sx={{ cursor: 'pointer' }}
          >
            ad@wadiz.kr
          </Typography>
          <Divider
            orientation="vertical"
            variant="middle"
            sx={{
              margin: '0px',
              marginLeft: '10px',
              marginRight: '10px',
              height: '10px',
            }}
          />
          <SubTitle
            onClick={() => {
              window.open('https://business.wadiz.kr/', '_blank');
            }}
            sx={{ cursor: 'pointer' }}
          >
            광고상품보기
          </SubTitle>
        </AfilliateTermsExcept>
      </Box>
      <Box sx={{ afilliateBoxStyle }}>
        <Typography sx={{ FontStyle }}>제휴 문의</Typography>
        <AfilliateTerms
          onClick={() => {
            window.open('mailto:partner@wadiz.kr');
          }}
        >
          <Typography>partner@wadiz.kr</Typography>
        </AfilliateTerms>
      </Box>
      <Box sx={{ afilliateBoxStyle }}>
        <Typography sx={{ FontStyle }}>마켓팅 제휴/PR 문의</Typography>
        <AfilliateTerms
          onClick={() => {
            window.open('mailto:mktpr@wadiz.kr');
          }}
        >
          <Typography>mktpr@wadiz.kr</Typography>
        </AfilliateTerms>
      </Box>
      <Box sx={{ afilliateBoxStyle }}>
        <Typography sx={{ FontStyle }}>IP 제휴 문의</Typography>
        <AfilliateTerms
          onClick={() => {
            window.open('mailto:ip@wadiz.kr');
          }}
        >
          <Typography>ip@wadiz.kr</Typography>
        </AfilliateTerms>
      </Box>
    </Box>
  );
};

export default AfilliateBox;
export { SubTitle };
