import { React } from 'react';
import { styled } from '@mui/system';
import { Typography, Button } from '@mui/material';
import { ReactComponent as LinkIcon } from '../../../assets/images/ArrowSquareOut.svg';

const Terms = styled(Button)(() => ({
  height: '40px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  transition: 'none',
  color: 'black',
  '&:hover': {
    backgroundColor: 'white',
  },
}));
const TermsText = styled(Typography)(() => ({
  fontSize: '15px',
  lineHieght: '24px',
  marginRight: '5px',
}));
const TermsBox = () => {
  return (
    <>
      <Terms
        onClick={() => {
          window.open('https://www.wadiz.kr/web/wterms/signup', '_blank');
        }}
      >
        <TermsText>공통 정책·약관</TermsText>
        <LinkIcon />
      </Terms>
      <Terms
        onClick={() => {
          window.open(
            'https://www.wadiz.kr/web/wterms/service_reward',
            '_blank',
          );
        }}
      >
        <TermsText>와디즈 정책·약관</TermsText>
        <LinkIcon />
      </Terms>
      <Terms
        onClick={() => {
          window.open(
            'https://www.wadiz.kr/web/wterms/service_invest',
            '_blank',
          );
        }}
      >
        <TermsText>와디즈파이낸스 정책·약관</TermsText>
        <LinkIcon />
      </Terms>
    </>
  );
};

export default TermsBox;
