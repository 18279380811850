import React from 'react';
import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { WadizFooter } from './WadizFooter';
import Header from './Header';
import CommonHelmet from '../common/CommonHelmet';

const Root = () => {
  return (
    <Box>
      <CommonHelmet
        title="와디즈 메이커센터"
        url={process.env.REACT_APP_SITE_URL}
      />
      <Header />
      <Outlet />
      <WadizFooter />
    </Box>
  );
};

export default Root;
