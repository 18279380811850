import React, { useContext, useMemo } from 'react';
import dayjs from 'dayjs';
import {
  styled,
  Box,
  IconButton,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { menuState } from '../../atoms';
import {
  homeContext,
  getfindBoard,
  numberOfThisWeek,
  makeDotColor,
} from '../../lib/utils';
import { ReactComponent as RightArrowIcon } from '../../assets/images/ic-rightArrow.svg';

const MakerCalendar = () => {
  const { board } = useContext(homeContext);
  const navigate = useNavigate();
  const theme = useTheme();
  const isDeskTop = useMediaQuery(theme.breakpoints.up('desktop'));
  const menuList = useRecoilValue(menuState);

  // console.log('board.calandar :', board.calendar);
  // console.log(moment());

  // 오늘날짜에 까만원 덮기 위한 배열 만들기 : [01, 1] 이렇게 둘다 인식해야 함
  const todayDateArr = useMemo(() => {
    const today = dayjs().format('DD');
    let today2 = [];
    if (today[0] === '0') {
      const arrTempDayNum = Array.from(today);
      arrTempDayNum.shift();
      today2 = [...arrTempDayNum[0]];
    }
    return [today, ...today2];
  }, []);

  // 0N으로 된 날짜에 대해 두가지 형식 모두 포괄하는 배열 만들기
  const makeTwoCaseDates = date => {
    let datesArr = [];
    const theDate = dayjs(date).format('DD');
    if (theDate[0] === '0') {
      const arrTempDayNum = Array.from(theDate);
      arrTempDayNum.shift();
      datesArr = [...arrTempDayNum[0]];
    }
    return [theDate, ...datesArr];
  };

  // 캘린더로 이동 by '더보기', '날짜'
  const onToCalendarBoard = () => {
    const currentMenu = getfindBoard(menuList, board.calendar.board_idx);
    navigate(`/menu/${currentMenu.menu_idx}`);
  };

  const onClickMoreButton = () => {
    window.dataLayer.push({
      event: 'ga.custom.tag',
      GACategory: '홈_메이커캘린더',
      GALabel: '더보기',
      GAAction: '클릭',
    });
  };

  // 이번주 날짜들 만들기
  const makeDates = nextNum => {
    let dayNum;
    if (nextNum === undefined) {
      const tempDayNum = dayjs(board.calendar.calendar_start_date).format('DD');
      if (tempDayNum[0] === '0') {
        const arrTempDayNum = Array.from(tempDayNum);
        arrTempDayNum.shift();
        const newDayNum = arrTempDayNum[0];
        dayNum = newDayNum;
      } else {
        dayNum = tempDayNum;
      }
    } else {
      const tempDayNum = dayjs(board.calendar.calendar_start_date)
        .add(nextNum, 'days')
        .format('DD');
      if (tempDayNum[0] === '0') {
        const arrTempDayNum = Array.from(tempDayNum);
        arrTempDayNum.shift();
        // arrTempDayNum.join();
        const newDayNum = arrTempDayNum[0];
        dayNum = newDayNum;
      } else {
        dayNum = tempDayNum;
      }
    }
    return dayNum;
  };

  // console.log('오늘 몇일?', makeDates());

  return (
    <Container
      sx={{
        // border: '1px solid red',
        px: isDeskTop ? 0 : '16px',
        width: isDeskTop ? '610px' : '100%',
        minWidth: isDeskTop ? '550px' : '320px',
        mx: isDeskTop ? '5px' : '0',
      }}
    >
      <TitleBox sx={{ justifyContent: 'space-between', pb: '10px' }}>
        <Title
          sx={{
            fontSize: isDeskTop ? '24px' : '18px',
            mb: '5px',
          }}
        >
          메이커 캘린더
        </Title>
        <FlexBox onClick={onToCalendarBoard} sx={{ cursor: 'pointer' }}>
          <MoreViewText onClick={onClickMoreButton}>더보기</MoreViewText>
          <RightArrowIcon />
        </FlexBox>
      </TitleBox>
      <InfoBox>
        <FlexBox
          sx={{
            alignItems: 'flex-end',
            whiteSpace: 'nowrap',
          }}
        >
          <WeekNumber sx={{ fontSize: isDeskTop ? '18px' : '16px' }}>
            {dayjs().format('MM')}월&nbsp;
            {numberOfThisWeek(new Date(board.calendar.calendar_start_date))}주차
          </WeekNumber>
          <DateNumber sx={{ display: isDeskTop ? 'block' : 'none' }}>
            ({dayjs(board.calendar.calendar_start_date).format('MM.DD')}~
            {dayjs(board.calendar.calendar_end_date).format('MM.DD')})
          </DateNumber>
        </FlexBox>
        <FlexBox>
          <FlexBox>
            <Dot sx={{ background: '#F25555' }} />
            <Text
              sx={{
                fontSize: isDeskTop ? '13px' : '12px',
                mr: isDeskTop ? '10px' : '3px',
              }}
            >
              {isDeskTop ? '이벤트/혜택' : '이벤트'}
            </Text>
          </FlexBox>
          <FlexBox>
            <Dot sx={{ background: '#EE9D3D' }} />
            <Text
              sx={{
                fontSize: isDeskTop ? '13px' : '12px',
                mr: isDeskTop ? '10px' : '3px',
              }}
            >
              {isDeskTop ? '와디즈 스쿨' : '스쿨'}
            </Text>
          </FlexBox>
          <FlexBox>
            <Dot sx={{ background: '#00B2B2' }} />
            <Text
              sx={{
                fontSize: isDeskTop ? '13px' : '12px',
                mr: isDeskTop ? '10px' : '3px',
              }}
            >
              기획전
            </Text>
          </FlexBox>
          <FlexBox>
            <Dot sx={{ background: '#4167D9' }} />
            <Text
              sx={{
                fontSize: isDeskTop ? '13px' : '12px',
                mr: isDeskTop ? '10px' : '3px',
              }}
            >
              {isDeskTop ? '서비스/정책' : '정책'}
            </Text>
          </FlexBox>
          <FlexBox>
            <Dot sx={{ background: '#8D4AE1' }} />
            <Text
              sx={{
                fontSize: isDeskTop ? '13px' : '12px',
                mr: isDeskTop ? 0 : '3px',
              }}
            >
              스토어 정산
            </Text>
          </FlexBox>
        </FlexBox>
      </InfoBox>
      <CalBox>
        <Box sx={{ width: '100%' }}>
          <Head sx={{ borderLeft: 0 }}>월</Head>
          <Body sx={{ borderLeft: 0 }}>
            <DateValue
              sx={{
                background: todayDateArr.includes(makeDates()) && '#000',
                color: todayDateArr.includes(makeDates()) && '#fff',
                '&:hover': {
                  background: todayDateArr.includes(makeDates()) && '#666666',
                },
              }}
              onClick={onToCalendarBoard}
            >
              {makeDates()}
            </DateValue>
            <DotBox>
              {board.calendar.lists
                .filter(item =>
                  makeTwoCaseDates(item.start_date).includes(makeDates()),
                )
                .map(item => {
                  return (
                    <Dot
                      key={item.idx}
                      sx={{ background: makeDotColor(item.calendar_type) }}
                    />
                  );
                })}
            </DotBox>
          </Body>
        </Box>
        <Box sx={{ width: '100%' }}>
          <Head>화</Head>
          <Body>
            <DateValue
              sx={{
                background: todayDateArr.includes(makeDates(1)) && '#000',
                color: todayDateArr.includes(makeDates(1)) && '#fff',
                '&:hover': {
                  background: todayDateArr.includes(makeDates(1)) && '#666666',
                },
              }}
              onClick={onToCalendarBoard}
            >
              {makeDates(1)}
            </DateValue>
            <DotBox>
              {board.calendar.lists
                .filter(item =>
                  makeTwoCaseDates(item.start_date).includes(makeDates(1)),
                )
                .map(item => {
                  return (
                    <Dot
                      key={item.idx}
                      sx={{ background: makeDotColor(item.calendar_type) }}
                    />
                  );
                })}
            </DotBox>
          </Body>
        </Box>
        <Box sx={{ width: '100%' }}>
          <Head>수</Head>
          <Body>
            <DateValue
              sx={{
                background: todayDateArr.includes(makeDates(2)) && '#000',
                color: todayDateArr.includes(makeDates(2)) && '#fff',
                '&:hover': {
                  background: todayDateArr.includes(makeDates(2)) && '#666666',
                },
              }}
              onClick={onToCalendarBoard}
            >
              {makeDates(2)}
            </DateValue>
            <DotBox>
              {board.calendar.lists
                .filter(item =>
                  makeTwoCaseDates(item.start_date).includes(makeDates(2)),
                )
                .map(item => {
                  return (
                    <Dot
                      key={item.idx}
                      sx={{ background: makeDotColor(item.calendar_type) }}
                    />
                  );
                })}
            </DotBox>
          </Body>
        </Box>
        <Box sx={{ width: '100%' }}>
          <Head>목</Head>
          <Body>
            <DateValue
              sx={{
                background: todayDateArr.includes(makeDates(3)) && '#000',
                color: todayDateArr.includes(makeDates(3)) && '#fff',
                '&:hover': {
                  background: todayDateArr.includes(makeDates(3)) && '#666666',
                },
              }}
              onClick={onToCalendarBoard}
            >
              {makeDates(3)}
            </DateValue>
            <DotBox>
              {board.calendar.lists
                .filter(item =>
                  makeTwoCaseDates(item.start_date).includes(makeDates(3)),
                )
                .map(item => {
                  return (
                    <Dot
                      key={item.idx}
                      sx={{ background: makeDotColor(item.calendar_type) }}
                    />
                  );
                })}
            </DotBox>
          </Body>
        </Box>
        <Box sx={{ width: '100%' }}>
          <Head>금</Head>
          <Body>
            <DateValue
              sx={{
                background: todayDateArr.includes(makeDates(4)) && '#000',
                color: todayDateArr.includes(makeDates(4)) && '#fff',
                '&:hover': {
                  background: todayDateArr.includes(makeDates(4)) && '#666666',
                },
              }}
              onClick={onToCalendarBoard}
            >
              {makeDates(4)}
            </DateValue>
            <DotBox>
              {board.calendar.lists
                .filter(item =>
                  makeTwoCaseDates(item.start_date).includes(makeDates(4)),
                )
                .map(item => {
                  return (
                    <Dot
                      key={item.idx}
                      sx={{ background: makeDotColor(item.calendar_type) }}
                    />
                  );
                })}
            </DotBox>
          </Body>
        </Box>
        <Box sx={{ width: '100%' }}>
          <Head sx={{ color: '#557CF2' }}>토</Head>
          <Body sx={{ color: '#557CF2' }}>
            <DateValue
              sx={{
                background: todayDateArr.includes(makeDates(5)) && '#000',
                color: todayDateArr.includes(makeDates(5)) ? '#fff' : '#557CF2',
                '&:hover': {
                  background: todayDateArr.includes(makeDates(5)) && '#666666',
                },
              }}
              onClick={onToCalendarBoard}
            >
              {makeDates(5)}
            </DateValue>
            <DotBox>
              {board.calendar.lists
                .filter(item =>
                  makeTwoCaseDates(item.start_date).includes(makeDates(5)),
                )
                .map(item => {
                  return (
                    <Dot
                      key={item.idx}
                      sx={{ background: makeDotColor(item.calendar_type) }}
                    />
                  );
                })}
            </DotBox>
          </Body>
        </Box>
        <Box sx={{ width: '100%' }}>
          <Head sx={{ color: '#FF6666', borderRight: 0 }}>일</Head>
          <Body sx={{ color: '#FF6666', borderRight: 0 }}>
            <DateValue
              sx={{
                background: todayDateArr.includes(makeDates(6)) && '#000',
                color: todayDateArr.includes(makeDates(6)) ? '#fff' : '#FF6666',
                '&:hover': {
                  background: todayDateArr.includes(makeDates(6)) && '#666666',
                },
              }}
              onClick={onToCalendarBoard}
            >
              {makeDates(6)}
            </DateValue>
            <DotBox>
              {board.calendar.lists
                .filter(item =>
                  makeTwoCaseDates(item.start_date).includes(makeDates(6)),
                )
                .map(item => {
                  return (
                    <Dot
                      key={item.idx}
                      sx={{ background: makeDotColor(item.calendar_type) }}
                    />
                  );
                })}
            </DotBox>
          </Body>
        </Box>
      </CalBox>
    </Container>
  );
};

const Container = styled(Box)({
  // border: '1px solid dodgerblue',
  position: 'relative',
  height: '218px',
  minWidth: '320px',
});
const FlexBox = styled(Box)({
  // border: '1px solid powderblue',
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  // cursor: 'pointer',
});
const TitleBox = styled(Box)({
  // border: '1px solid powderblue',
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderBottom: '1px solid #f2f2f2',
  marginBottom: '20px',
});
const Title = styled(Box)({
  fontSize: '24px',
  fontWeight: 700,
  color: '#212529',
  lineHeight: '34px',
});
const MoreViewText = styled(Box)({
  // border: '1px solid red',
  marginRight: '8px',
  color: '#000',
  fontSize: '14px',
  fontWeight: 400,
  display: 'flex',
  alignItems: 'center',
});
const InfoBox = styled(Box)({
  // border: '1px solid powderblue',
  position: 'relative',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '8px',
});
const WeekNumber = styled(Box)({
  fontSize: '18px',
  fontWeight: 700,
  color: '#212529',
  marginRight: '5px',
});
const DateNumber = styled(Box)({
  fontSize: '15px',
  fontWeight: 400,
  color: '#212529',
});
const Dot = styled('div')({
  display: 'inline-block',
  marginRight: '1px',
  width: '6px',
  height: '6px',
  border: 0,
  borderRadius: '50%',
});
const Text = styled(Box)({
  // border: '1px solid powderblue',
  fontSize: '13px',
  fontWeight: 400,
  color: '#495057',
  margin: '1px 10px 0 3px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  letterSpacing: '-0.3px',
});
const CalBox = styled(Box)({
  // border: '1px solid powderblue',
  width: '100%',
  position: 'relative',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
});
const Head = styled(Box)({
  fontSize: '14px',
  fontWeight: 500,
  color: '#495057',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  // maxWidth: '87px',
  maxWidth: '149px',
  minWidth: '30px',
  padding: '',
  // width: '87px',
  height: '32px',
  background: '#F2F4F6',
  borderRight: '1px solid #E6EAED',
});
const Body = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  maxWidth: '149px',
  // maxWidth: '87px',
  minWidth: '30px',
  // width: '87px',
  height: '78px',
  borderRight: '1px solid #E6EAED',
  borderBottom: '1px solid #E6EAED',
});
const DateValue = styled(IconButton)({
  width: '32px',
  height: '32px',
  margin: '15px 0 10px',
  padding: '1px 0 0 0',
  fontSize: '15px',
  fontWeight: 800,
  color: '#495057',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});
const DotBox = styled(Box)({
  // border: '1px solid red',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export default MakerCalendar;

// const Box = styled(motion.div)({
//   width: '100px',
//   height: '100px',
//   backgroundColor: 'red',
// }); <Box whileHover={{ scale: 1.2 }} />
