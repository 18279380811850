/* eslint-disable no-param-reassign */
import React, { useRef, useContext, useCallback, useState } from 'react';
import LinesEllipsis from 'react-lines-ellipsis';
import qs from 'qs';
import { useNavigate } from 'react-router-dom';
import { styled, Box, useTheme, useMediaQuery } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Autoplay } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import 'swiper/components/navigation/navigation.min.css';
import { homeContext, getfindBoard } from '../../lib/utils';
import { menuState } from '../../atoms';
import { ReactComponent as LeftClickIcon } from '../../assets/images/ic-leftClick.svg';
import { ReactComponent as RightClickIcon } from '../../assets/images/ic-rightClick.svg';
import { ReactComponent as RightArrowIcon } from '../../assets/images/ic-rightArrow.svg';

const Academy = () => {
  const { board } = useContext(homeContext);
  const [currentStep, setCurrentStep] = useState(1);
  const menuList = useRecoilValue(menuState);
  const navigate = useNavigate();
  SwiperCore.use([Navigation, Autoplay]); // swiper 기능 임포트
  const navigationPrevRef = useRef(null); // swiper 커스텀 네비버튼
  const navigationNextRef = useRef(null); // swiper 커스텀 네비버튼
  const theme = useTheme();
  const isDeskTop = useMediaQuery(theme.breakpoints.up('desktop'));

  // console.log('board : ', board.card2);

  // 네비 바의 움직여야 할 퍼센티지 계산
  const movingPoint = useCallback(
    currentIndex => {
      let movingRatio;
      if (currentIndex === 1 || currentIndex === board.card2.lists.length + 1) {
        movingRatio = 0;
      } else if (currentIndex === 0) {
        movingRatio = `${
          (100 / board.card2.lists.length) * (board.card2.lists.length - 1)
        }`;
      } else {
        movingRatio = `${
          (100 / board.card2.lists.length) * (currentIndex - 1)
        }`;
      }
      return movingRatio;
    },
    [
      currentStep,
      board,
      // board.card2.lists.length
    ],
  );

  // 아카데미 게시판으로 이동 by '더보기'
  const onToAcademyBoard = () => {
    const currentMenu = getfindBoard(menuList, board.card2.board_idx);
    navigate(`/menu/${currentMenu.menu_idx}`);
  };

  // 아케데미 상세내용으로 이동
  const onToAcademyBoardDetail = useCallback(
    (listIdx, listRownum) => {
      const data = {
        rownum: listRownum,
        search_type: null,
        search_text: null,
        category1_idx: null,
        category2_idx: null,
        board_id: board.card2.board_idx,
      };
      const query = qs.stringify(data);
      navigate(`/board/${board.card2.board_type}/${listIdx}?${query}`);
    },
    [board],
  );

  return (
    <Container
      sx={{
        mb: isDeskTop ? '60px' : '40px',
        px: isDeskTop ? 0 : '8px',
      }}
    >
      <InnerBox>
        <LeftClickBtn
          type="button"
          ref={navigationPrevRef}
          sx={{
            display:
              isDeskTop && board.card2.lists.length >= 5 ? 'block' : 'none',
          }}
        >
          <LeftClickIcon />
        </LeftClickBtn>
        <RightClickBtn
          type="button"
          ref={navigationNextRef}
          sx={{
            display:
              isDeskTop && board.card2.lists.length >= 5 ? 'block' : 'none',
          }}
        >
          <RightClickIcon />
        </RightClickBtn>
        <Inner
          sx={{
            maxWidth: isDeskTop ? '1280px' : '100%',
            minWidth: isDeskTop ? '1090px' : '100%',
          }}
        >
          <TitleBox
            sx={{
              justifyContent: 'space-between',
              pb: '10px',
            }}
          >
            <Title
              sx={{
                fontSize: isDeskTop ? '24px' : '18px',
                mb: '5px',
              }}
            >
              와디즈 스쿨
            </Title>
            <FlexBox onClick={onToAcademyBoard}>
              <MoreViewText>더보기</MoreViewText>
              <RightArrowIcon />
            </FlexBox>
          </TitleBox>
          {/* 빈배열 일때, 데스크탑 && 모바일 */}
          {board.card2.lists.length < 1 && (
            <EmptyBox
              sx={{
                display: board.card2.lists.length >= 4 ? 'none' : 'flex',
                height: isDeskTop ? '274px' : '160px',
                fontSize: isDeskTop ? '20px' : '16px',
              }}
            >
              성공 메이커 양성소
              <br />
              와디즈 스쿨
            </EmptyBox>
          )}
          {/* 게시물 1개 이고, 모바일 */}
          {board.card2.lists.length >= 1 &&
            board.card2.lists.length < 2 &&
            isDeskTop === false && (
              <ItemBox
                sx={{
                  height: isDeskTop ? '273px' : '290px',
                }}
              >
                <ImageBox
                  sx={{
                    backgroundImage: `url(${board.card2.lists[0].image_url})`,
                    maxWidth: isDeskTop ? '300px' : '100%',
                    height: isDeskTop ? '160px' : '174px',
                  }}
                  onClick={() => {
                    onToAcademyBoardDetail(
                      board.card2.lists[0].idx,
                      board.card2.lists[0].rownum,
                    );
                  }}
                />
                <AcademyTitle
                  onClick={() => {
                    onToAcademyBoardDetail(
                      board.card2.lists[0].idx,
                      board.card2.lists[0].rownum,
                    );
                  }}
                  sx={{ width: isDeskTop ? '295px' : '92vw' }}
                >
                  <LinesEllipsis
                    text={board.card2.lists[0].title}
                    maxLine="2"
                    ellipsis="..."
                    trimRight
                    basedOn="letters"
                  />
                </AcademyTitle>
                <Summary
                  sx={{
                    width: isDeskTop ? '290px' : '92vw',
                  }}
                >
                  <LinesEllipsis
                    text={board.card2.lists[0].summary}
                    maxLine="2"
                    ellipsis="..."
                    trimRight
                    basedOn="letters"
                  />
                </Summary>
              </ItemBox>
            )}
          {/* 1~3개의 게시물이 있고, 데스크탑 일 때 */}
          {isDeskTop &&
            board.card2.lists.length >= 1 &&
            board.card2.lists.length < 4 && (
              <FlexBox>
                {board.card2.lists.map(brd => {
                  return (
                    <ItemBox
                      key={`academy-${brd.idx}-${brd.image_url}`}
                      sx={{
                        height: isDeskTop ? '273px' : '290px',
                        mr: '24px',
                      }}
                    >
                      <ImageBox
                        sx={{
                          backgroundImage: `url(${brd.image_url})`,
                          maxWidth: isDeskTop ? '300px' : '100%',
                          height: isDeskTop ? '160px' : '174px',
                        }}
                        onClick={() => {
                          onToAcademyBoardDetail(brd.idx, brd.rownum);
                        }}
                      />
                      <AcademyTitle
                        onClick={() => {
                          onToAcademyBoardDetail(brd.idx, brd.rownum);
                        }}
                        sx={{ width: isDeskTop ? '295px' : '92vw' }}
                      >
                        <LinesEllipsis
                          text={brd.title}
                          maxLine="2"
                          ellipsis="..."
                          trimRight
                          basedOn="words"
                        />
                      </AcademyTitle>
                      <Summary
                        sx={{
                          width: isDeskTop ? '290px' : '92vw',
                        }}
                      >
                        <LinesEllipsis
                          text={brd.summary}
                          maxLine="2"
                          ellipsis="..."
                          trimRight
                          basedOn="words"
                        />
                      </Summary>
                    </ItemBox>
                  );
                })}
                <EmptyBox
                  sx={{
                    display: board.card2.lists.length >= 4 ? 'none' : 'flex',
                    height: isDeskTop ? '274px' : '160px',
                    fontSize: isDeskTop ? '20px' : '16px',
                  }}
                >
                  성공 메이커 양성소
                  <br />
                  와디즈 스쿨
                </EmptyBox>
              </FlexBox>
            )}
          {/* 게시물 2개 이상인 모바일 || 게시물 4개 이상인 데스크탑 */}
          {((board.card2.lists.length >= 2 && isDeskTop === false) ||
            board.card2.lists.length >= 4) && (
            <Swiper
              className="mySwiper"
              modules={[Navigation, Autoplay]}
              spaceBetween={24}
              slidesPerView={isDeskTop ? 4 : 1}
              loop
              navigation={{
                prevEl: navigationPrevRef.current,
                nextEl: navigationNextRef.current,
              }}
              onBeforeInit={swiper => {
                swiper.params.navigation.prevEl = navigationPrevRef.current;
                swiper.params.navigation.nextEl = navigationNextRef.current;
              }}
              // autoplay={{
              //   delay: 5000,
              //   disableOnInteraction: false,
              // }}
              onSlideChange={swiper => setCurrentStep(swiper.activeIndex)}
            >
              {board &&
                board.card2.lists.map(brd => {
                  return (
                    <SwiperSlide key={`home-academy-${brd.idx}`}>
                      <ItemBox
                        sx={{
                          width: '100%',
                          // border: '1px solid red',
                          height: isDeskTop ? '273px' : '290px',
                        }}
                      >
                        <ImageBox
                          sx={{
                            backgroundImage: `url(${brd.image_url})`,
                            maxWidth: isDeskTop ? '300px' : '100%',
                            height: isDeskTop ? '160px' : '174px',
                          }}
                          onClick={() => {
                            onToAcademyBoardDetail(brd.idx, brd.rownum);
                          }}
                        />
                        <AcademyTitle
                          sx={{ width: isDeskTop ? '295px' : '92vw' }}
                          onClick={() => {
                            onToAcademyBoardDetail(brd.idx, brd.rownum);
                          }}
                        >
                          <LinesEllipsis
                            text={brd.title}
                            maxLine="2"
                            ellipsis="..."
                            trimRight
                            basedOn="letters"
                          />
                        </AcademyTitle>
                        <Summary sx={{ width: isDeskTop ? '290px' : '92vw' }}>
                          <LinesEllipsis
                            text={brd.summary}
                            maxLine="2"
                            ellipsis="..."
                            trimRight
                            basedOn="letters"
                          />
                        </Summary>
                      </ItemBox>
                    </SwiperSlide>
                  );
                })}
            </Swiper>
          )}
        </Inner>
      </InnerBox>
      <Box
        sx={{
          display:
            isDeskTop || board.card2.lists.length <= 1 ? 'none' : 'block',
        }}
      >
        <NaviBarContainer>
          {/* 바를 직접 클릭하여 이동할 투명바 설정 */}
          <NaviBackground />
          <MovingBar
            sx={{
              width: `${100 / board.card2.lists.length}%`, // 자동너비
              left: `${movingPoint(currentStep)}%`, // 이동
            }}
          />
        </NaviBarContainer>
      </Box>
    </Container>
  );
};

const Container = styled(Box)({
  // border: '1px solid dodgerblue',
  position: 'relative',
  width: '100%',
  height: '100%',
});
const Inner = styled(Box)({
  // border: '1px solid red',
  position: 'relative',
  margin: '0 auto',
});
const InnerBox = styled(Box)({
  // border: '1px solid blue',
  maxWidth: '1420px',
  width: '98%', // 네비게이터 배치 용도, 최상위 부모 너비를 따름
  position: 'relative',
  margin: '0 auto 20px',
});
const FlexBox = styled(Box)({
  // border: '1px solid powderblue',
  position: 'relative',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  cursor: 'pointer',
});
const EmptyBox = styled(Box)({
  flexGrow: 1,
  background: '#F9FAFB',
  justifyContent: 'center',
  alignItems: 'center',
  color: '#868E96',
  fontWeight: 700,
  border: 0,
  borderRadius: '10px',
  textAlign: 'center',
});
const TitleBox = styled(Box)({
  // border: '1px solid powderblue',
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
});
const Title = styled(Box)({
  fontSize: '24px',
  fontWeight: 700,
  color: '#212529',
  lineHeight: '34px',
});
const MoreViewText = styled(Box)({
  // border: '1px solid red',
  marginRight: '8px',
  color: '#000',
  fontSize: '14px',
  fontWeight: 400,
  display: 'flex',
  alignItems: 'center',
});
const ItemBox = styled(Box)({
  // border: '1px solid red',
  height: '279px',
});
const ImageBox = styled(Box)({
  marginBottom: '16px',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center center',
  backgroundSize: '100%',
  border: 0,
  borderRadius: '10px',
  transition: 'all 0.2s ease-in-out',
  cursor: 'pointer',
  '&:hover': {
    backgroundSize: '120%',
  },
});
const LeftClickBtn = styled('button')({
  position: 'absolute',
  top: '40%',
  left: '5px',
  transform: 'translate(0, -50%)',
  zIndex: 2,
  padding: 0,
  border: 0,
  borderRadius: '50%',
  background: 'transparent',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
});
const RightClickBtn = styled('button')({
  position: 'absolute',
  top: '40%',
  right: '5px',
  transform: 'translate(0, -50%)',
  zIndex: 2,
  padding: 0,
  border: 0,
  borderRadius: '50%',
  background: 'transparent',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
});
const AcademyTitle = styled(Box)({
  // border: '1px solid red',
  height: '48px',
  marginBottom: '10px',
  color: '#212529',
  fontSize: '16px',
  fontWeight: 500,
  cursor: 'pointer',
});
const Summary = styled(Box)({
  // border: '1px solid red',
  color: '#90949C',
  fontSize: '13px',
  fontWeight: 400,
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  lineHeight: '16px',
});
const NaviBarContainer = styled('div')({
  width: '100%', // 바의 전체 너비 결정
  height: '3px', // 바의 전체 높이 결정
  position: 'relative', // 포지션 부모 설정
  backgroundColor: 'rgba(0, 0, 0, .1)',
  border: 0,
  boxSizing: 'border-box',
});
const MovingBar = styled('div')({
  position: 'absolute', // 중요!
  top: '0', // 중요
  transition: '.2s ease-in-out',
  height: '100%', // width는 인라인에서 설정
  backgroundColor: '#000', // 색깔
});
const NaviBackground = styled('div')({
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  border: 0,
  boxSizing: 'border-box',
  backgroundColor: 'rgba(0), 0, 0, .3)',
});

export default Academy;

// const Box = styled(motion.div)({
//   width: '100px',
//   height: '100px',
//   backgroundColor: 'red',
// }); <Box whileHover={{ scale: 1.2 }} />

// const board = {
//   card2: {
//     lists: [
//       {
//         category1_title: null,
//         category2_title: null,
//         contents:
//           '<p>와디즈메이커 배워보기 와디즈메이커 배워보기 와디즈메이커 배워보기 와디즈메이커 배워보기 와디즈메이커 배워보기</p><p>와디즈메이커 배워보기 와디즈메이커 배워보기 와디즈메이커 배워보기 와디즈메이커 배워보기 와디즈메이커 배워보기</p><p>와디즈메이커 배워보기 와디즈메이커 배워보기 와디즈메이커 배워보기 와디즈메이커 배워보기 와디즈메이커 배워보기</p><p>와디즈메이커 배워보기 와디즈메이커 배워보기 와디즈메이커 배워보기 와디즈메이커 배워보기 와디즈메이커 배워보기</p><p>와디즈메이커 배워보기 와디즈메이커 배워보기 와디즈메이커 배워보기 와디즈메이커 배워보기 와디즈메이커 배워보기</p><p>와디즈메이커 배워보기 와디즈메이커 배워보기 와디즈메이커 배워보기 와디즈메이커 배워보기 와디즈메이커 배워보기</p><p>와디즈메이커 배워보기 와디즈메이커 배워보기 와디즈메이커 배워보기 와디즈메이커 배워보기 와디즈메이커 배워보기</p><p>와디즈메이커 배워보기 와디즈메이커 배워보기 와디즈메이커 배워보기 와디즈메이커 배워보기 와디즈메이커 배워보기</p>',
//         idx: 150,
//         image_url:
//           'https://dev-static.wadiz.bnbcompany.io/board/image/c9c1be31-da85-4504-b7dc-92072cc2256d.jpeg',
//         important_yn: 'Y',
//         reg_date: '2022-12-19 11:46:31',
//         rownum: 5,
//         summary:
//           '와디즈메이커 배워보기 와디즈메이커 배워보기 와디즈메이커 배워보기 와디즈메이커 배워보기 와디즈메이커 배워보기',
//         title:
//           '1 와디즈메이커 배워보기 와디즈메이커 배워보기 와디즈메이커 배워보기 와디즈메이커 배워보기 와디',
//       },
//       {
//         category1_title: null,
//         category2_title: null,
//         contents:
//           '<p>와디즈메이커 배워보기 와디즈메이커 배워보기 와디즈메이커 배워보기 와디즈메이커 배워보기 와디즈메이커 배워보기</p><p>와디즈메이커 배워보기 와디즈메이커 배워보기 와디즈메이커 배워보기 와디즈메이커 배워보기 와디즈메이커 배워보기</p><p>와디즈메이커 배워보기 와디즈메이커 배워보기 와디즈메이커 배워보기 와디즈메이커 배워보기 와디즈메이커 배워보기</p><p>와디즈메이커 배워보기 와디즈메이커 배워보기 와디즈메이커 배워보기 와디즈메이커 배워보기 와디즈메이커 배워보기</p><p>와디즈메이커 배워보기 와디즈메이커 배워보기 와디즈메이커 배워보기 와디즈메이커 배워보기 와디즈메이커 배워보기</p><p>와디즈메이커 배워보기 와디즈메이커 배워보기 와디즈메이커 배워보기 와디즈메이커 배워보기 와디즈메이커 배워보기</p><p>와디즈메이커 배워보기 와디즈메이커 배워보기 와디즈메이커 배워보기 와디즈메이커 배워보기 와디즈메이커 배워보기</p><p>와디즈메이커 배워보기 와디즈메이커 배워보기 와디즈메이커 배워보기 와디즈메이커 배워보기 와디즈메이커 배워보기</p>',
//         idx: 151,
//         image_url:
//           'https://dev-static.wadiz.bnbcompany.io/board/image/c9c1be31-da85-4504-b7dc-92072cc2256d.jpeg',
//         important_yn: 'Y',
//         reg_date: '2022-12-19 11:46:31',
//         rownum: 5,
//         summary:
//           '와디즈메이커 배워보기 와디즈메이커 배워보기 와디즈메이커 배워보기 와디즈메이커 배워보기 와디즈메이커 배워보기',
//         title:
//           '2 와디즈메이커 배워보기 와디즈메이커 배워보기 와디즈메이커 배워보기 와디즈메이커 배워보기 와디',
//       },
//     ],
//   },
// };
