import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { ErrorBoundary } from '@sentry/react';
import ScreenLoading from './components/common/ScreenLoading';
import Home from './pages/Home';
import Root from './components/frame/Root';
import { mainApi } from './api/main';
import { menuState } from './atoms';
import { showError } from './lib/utils';
import MenuRoot from './components/frame/MenuRoot';
import Search from './pages/Search';
import BasicDetail from './pages/BasicDetail';
import CardDetail from './pages/CardDetail';
import EventDetail from './pages/EventDetail';
import CalendarDetail from './pages/CalendarDetail';
import { loadBasicDetail } from './pages/loader/loadBasicDetail';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: '',
        element: <Home />,
      },
      {
        path: 'menu/:menuId',
        element: <MenuRoot />,
      },
      {
        path: 'board/basic/:idx',
        loader: loadBasicDetail('basic'),
        element: <BasicDetail />,
      },
      {
        path: 'board/card/:idx',
        loader: loadBasicDetail('card'),
        element: <CardDetail />,
      },
      {
        path: 'board/event/:idx',
        loader: loadBasicDetail('event'),
        element: <EventDetail />,
      },
      {
        path: 'board/calendar/:idx',
        loader: loadBasicDetail('calendar'),
        element: <CalendarDetail />,
      },
      {
        path: 'search/:searchText',
        element: <Search />,
      },
      {
        path: '*',
        element: <Navigate to="/" />,
      },
    ],
  },
]);

const Router = () => {
  const setMenu = useSetRecoilState(menuState);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const loadData = async () => {
      try {
        setLoading(true);
        const { data } = await mainApi.getMenu();
        setMenu(data.data);
        setLoading(false);
      } catch (err) {
        showError(err);
      }
    };
    loadData();
  }, []);

  if (loading) {
    return <ScreenLoading />;
  }

  return <RouterProvider router={router} />;
};

export default Router;
