import { React, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import {
  useTheme,
  useMediaQuery,
  Typography,
  Box,
  Button,
  ClickAwayListener,
} from '@mui/material';
import Popper from '@mui/material/Popper';

import { ReactComponent as LinkIcon } from '../../assets/images/ArrowSquareOut.svg';
import { ReactComponent as DownIcon } from '../../assets/images/m-arrow-down.svg';
import { ReactComponent as UpIcon } from '../../assets/images/m-arrow-up.svg';

import AfilliateBox from './FooterMenuBox/AfilliateBox';
import SNSBox from './FooterMenuBox/SNSBox';
import TermsBox from './FooterMenuBox/TermsBox';
import PolicyTermsBox from './FooterMenuBox/PolicyTermsBox';

const MenuItem = styled(Button)(({ theme }) => ({
  transition: 'none',
  '&:hover': {
    backgroundColor: 'white',
  },
  [theme.breakpoints.up('desktop')]: {
    padding: '0px',
  },
}));

const MenuItemBox = styled(Box)(({ theme }) => ({
  position: 'relative',
  boxSizing: 'border-box',
  width: '45vw',
  [theme.breakpoints.up('desktop')]: {
    width: 'auto',
  },
}));

const MenuItemText = styled(Typography)(({ theme }) => ({
  fontSize: '16px',
  fontWeight: 400,
  color: '#212529',
  lineHeight: '40px',
  marginRight: '7.5px',
  [theme.breakpoints.up('desktop')]: {
    padding: '0px',
    letterSpacing: '-0.3px',
    fontSize: '14px',
    lineHeight: '20px',
  },
}));

const MenuItemBoldText = styled(Typography)(({ theme }) => ({
  fontSize: '16px',
  fontWeight: 700,
  color: '#212529',
  lineHeight: '40px',
  marginRight: '7.5px',
  [theme.breakpoints.up('desktop')]: {
    padding: '0px',
    letterSpacing: '-0.3px',
    fontSize: '14px',
    lineHeight: '20px',
  },
}));

const Icon = ({ isOpen }) => {
  return (
    <Box sx={{ display: 'block' }}>{isOpen ? <UpIcon /> : <DownIcon />}</Box>
  );
};

Icon.propTypes = {
  isOpen: PropTypes.bool,
};

Icon.defaultProps = {
  isOpen: false,
};

const FooterMenu = ({
  title,
  type,
  boxwidth,
  children,
  onClick,
  anchor,
  lastItem,
  isLinkMargin,
  isBold = false,
}) => {
  const [menuOpen, setmenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClickAway = () => {
    setmenuOpen(false);
  };
  const ButtonClick = event => {
    if (type === 'link') onClick();
    else {
      setmenuOpen(!menuOpen);
      setAnchorEl(event.currentTarget);
    }
  };

  const styles = {
    width: boxwidth,
    top: 40,
    right: 0,
    left: 0,
    marginLeft: anchor,
    zIndex: 1,
    border: '1px solid',
    p: 1,
    bgcolor: 'background.paper',
    padding: '8px 24px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  };

  const theme = useTheme();
  const isLinkMarginTop =
    useMediaQuery(theme.breakpoints.up('desktop')) && isLinkMargin;
  const MenuItemStyle = {
    marginRight: lastItem ? '0px' : '43px',
    marginTop: isLinkMarginTop ? '2px' : '0px',
    justifyContent: 'flex-start',
  };
  const widString = useMediaQuery(theme.breakpoints.up('desktop'))
    ? 'auto'
    : '45vw';

  const MenuItemBoxStyle = {
    width: lastItem ? '48px !important' : widString,
  };
  const SelectedMenuItemText = isBold ? MenuItemBoldText : MenuItemText;
  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <MenuItemBox sx={MenuItemBoxStyle}>
        <MenuItem sx={MenuItemStyle} onClick={ButtonClick}>
          <SelectedMenuItemText>{title}</SelectedMenuItemText>
          {type === 'link' && <LinkIcon />}
          {type === 'menu' && <Icon isOpen={menuOpen} />}
        </MenuItem>
        <Popper open={menuOpen} anchorEl={anchorEl}>
          <Box sx={styles}>{children}</Box>
        </Popper>
      </MenuItemBox>
    </ClickAwayListener>
  );
};

FooterMenu.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string.isRequired,
  type: PropTypes.string,
  onClick: PropTypes.func,
  boxwidth: PropTypes.string,
  anchor: PropTypes.string,
  isLinkMargin: PropTypes.bool,
  lastItem: PropTypes.bool,
};
FooterMenu.defaultProps = {
  children: null,
  type: 'menu',
  onClick: null,
  boxwidth: '200px',
  anchor: '0px',
  isLinkMargin: false,
  lastItem: false,
};

const FooterMenuBox = styled(Box)(({ theme }) => ({
  padding: '0px',
  borderWidth: '1px 0px',
  borderStyle: 'solid',
  borderColor: '#E6EAED',
  justifyContent: 'center',
  width: '100%',
  [theme.breakpoints.up('desktop')]: {
    height: '58px',
  },
}));

const FooterMenuBar = styled(Box)(({ theme }) => ({
  /* Auto layout */
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: '8px 16px 8px',
  width: '100%',
  overflow: 'hidden',
  [theme.breakpoints.up('desktop')]: {
    padding: '0px',
    height: '20px',
    margin: 'auto',
    marginTop: '17px',
    flexDirection: 'row',
    maxWidth: '1280px',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

const FooterMenuTermsRight = styled(Box)(({ theme }) => ({
  flexWrap: 'wrap',
  display: 'flex',
  flexDirection: 'column',
  padding: '0px',
  [theme.breakpoints.up('desktop')]: {
    flexWrap: 'nowrap',
    flexDirection: 'row',
  },
}));
const FooterMenuMobileWrap = styled(Box)(({ theme }) => ({
  flexWrap: 'wrap',
  display: 'flex',
  flexDirection: 'row',
  padding: '0px',
  [theme.breakpoints.up('desktop')]: {
    flexWrap: 'nowrap',
  },
}));
const FooterMenuTermsLeft = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  padding: '0px',
  [theme.breakpoints.up('desktop')]: {},
}));

const FooterMenuArea = () => {
  const navigate = useNavigate();
  const openNotice = () => {
    navigate(`/menu/17`); // 공지 이벤트 페이지로 이동해야됨
  };
  const openWadiz = () => {
    window.open('https://www.wadiz.kr/web/main', '_blank');
  };
  return (
    <FooterMenuBox>
      <FooterMenuBar>
        <FooterMenuTermsLeft>
          <FooterMenu title="정책 · 약관" boxwidth="255px">
            <TermsBox />
          </FooterMenu>
          <FooterMenu
            title="개인정보처리방침"
            boxwidth="310px"
            anchor="-100px"
            isBold
          >
            <PolicyTermsBox />
          </FooterMenu>
        </FooterMenuTermsLeft>
        <FooterMenuTermsRight>
          <FooterMenuMobileWrap>
            <FooterMenu title="제휴문의" boxwidth="310px">
              <AfilliateBox />
            </FooterMenu>
            <FooterMenu
              onClick={openNotice}
              title="공지/이벤트"
              type="link"
              isLinkMargin
            />
          </FooterMenuMobileWrap>
          <FooterMenuMobileWrap>
            <FooterMenu
              onClick={openWadiz}
              title="와디즈 바로가기"
              type="link"
              isLinkMargin
            />
            <FooterMenu title="SNS" boxwidth="280px" anchor="-130px" lastItem>
              <SNSBox />
            </FooterMenu>
          </FooterMenuMobileWrap>
        </FooterMenuTermsRight>
      </FooterMenuBar>
    </FooterMenuBox>
  );
};

export default FooterMenuArea;
