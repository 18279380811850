import React from 'react';
import qs from 'qs';
import reactStringReplace from 'react-string-replace';
import { styled, Box, useTheme, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { useRecoilValue } from 'recoil';
import { menuState } from '../../atoms';
import { getfindBoard } from '../../lib/utils';

const SearchResultItem = ({
  // searchText,
  idx,
  board_title,
  hashtag,
  start_date,
  end_date,
  reg_date,
  summary,
  title,
  rownum,
  board_type,
  onSearchByHashtag,
  board_idx,
  keywords,
  // contents,
  // view_count,
}) => {
  const theme = useTheme();
  const isDeskTop = useMediaQuery(theme.breakpoints.up('desktop'));
  const menuList = useRecoilValue(menuState);
  const navigate = useNavigate();

  // 검색결과 게시판 리스트 화면으로 이동
  const onMoveToBoard = () => {
    const currentMenu = getfindBoard(menuList, board_idx);
    navigate(`/menu/${currentMenu.menu_idx}`);
  };

  // 검색결과 상세화면으로 이동
  const onMoveToBoardDetail = () => {
    const data = {
      rownum,
      search_type: null,
      search_text: null,
      category1_idx: null,
      category2_idx: null,
      board_id: board_idx,
    };
    const query = qs.stringify(data);
    navigate(`/board/${board_type}/${idx}?${query}`);
  };

  // 키워드 하이라이트
  const keywordReplace = text => {
    let contents = text;

    keywords.forEach(keyword => {
      const reg = new RegExp(`(${keyword})`, 'g');
      contents = reactStringReplace(contents, reg, (match, index) => (
        <span
          key={`${keyword}${index}`}
          style={{ color: '#00B2B2', fontWeight: 700 }}
        >
          {match}
        </span>
      ));
    });
    return contents;
  };

  const getEventTag = () => {
    if (!start_date) return null;
    if (dayjs().isBefore(start_date)) return <BoxBefore>진행예정</BoxBefore>;
    if (dayjs().isAfter(end_date)) return <BoxAfter>종료</BoxAfter>;
    return <BoxIng>진행중</BoxIng>;
  };

  return (
    <Container
      key={`search-result-${idx}-${title}`}
      sx={{
        mb: '20px',
        padding: isDeskTop ? 0 : '0 16px',
      }}
    >
      <TextInner>
        {getEventTag()}
        <FlexBox
          sx={{
            justifyContent: 'flex-start',
            mt: '8px',
            mb: '8px',
          }}
        >
          <Catogory
            sx={{
              mr: '20px',
              fontSize: isDeskTop ? '15px' : '14px',
              cursor: 'pointer',
            }}
            onClick={onMoveToBoard}
          >
            {board_title}
          </Catogory>
          <Date
            sx={{
              display:
                reg_date === null ||
                board_title === '이용가이드' ||
                board_title === '자주 묻는 질문'
                  ? 'none'
                  : 'block',
              fontSize: isDeskTop ? '15px' : '14px',
            }}
          >
            {start_date ? (
              <>
                {dayjs(start_date).format('YYYY.MM.DD')} ~{' '}
                {dayjs(end_date).format('YYYY.MM.DD')}
              </>
            ) : (
              <>{dayjs(reg_date).format('YYYY.MM.DD')}</>
            )}
          </Date>
        </FlexBox>
        <TitleBox
          sx={{
            mb: '8px',
            fontSize: isDeskTop ? '20px' : '16px',
          }}
          onClick={onMoveToBoardDetail}
        >
          {keywordReplace(title)}
        </TitleBox>
        <SummaryBox
          sx={{
            mb: '24px',
            fontSize: isDeskTop ? '14px' : '13px',
          }}
        >
          {keywordReplace(summary)}
        </SummaryBox>
        <TagBox sx={{ mb: '20px' }}>
          {hashtag?.length >= 1 &&
            hashtag.map(tag => {
              return (
                <Tag
                  key={tag}
                  onClick={() => onSearchByHashtag(tag)}
                  sx={{ fontSize: isDeskTop ? '14px' : '12px' }}
                >
                  #{keywordReplace(tag)}
                </Tag>
              );
            })}
        </TagBox>
      </TextInner>
      {/* <ImageInner>
        <Box sx={{ border: '1px solid red', width: '100px', height: '100px' }}>
          Image
        </Box>
      </ImageInner> */}
    </Container>
  );
};

SearchResultItem.defaultProps = {
  // searchText: null,
  hashtag: null,
  summary: null,
  start_date: null,
  end_date: null,
  reg_date: null,
  keywords: [],
  // idx, board_idx, board_title,contents, reg_date, , title, view_count
};

SearchResultItem.propTypes = {
  // searchText: PropTypes.string,
  idx: PropTypes.number.isRequired,
  board_title: PropTypes.string.isRequired,
  hashtag: PropTypes.array,
  start_date: PropTypes.string,
  end_date: PropTypes.string,
  reg_date: PropTypes.string,
  summary: PropTypes.string,
  title: PropTypes.string.isRequired,
  onSearchByHashtag: PropTypes.func.isRequired,
  rownum: PropTypes.number.isRequired,
  board_type: PropTypes.string.isRequired,
  board_idx: PropTypes.number.isRequired,
  keywords: PropTypes.array,
  // contents: PropTypes.string.isRequired,
  // view_count: PropTypes.number.isRequired,
};

const Container = styled(Box)({
  // border: '1px solid powderblue',
  position: 'relative',
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderBottom: '1px solid #E6EAED',
});
const TextInner = styled(Box)({
  // border: '1px solid red',
});
// const ImageInner = styled(Box)({
//   // border: '1px solid red',
// });
const FlexBox = styled(Box)({
  // border: '1px solid red',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});
const Catogory = styled(Box)({
  // border: '1px solid red',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  color: '#212529',
  fontSize: '15px',
  fontWeight: 400,
  lineHeight: '24px',
});
const Date = styled(Box)({
  // border: '1px solid red',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  color: '#90949C',
  fontSize: '15px',
  fontWeight: 400,
  lineHeight: '24px',
});
const TitleBox = styled(Box)({
  // border: '1px solid red',
  maxWidth: '1280px',
  width: '95vw',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  wordBreak: 'break-word',
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  WebkitBoxAlign: 'start',
  color: '#212529',
  fontSize: '20px',
  fontWeight: 400,
  lineHeight: '30px',
  cursor: 'pointer',
});
const SummaryBox = styled(Box)({
  // border: '1px solid red',
  maxWidth: '1280px',
  width: '95vw',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  wordBreak: 'break-word',
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  WebkitBoxAlign: 'start',
  color: '#aab3bb',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '20px',
});
const TagBox = styled(Box)({
  // border: '1px solid red',
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  flexWrap: 'wrap',
});
const Tag = styled(Box)({
  // border: '1px solid red',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  color: '#212529',
  fontSize: '14px',
  fontWeight: 400,
  margin: '0 8px 8px 0',
  padding: '8px 16px',
  border: 0,
  borderRadius: '20px',
  backgroundColor: '#F2F4F6',
  cursor: 'pointer',
});

const BoxBefore = styled(Box)(({ theme }) => ({
  backgroundColor: '#e7f9f9',
  color: '#00b2b2',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontWeight: 700,
  lineHeight: '14px',
  borderRadius: '3px',
  fontSize: '12px',
  height: '22px',
  width: '58px',
  [theme.breakpoints.up('desktop')]: {
    fontSize: '14px',
    height: '30px',
    width: '74px',
  },
}));

const BoxIng = styled(Box)(({ theme }) => ({
  backgroundColor: '#557cf2',
  color: '#fff',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '3px',
  fontWeight: 700,
  lineHeight: '14px',
  fontSize: '12px',
  height: '22px',
  width: '49px',
  [theme.breakpoints.up('desktop')]: {
    fontSize: '14px',
    height: '30px',
    width: '63px',
  },
}));

const BoxAfter = styled(Box)(({ theme }) => ({
  backgroundColor: '#868e96',
  color: '#fff',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontWeight: 700,
  lineHeight: '14px',
  borderRadius: '3px',
  fontSize: '12px',
  height: '22px',
  width: '38px',
  [theme.breakpoints.up('desktop')]: {
    fontSize: '14px',
    height: '30px',
    width: '48px',
  },
}));

export default SearchResultItem;
