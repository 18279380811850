import { Box, Typography, styled, Divider, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ListItemButton from '@mui/material/ListItemButton';
import List from '@mui/material/List';
import { useRecoilValue, useRecoilState } from 'recoil';
import { ReactComponent as LogoIcon } from '../../assets/images/m-logo.svg';
import { ReactComponent as EduIcon } from '../../assets/images/m-edu.svg';
import { ReactComponent as WadizIcon } from '../../assets/images/m_wadiz.svg';
import { ReactComponent as PartnerIcon } from '../../assets/images/m-partner.svg';
import { ReactComponent as AdIcon } from '../../assets/images/m-ad.svg';
import { ReactComponent as RightIcon } from '../../assets/images/m-arrow-right.svg';
import { menuState, menuOpenState } from '../../atoms';
import SideBarCollaspeItem from './SideBarCollaspeItem';
import SideBarFooter from './SideBarFooter';

const MenuTag = ({ menu, onClick, depth, menuOpenData, setMenuOpen }) => {
  if (menu?.menu_type === 'category') {
    const findData = menuOpenData.find(i => i.menuIdx === menu.menu_idx);
    return (
      <>
        {depth === 1 && (
          <>
            <Typography
              sx={{
                fontSize: '13px',
                fontWeight: 500,
                color: '#868E96',
                lineHeight: '24px',
              }}
            >
              {menu.menu_title}
            </Typography>
            <Box>
              {menu.menu.map(m => (
                <MenuTag
                  menu={m}
                  key={m.menu_idx}
                  onClick={onClick}
                  depth={depth + 1}
                  menuOpenData={menuOpenData}
                  setMenuOpen={setMenuOpen}
                />
              ))}
            </Box>
          </>
        )}

        {depth === 2 && (
          <SideBarCollaspeItem
            title={menu.menu_title}
            enabled
            menuOpen={findData ? findData.open : true}
            onClick={() => {
              const find = menuOpenData.find(i => i.menuIdx === menu.menu_idx);
              if (!find) {
                setMenuOpen([
                  ...menuOpenData,
                  { menuIdx: menu.menu_idx, open: false },
                ]);
              } else {
                const newMenuOpenData = [];
                menuOpenData.forEach(newData => {
                  if (newData.menuIdx === menu.menu_idx) {
                    newMenuOpenData.push({
                      menuIdx: menu.menu_idx,
                      open: !find.open,
                    });
                  } else {
                    newMenuOpenData.push(newData);
                  }
                });

                setMenuOpen(newMenuOpenData);
              }
            }}
          >
            {menu.menu.map(m => (
              <MenuTag
                menu={m}
                key={m.menu_idx}
                onClick={onClick}
                depth={depth + 1}
                menuOpenData={menuOpenData}
                setMenuOpen={setMenuOpen}
              />
            ))}
          </SideBarCollaspeItem>
        )}
        {depth === 1 && (
          <Divider sx={{ marginTop: '16px', marginBottom: '16px' }} />
        )}
      </>
    );
  }

  if (depth === 1) {
    return (
      <ListItemButton
        onClick={() => {
          onClick(menu?.menu_idx, menu?.menu_title);
        }}
        sx={{ padding: 0, height: '40px' }}
      >
        <Typography
          sx={{ fontSize: '16px', fontWeight: 400, color: '#212529' }}
        >
          {menu?.menu_title}
        </Typography>
      </ListItemButton>
    );
  }
  if (depth === 2) {
    return (
      <ListItemButton
        onClick={() => {
          onClick(menu?.menu_idx, menu?.menu_title);
        }}
        sx={{ padding: 0, height: '40px' }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          <Typography
            sx={{
              fontSize: '16px',
              fontWeight: 400,
              color: '#212529',
              flexGrow: 1,
            }}
          >
            {menu?.menu_title}
          </Typography>
          <RightIcon />
        </Box>
      </ListItemButton>
    );
  }
  if (depth === 3) {
    return (
      <ListItemButton
        onClick={() => {
          onClick(menu?.menu_idx);
        }}
        sx={{ padding: 0, paddingTop: '4px', paddingBottom: '4px' }}
      >
        <Typography
          sx={{
            fontSize: '14px',
            fontWeight: 700,
            color: '#495057',
            lineHeight: '24px',
          }}
        >
          {menu?.menu_title}
        </Typography>
      </ListItemButton>
    );
  }

  return (
    <ListItemButton
      onClick={() => {
        onClick(menu?.menu_idx);
      }}
      sx={{ padding: 0, height: '40px' }}
    >
      <Typography sx={{ fontSize: '16px', fontWeight: 400, color: '#212529' }}>
        {menu?.menu_title}
      </Typography>
    </ListItemButton>
  );
};

MenuTag.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  menu: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  depth: PropTypes.number.isRequired,
  menuOpenData: PropTypes.array.isRequired,
  setMenuOpen: PropTypes.func.isRequired,
};

const MenuIconButton = ({ title, onClick, children }) => {
  return (
    <Button sx={{ display: 'block' }} onClick={onClick}>
      {children}
      <LinkTitle>{title}</LinkTitle>
    </Button>
  );
};

MenuIconButton.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

const LinkTitle = styled(Typography)(({ theme }) => ({
  color: '#212529',
  fontSize: '14px',
  fontWeight: 500,
  [theme.breakpoints.up('desktop')]: {},
}));

const Sidebar = ({ close }) => {
  const menuList = useRecoilValue(menuState);
  const [menuOpenData, setMenuOpen] = useRecoilState(menuOpenState);
  const navigate = useNavigate();

  const onClickQuickMenu = name => {
    window.dataLayer.push({
      event: 'ga.custom.tag',
      GACategory: 'GNB',
      GALabel: name,
      GAAction: '클릭',
    });
  };

  const handleClick = (menuIdx, menuTitle) => {
    onClickQuickMenu(menuTitle);
    close();
    navigate(`/menu/${menuIdx}`);
  };

  return (
    <Box>
      <Box
        sx={{
          backgroundColor: '#00C4C4',
          paddingTop: '7px',
          paddingBottom: '24px',
        }}
      >
        <Box
          sx={{ display: 'flex', paddingLeft: '16px', paddingRight: '20px' }}
        >
          <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
            <Button
              sx={{ padding: 0 }}
              onClick={() => {
                navigate('/');
                close();
              }}
            >
              <LogoIcon />
            </Button>
          </Box>
          <IconButton
            onClick={() => {
              close();
              window.dataLayer.push({
                event: 'ga.custom.tag',
                GACategory: 'GNB',
                GALabel: '닫기',
                GAAction: '클릭',
              });
            }}
          >
            <CloseIcon width={16} height={16} />
          </IconButton>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-around',
            marginTop: '20px',
          }}
        >
          <MenuIconButton
            title="와디즈"
            onClick={() => {
              window.open('https://www.wadiz.kr/web/main', '_blank');
              onClickQuickMenu('와디즈');
            }}
          >
            <WadizIcon width={36} height={36} />
          </MenuIconButton>
          <MenuIconButton
            title="와디즈 스쿨"
            onClick={() => {
              window.open('https://www.wadiz.kr/web/school/main', '_blank');
              onClickQuickMenu('와디즈 스쿨');
            }}
          >
            <EduIcon width={36} height={36} />
          </MenuIconButton>
          <MenuIconButton
            title="파트너 서비스"
            onClick={() => {
              window.open('https://www.partnerzone.wadiz.kr/', '_blank');
              onClickQuickMenu('파트너 서비스');
            }}
          >
            <PartnerIcon width={36} height={36} />
          </MenuIconButton>
          <MenuIconButton
            title="광고 서비스"
            onClick={() => {
              window.open('https://business.wadiz.kr/', '_blank');
              onClickQuickMenu('광고 서비스');
            }}
          >
            <AdIcon width={36} height={36} />
          </MenuIconButton>
        </Box>
      </Box>
      <List sx={{ marginLeft: '16px', marginRight: '16px', marginTop: '16px' }}>
        {menuList.map(menu => {
          return (
            <MenuTag
              menu={menu}
              key={menu.menu_idx}
              onClick={handleClick}
              depth={1}
              menuOpenData={menuOpenData}
              setMenuOpen={setMenuOpen}
            />
          );
        })}
      </List>
      <SideBarFooter />
    </Box>
  );
};

Sidebar.propTypes = {
  close: PropTypes.func.isRequired,
};

export default Sidebar;
