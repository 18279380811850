import React from 'react';
import PropTypes from 'prop-types';
import { useWindowWidth } from '@react-hook/window-size';

const InnerHtml = ({ url }) => {
  const ref = React.useRef();
  const [iframeHeight, setIframeHeight] = React.useState('0px');
  const onlyWidth = useWindowWidth();
  const receiveMessage = event => {
    if (typeof event.data === 'object') {
      const message = event.data;
      if (message.code === 'height') {
        setIframeHeight(`${message.value}px`);
      }
      if (message.code === 'link') {
        // setHeight(evt.data.value + "px");
        if (message.option && message.option === 'popup_n') {
          // setHeight(evt.data.value + "px");
          window.location.href = message.value;
          return;
        }
        if (message.option && message.option === 'popup_y') {
          // window.location.href =  evt.data.value;
          window.open(message.value);
        }
      }
    }
  };
  const onLoad = () => {
    setTimeout(() => {
      setIframeHeight(
        `${ref.current.contentWindow.document.body.scrollHeight}px`,
      );
    }, 50);
  };
  React.useEffect(() => {
    if (window.addEventListener) {
      // For standards-compliant web browsers
      window.addEventListener('message', receiveMessage, false);
    } else {
      window.attachEvent('onmessage', receiveMessage);
    }
  }, [onlyWidth]);
  return (
    <div className="innerHtmlContainer" style={{ width: '100%' }}>
      <iframe
        ref={ref}
        src={url}
        title={url}
        height={iframeHeight}
        style={{
          display: 'block',
          width: '100%',
          height: iframeHeight,
          minHeight: iframeHeight,
          framespacing: 0,
          marginheight: 0,
          marginwidth: 0,
          overflow: 'auto',
          border: 0,
        }}
        scrolling="no"
        onLoad={onLoad}
      />
    </div>
  );
};

InnerHtml.propTypes = {
  url: PropTypes.string,
};

InnerHtml.defaultProps = {
  url: '',
};

export default InnerHtml;
